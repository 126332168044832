import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minWidth: '100%',
    height: '100%',
    backgroundImage: 'url("bghome.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginLeft: 0,
    marginRight: 0,
    alignItems: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
      backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
      zIndex: -1,
    },
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
    backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
    zIndex: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: 'rgba(17, 17, 17, 0.7)',
    backdropFilter: 'blur(10px)',
    border: '2px solid transparent',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
    borderRadiusTopRight: 100,
    borderRadiusTopLeft: 100,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 16,
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontFamily: 'Rubik Wet Paint',
  },
  titleFAQ: {
    marginBottom: theme.spacing(2),
    fontSize: 26,
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontWeight: 'bold',
    fontFamily: 'Rubik Wet Paint',
  },
  titleAccordition: {
    marginBottom: theme.spacing(2),
    fontSize: 16,
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontFamily: 'Rubik Wet Paint',
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
  },
  // ... altre classi
}));

const FAQ: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.overlay}></div>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h5" className={classes.titleFAQ}>
          Frequently Asked Questions
        </Typography>
        <Accordion className={classes.paper}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.titleAccordition}>1. Who are Hyena Hustlers?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              A dynamic Cronos community, driven by innovation and financial growth 💵 💶 Our unwavering motto: Show up every single day mfer.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.paper}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.titleAccordition}>2. What is Hyena Hustlers drop?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              -3200 NFT x 250cro for Alpha Drop happening in the first week of October ⏳ Hustlers mentality art 🔥
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.paper}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.titleAccordition}>3. Who are Alphas?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              Alphas are first adopters of the project. Free mint happening on 20.8.2023 🆓 ❤️‍🔥
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.paper}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.titleAccordition}>4. What are Hustlers Utilities? What are our future plans?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              Soon; Roadmap
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.paper}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.titleAccordition}>5. Where can we get more information?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              Discord
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.paper}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.titleAccordition}>6. Where can we contact the Team?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              On Discord: 
              ⁠HyenaHustlers⁠║🎫║-open-a-ticket
              ⁠HyenaHustlers⁠🐆-hyenas-den-🐆
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  );
}

export default FAQ;
