import React, { useContext, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Alert, AlertTitle, CardMedia, Divider } from '@mui/material';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import EtherHelper from "../../ethers/EtherHelper";
import { Container, Typography, Button, Grid, createStyles, makeStyles, LinearProgress, Paper, Box, Collapse, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Switch, Tooltip } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CountdownTimer from '../Countdown';
import GetAppIcon from '@material-ui/icons/GetApp'; // Import the GetAppIcon
import BadgeIcon from '@mui/icons-material/Badge';
import PaidIcon from '@mui/icons-material/Paid';
import NotificationIcon from '@mui/icons-material/Notifications';
import { Badge, Chip, Slider } from "@mui/material";
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { BadgeProps, Flex } from "@chakra-ui/react";
import { EtherContext } from '../../ethers/EtherContext';
import { EtherContextRepository } from '../../ethers/EtherContextRepository';
import { BigNumber, ethers } from 'ethers';
import { Share } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        backgroundImage: 'url("casino.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        position: 'relative',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minWidth: '100%',
        height: '100%',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(10px)',
            zIndex: -1,
        },
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(10px)',
        zIndex: 0,
    },
    section: {
        padding: theme.spacing(3),
        borderRadius: theme.spacing(2),
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        marginBottom: theme.spacing(4),
        color: 'white'
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
    },
    paper: {
        display: 'flex',
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: 'rgba(17, 17, 17, 0.0)',
        marginTop: 80,
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    themeDark: {
        background: 'url("launchpad.jpg")',
        maxWidth: 345,
        border: "2px solit white",
        borderRadius: 50
    },
    catchyTextSupplyCardNFT: {
        color: '#f57c00',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 26,
        letterSpacing: 0.2,
        lineHeight: 1.2,
    },
    media: {
        position: 'relative',
        height: 0,
        paddingTop: '100%', // 16:9
    },
    overlayCard: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pointerEvents: 'none',
    },
    tooltip: {
        padding: theme.spacing(0),
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        color: '#fff',
        borderRadius: 4,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    pagination: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titlePagination: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    success: {
        backgroundColor: 'green',
        color: 'white',
        fontSize: '20px',
        fontWeight: 'bold',
    },
    paperAlert: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: 'rgba(17, 17, 17, 0.7)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
        position: 'fixed',
        zIndex: 9999,
        width: '100%',
        minHeight: 10,
        height: 'auto',
        marginLeft: -33
    },
}));

const CustomizedHeader = styled(CardHeader)`
  background: 'launchpad.jpg';
`;

const Royalties: React.FC = () => {
    const classes = useStyles();
    const [tokenCards, setTokenCards] = useState<JSX.Element[]>([]);
    const { context, saveContext } = useContext(EtherContext) as EtherContextRepository;
    const [toClaim, setToClaim] = useState(0)
    const [contract_balance, setContractBalance] = useState(0)
    const [xnft, setXNFT] = useState<any>()
    const [shares, setShares] = useState()
    const [release, setReleased] = useState(0)
    const [alertInfo, setAlertInfo] = useState<{ severity: "success" | "error", message: any } | null>(null);
    const isMobile = useMediaQuery('(max-width:960px)');

    const handleCloseAlert = () => {
        setAlertInfo(null)
    }

    const ClaimRoyal = async () => {
        try {
            const data = await EtherHelper.RoyalRelease(context, context.hustlers_tokenId ?? [])
                .then((tx) => setAlertInfo({ severity: "success", message: 'Claimed!' }))
            console.log(data)
        } catch (error: any) {
            console.error("Error on mint: ", JSON.stringify(error));
                setAlertInfo({ severity: "error", message: error.reason });
        } finally {
            if (alertInfo?.severity === "error") {
                setTimeout(() => {
                    handleCloseAlert();
                }, 3000);
            }
        }
    }

    const GetMultiPayout = async () => {
        try {
            const data = await EtherHelper.MultiPayout(context, context.hustlers_tokenId ?? []);
            const numberValue = data / 1e18;
            setToClaim(numberValue)
            console.log(numberValue);
        } catch (e) {
            console.log("Error on Rewards Released", e);
        }
    };

    const getContractBalance = async () => {
        try {
            const data = await EtherHelper.RoyaltyBalance(context);
            const numberValue = data / 1e18;
            setContractBalance(numberValue)
            console.log(numberValue);
        } catch (e) {
            console.log("Error on getContractBalance", e);
        }
    };

    const AmountXNft = async () => {
        if (toClaim > 0) {
            const hold = Number(context.hustlers_tokenId?.length ?? 0)
            const xNFT = toClaim / hold
            setXNFT(xNFT)
        } else {
            return 0
        }
    }

    const Shares = async () => {
        if (context) {
            const hold = await EtherHelper.TotalShares(context)
            const shares = hold
            setShares(shares)
        } else {
            return 0
        }
    }

    const Released = async () => {
        if (context) {
            const hold = await EtherHelper.TotalReleased(context)
            const released = hold / 1e18
            setReleased(released)
        } else {
            return 0
        }
    }

    useEffect(() => {
        GetMultiPayout();
        getContractBalance();
        AmountXNft();
        Shares();
        Released();
    }, [context])

    const fetchTokenCards = async () => {
        const newTokenCards: JSX.Element[] = [];
        const hyenaTokenIds = context.hustlers_tokenId ?? []

        for (const nft of hyenaTokenIds) {
            try {
                const tokenImage = await EtherHelper.getHustlerTokenURI(context, nft);
                console.log(tokenImage)
                const tokenData = tokenImage;
                const handleDownloadClick = () => {
                    // Assuming 'image' is the URL of the image you want to download
                    const imageDownloadLink = document.createElement('a');
                    imageDownloadLink.href = "h1.png";
                    imageDownloadLink.setAttribute('download', 'nft_image.png'); // Set the desired file name
                    document.body.appendChild(imageDownloadLink);
                    imageDownloadLink.click();
                    document.body.removeChild(imageDownloadLink);
                };
                newTokenCards.push(
                    <Card className={classes.themeDark} key={nft} style={{ marginBottom: 30 }}>
                        <CustomizedHeader
                            className={classes.themeDark}
                            title={
                                <Typography variant="h4" className={classes.catchyTextSupplyCardNFT} color="textSecondary" component="p">
                                    {tokenData.name}
                                </Typography>
                            }
                        />
                        <CardMedia
                            className={classes.media}
                            image={'https://ipfs.io/ipfs/' + tokenData.image.slice(7)}
                            title="NFT Image"
                        >
                        </CardMedia>
                        <CardContent>
                            {toClaim <= 0 && (
                                <Chip
                                    style={{ marginTop: 0 }}
                                    className={classes.success}
                                    label={'Nothing to claim'}
                                    color={'warning'}
                                />
                            )}
                            {toClaim > 0 && (
                                <Chip
                                    style={{ marginTop: 0 }}
                                    icon={<PaidIcon />}
                                    className={classes.success}
                                    label={xnft.toFixed(4) + ' CRO'}
                                    color={'success'}
                                />
                            )}
                        </CardContent>
                    </Card >
                );
            } catch (error) {
                console.error('Error fetching token data:', error);
            }
        }
        setTokenCards(newTokenCards);
    };

    useEffect(() => {
        if (context) {
            fetchTokenCards()
        } else {
            fetchTokenCards()
        }
    }, [context])

    return (
        <div className={classes.root}>
            <div className={classes.overlay}></div>
            <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                            {alertInfo !== null && (
                                <Paper elevation={3} className={classes.paperAlert}>
                                    <Collapse in={alertInfo !== null}>
                                        <Alert
                                            variant="outlined"
                                            severity={alertInfo?.severity || "info"}
                                            onClose={handleCloseAlert}
                                        >
                                            <AlertTitle>{alertInfo?.severity === "error" ? "Error" : "Success"}</AlertTitle>
                                            {alertInfo?.message}
                                        </Alert>
                                    </Collapse>
                                </Paper>
                            )}
                        </Grid>
            <Paper elevation={3} className={classes.paper}>
                <Grid container spacing={0}>
                
                    <Grid item xs={12}>
                        <Paper className={classes.section}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Royalties
                            </Typography>
                            <Typography variant="body1">
                                Explore your royalties and claims.
                            </Typography>
                            <Chip
                                style={{ marginTop: 20 }}
                                className={classes.success}
                                label={context.hustlers_tokenId?.length + ' Hyena Hustlers'}
                                color={'success'}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {!isMobile && (
                        <Grid item xs={12} md={12} style={{ marginBottom: 50, gap: 20, margin: 1, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button color="secondary" variant="text">Contract Balance</Button>
                                <Chip
                                    style={{ marginTop: 0 }}
                                    icon={<PaidIcon />}
                                    className={classes.success}
                                    label={contract_balance.toFixed(2) + ' CRO'}
                                    color={'success'}
                                />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button color="secondary" variant="text">Available to claim</Button>
                                <Chip
                                    style={{ marginTop: 0 }}
                                    icon={<PaidIcon />}
                                    className={classes.success}
                                    label={toClaim.toFixed(2) + ' CRO'}
                                    color={'success'}
                                />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button color="secondary" variant="text">Royalties Distributed</Button>
                                <Chip
                                    style={{ marginTop: 0 }}
                                    icon={<PaidIcon />}
                                    className={classes.success}
                                    label={release.toFixed(2) + ' CRO'}
                                    color={'success'}
                                />
                            </Box>

                        </Grid>
                        )}
                        {isMobile && (
                             <Grid item xs={12} md={12} style={{ marginBottom: 50, gap: 20, margin: 1, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                             <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                 <Button color="secondary" variant="text">Contract Balance</Button>
                                 <Chip
                                     style={{ marginTop: 0 }}
                                     icon={<PaidIcon />}
                                     className={classes.success}
                                     label={contract_balance.toFixed(2) + ' CRO'}
                                     color={'success'}
                                 />
                             </Box>
                             <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                 <Button color="secondary" variant="text">Available to claim</Button>
                                 <Chip
                                     style={{ marginTop: 0 }}
                                     icon={<PaidIcon />}
                                     className={classes.success}
                                     label={toClaim.toFixed(2) + ' CRO'}
                                     color={'success'}
                                 />
                             </Box>
                             <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                 <Button color="secondary" variant="text">Royalties Distributed</Button>
                                 <Chip
                                     style={{ marginTop: 0 }}
                                     icon={<PaidIcon />}
                                     className={classes.success}
                                     label={release.toFixed(2) + ' CRO'}
                                     color={'success'}
                                 />
                             </Box>
 
                         </Grid>
                        )}
                        <Grid item style={{ marginTop: 25 }}>

                            <Button color="secondary" variant='contained' onClick={ClaimRoyal}>CLAIM ALL</Button>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                        {tokenCards.map((tokenCard, index) => (
                            <Grid item xs={12} sm={6} style={{display: 'flex', justifyContent: 'center'}} md={6} key={index}>
                                {tokenCard}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
            </Grid>
        </div>
    );
};

export default Royalties;
