import { IEtherContext } from "../../../ethers/IEtherContext";

interface LevelInfo {
    level: number;
    percentage: number;
    xhyn_req: number[];
}

export const getLevelHH = (context: IEtherContext): LevelInfo => {

    const balance_xhyn = context.xhynAmount ?? 0;
    const lvl1 = (balance_xhyn <= 10);
    const lvl2 = (balance_xhyn <= 20);
    const lvl3 = (balance_xhyn <= 50);
    const lvl4 = (balance_xhyn <= 100);
    const lvl5 = (balance_xhyn <= 500);
    const xhyn_req = [10,20,50,100,500]

    let level: number;
    let percentage: number;
    let xhyn: number;

    if (lvl1) {
        level = 1;
        percentage = (balance_xhyn / 10) * 100;
        xhyn = 10
    } else if (lvl2) {
        level = 2;
        percentage = (balance_xhyn / 20) * 100;
        xhyn = 20
    } else if (lvl3) {
        level = 3;
        percentage = (balance_xhyn / 50) * 100;
        xhyn = 50
    } else if (lvl4) {
        level = 4;
        percentage = (balance_xhyn / 100) * 100;
        xhyn = 100
    } else if (lvl5) {
        level = 5;
        percentage = (balance_xhyn / 500) * 100;
        xhyn = 500
    } else {
        level = 0;
        percentage = (balance_xhyn / 10) * 100;
        xhyn = 10
    }

    return {level, percentage, xhyn_req };
};
