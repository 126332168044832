
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup, List, ListItem, ListItemText, Grid, Stack, IconButton, createTheme, styled, Badge, CardMedia, CardActions, CardHeader, Chip, Slider, Input } from '@mui/material';
import { BadgeProps, Box, Card, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Modal, Paper, Slide, Typography, useMediaQuery } from '@material-ui/core';
import { TextField, makeStyles } from '@material-ui/core';
import EtherHelper from '../../ethers/EtherHelper';
import { EtherContext } from '../../ethers/EtherContext';
import { EtherContextRepository } from '../../ethers/EtherContextRepository';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { TransitionProps } from '@material-ui/core/transitions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import lottery from '../../common/lottery.jpg'
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import { BigNumber } from 'ethers';
import { IEtherContext } from '../../ethers/IEtherContext';
import BadgeIcon from '@mui/icons-material/Badge';
import PaidIcon from '@mui/icons-material/Paid';
import NotificationIcon from '@mui/icons-material/Notifications';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const deployer = '0xC1ec8665C40B8cAB988C3E126d96d28Bbcdd550a'

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CountdownTimer: React.FC<{ lastOpenTimestamp: Date; maxTime: number }> = ({ lastOpenTimestamp, maxTime }) => {
    const [remainingTime, setRemainingTime] = useState((lastOpenTimestamp.getTime() / 1000) + maxTime - Math.floor(Date.now() / 1000));

    useEffect(() => {
        setRemainingTime((lastOpenTimestamp.getTime() / 1000) + maxTime - Math.floor(Date.now() / 1000));

        const interval = setInterval(() => {
            setRemainingTime(prevTime => Math.max(prevTime - 1, 0));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [lastOpenTimestamp, maxTime]);

    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <Button
            style={{ color: '#1976d2', border: '2px solid #1976d2' }}
            variant='outlined'
            color="secondary"
        >
            {formatTime(remainingTime)}
        </Button>
    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        backgroundImage: "url(https://img.freepik.com/free-vector/summer-landscape-with-moon-sky-night_107791-9394.jpg?w=1060&t=st=1693411349~exp=1693411949~hmac=834c857dbac3df4343386dd21b23794cc3749eaff3944f91b605da2388b7e938)",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        position: 'relative',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minWidth: '100%',
        height: '100%',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: -1,
        },
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
        zIndex: 0,
    },
    button: {
        marginRight: theme.spacing(2),
        color: 'white',
        background: 'rgba(17, 17, 17, 0.2)',
        border: '2px solid transparent',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        marginLeft: theme.spacing(0),
        borderRadius: theme.spacing(2),
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
            background: '#FF8053',
            color: 'white',
        },
    },
    gradient: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        margin: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    section: {
        padding: theme.spacing(3),
        borderRadius: theme.spacing(2),
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        marginBottom: theme.spacing(4),
        color: 'white'
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'rgba(17, 17, 17, 0.1)',
        marginTop: 10,
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    card: {
        width: '100%',
        color: 'white',
        backgroundColor: 'rgba(255, 255, 255, 0)', // Sfondo semi-trasparente
        marginBottom: theme.spacing(2),
        backdropFilter: 'blur(10px)', // Effetto di sfocatura
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    chip: {
        margin: 7,
        gap: 10,
        backgroundColor: '#9c27b0',
        color: 'white',
        fontWeight: 'bold', // Aggiunto il grassetto
        fontFamily: 'Roboto',
        fontSize: 14
    },
    paper2: {
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        marginTop: 10,
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 20,
    },
    sectionTitleRF: {
        marginBottom: theme.spacing(10),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 30,
    },
    sectionTitleRF1: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg #FF8E53 90%, #FE6B8B 30%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 29,
    },
    sectionTitle2: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 32,
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(3),
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    cardz: {
        maxWidth: 345,
        backgroundColor: "rgba(0,0,0, 0.5)",
        border: "5px solit white",
        borderRadius: 50
    },
    catchyTextSupplyCard: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        letterSpacing: 0.2,
        lineHeight: 1.2,
    },
    titleCount: {
        marginBottom: theme.spacing(2),
        color: 'white',
        fontFamily: 'Rubik Wet Paint',
    },
    success: {
        backgroundColor: 'green', // Cambia il colore di sfondo in verde (puoi personalizzarlo)
        color: 'white' // Cambia il colore del testo in bianco (puoi personalizzarlo)
        // Altri stili se necessario
    },
    denied: {
        backgroundColor: 'red', // Cambia il colore di sfondo in verde (puoi personalizzarlo)
        color: 'white' // Cambia il colore del testo in bianco (puoi personalizzarlo)
        // Altri stili se necessario
    },
    rootLoader: {
        flexGrow: 1,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        maxWidth: '100%',
        height: '100%',
        backgroundImage: 'url("bghome.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
    },
    logoSpin: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        vertficalAlign: 'center',
        position: 'relative',
        top: '25%',
        width: '100%',
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)', // Initial rotation angle
        },
        '100%': {
            transform: 'rotate(360deg)', // Final rotation angle after 360 degrees
        },
    },
    logoImage: {
        maxWidth: '38vh',
        maxHeight: '38vh',
        animation: '$spin 2s linear infinite', // Applying the spinning animation
    },
    rainbowText: {
        background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        animation: '$rainbow 5s infinite',
        fontWeight: 'bold', // Aggiunto il grassetto
        fontFamily: 'Roboto',
    },
    '@keyframes rainbow': {
        '0%': { color: theme.palette.secondary.main },
        '25%': { color: theme.palette.error.main },
        '50%': { color: theme.palette.primary.main },
        '75%': { color: theme.palette.info.main },
        '100%': { color: theme.palette.success.main },
    },
}));

const theme = createTheme({
    components: {
        MuiStack: {
            defaultProps: {
                useFlexGap: true,
            },
        },
    },
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
}));

interface NumberInputProps {
    value: number;
    onChange: (newValue: number) => void;
}

const NumberInputWithButtons: React.FC<NumberInputProps> = ({ value, onChange }) => {
    const increment = () => {
        onChange(value + 1);
    };

    const decrement = () => {
        if (value > 0) {
            onChange(value - 1);
        }
    };

    return (
        <ButtonGroup style={{ marginBottom: 20 }}>
            <Button color="secondary" onClick={decrement}>—</Button>
            <TextField
                label='Buy Tickets'
                value={value}
                variant="filled"
                color="primary"
                style={{
                    width: '100%',
                    backgroundColor: 'rgba(255,255,255, 0.2)',       // White background
                    border: '2px solid #7b1fa2',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: 10,
                    paddingLeft: 0,                 // Add some padding for better appearance
                    paddingRight: 0,
                }}
                type="number"
                inputProps={{ min: 0 }}
                onChange={(e) => onChange(parseFloat(e.target.value))}
            />
            <Button color="secondary" onClick={increment}>+</Button>
        </ButtonGroup>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface PoolStructRaffle {
    poolIdRaffle?: BigNumber;
    totalFunds?: string;
    totalFundsClaimed?: string;
    endTime?: BigNumber;
    isEnabled?: boolean;
    isOnlyHolders?: boolean;
    isFreeTickets?: boolean;
    rafflFundsPerc?: BigNumber;
    isRaffleActive?: boolean;
    historyRaffle?: RaffleStruct[];
}

export interface RaffleStruct {
    pausedRf?: boolean;
    poolIdRf?: number;
    players?: string[];
    maxTickets?: number;
    ticketPrice?: number;
    numFreeTickets?: number;
    nftContract?: string;
    name?: string;
    tokenId?: number;
    rank?: number;
    lastOpenTimestampRf?: number;
    lastWinTimestampRf?: number;
    recentWinner?: string;
}

const Raffle: React.FC = () => {
    const [winner, setWinner] = useState<string | null>(null);
    const [poolHistory, setPoolHistory] = useState<any[]>([]);
    const { context, saveContext } = React.useContext(EtherContext) as EtherContextRepository;
    const classes = useStyles();
    const [poolId, setPoolId] = useState(0);
    const [poolData, setPoolData] = useState<PoolStructRaffle>({
        // Inizializza con valori di default o oggetti vuoti
        poolIdRaffle: undefined,
        totalFunds: undefined,
        totalFundsClaimed: undefined,
        endTime: undefined,
        isEnabled: false,
        isOnlyHolders: false,
        isFreeTickets: false,
        rafflFundsPerc: undefined,
        isRaffleActive: false,
        historyRaffle: [],
    });
    const [poolDataInput, setPoolDataInp] = useState({
        isOnlyHolders: true,
        isFreeTickets: false,
        rafflFundsPerc: 80,
    });

    const [openDialog, setOpenDialog] = React.useState(false);
    const [enabledPools, setEnabledPools] = React.useState<number[]>([])
    const [openAlert, setOpenAlert] = React.useState(false);
    const [countdownTime, setCountdownTime] = useState<number>(0);
    const [inputValue, setInputValue] = useState('');
    const [inputValueId, setInputValueId] = useState('');
    const [poolIds, setPoolIds] = useState<number[]>([]);
    const isMobile = useMediaQuery('(max-width:960px)');
    const [open, setOpen] = React.useState(false);
    const [alertType, setAlertType] = useState('success'); // 'success' o 'warning'
    const [enabledPoolsRaffle, setEnabledPoolsRaffle] = useState<number[]>([])


    const [isOnlyHolders, setOnlyHolders] = useState<boolean>(true);
    const [isFreeTickets, setIsFreeTicket] = useState<boolean>(false);
    const [rafflFundsPerc, setRafflFundsPerc] = useState<number>(0);

    /* OPEN RAFFLE */

    const [_poolId, set_PoolId] = useState<number>(0);
    const [_maxTickets, setMaxTickets] = useState<number>(0);
    const [_ticketPrice, setTicketPrice] = useState<number>(0);
    const [_nftContract, setNftContract] = useState<string>('');
    const [_name, setName] = useState<string>('');
    const [_tokenId, setTokenId] = useState<number>(0);
    const [_rank, setRank] = useState<number>(0);
    //———————————————————————————————————————————————————————

    /* RAFFLE STRUCT */

    const [raffleStruct, setRaffleStruct] = useState<any[]>([])
    console.log(raffleStruct)
    const [tokenCards, setTokenCards] = useState<JSX.Element[]>([]);

    //———————————————————————————————————————————————————————

    /* POOL STRUCT & TICKETS */

    const [poolStruct, setPoolStruct] = useState<any[]>([])
    //—________—___________—________—___________—____________

    const [freeTickets, setTicketFree] = useState<any>()
    const [amountTickets, setAmountTickets] = useState<number>(0);

    //———————————————————————————————————————————————————————



    const [alertSuccess, setAlertSuccess] = useState(false);
    const [alertFailedTitle, setAlertTitle] = useState('');
    const [loading, setLoading] = useState(true); // Stato per gestire il caricamento dei dati
    const [value, setValue] = React.useState(1);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    const handleInputChangeBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };

    useEffect(() => {
        const fetchTokenCards = async () => {
            const newTokenCards: JSX.Element[] = [];

            try {
                setLoading(true)
                if (raffleStruct) {
                    for (const [index, data] of raffleStruct.entries()) {
                        try {
                            const uri = await EtherHelper.getURI(data.nftContract, data.tokenId, context);
                            const tokenData = uri;
                            let image = '';
                            const poolId = data.poolId

                            const cancelRaffle = async () => {
                                try {
                                    EtherHelper.cancelRaffle(context, data.poolId)
                                } catch (e) {
                                    console.log(e)
                                }
                            }

                            const buyRaffle = async () => {
                                try {
                                    const tx = await EtherHelper.buyTicketsRaffle(context, data.poolId, 1, data.ticketPrice);
                                    console.log(tx)
                                    setOpen(true);
                                    setAlertType('success');
                                } catch (error: any) {
                                    if (error.code === 4001) {
                                        // L'utente ha rifiutato la transazione in MetaMask
                                        console.log("User denied transaction signature.");
                                        setAlertTitle('User denied transaction signature.');
                                        setAlertType('error');
                                    }
                                    if (error.code === -32603) {
                                        console.log("Check Your Balance");
                                        setOpen(true);
                                        setAlertTitle("Check Your Balance");
                                        setAlertType('error');
                                    }
                                    else {
                                        // Gestisci altri errori
                                        console.log("An error occurred during the transaction.");
                                        setOpen(true);
                                        setAlertTitle('An error occurred during the transaction.');
                                        setAlertType('error');
                                    }
                                }
                            };

                            const joinFreeRaffle = async () => {
                                try {
                                    const amount = 1
                                    const tx = await EtherHelper.joinFreeRaffle(poolId, amount, context);
                                    setAlertType('success');
                                } catch (error: any) {
                                    if (error.code === 4001) {
                                        // L'utente ha rifiutato la transazione in MetaMask
                                        console.log("User denied transaction signature.");
                                        setAlertTitle('User denied transaction signature.');
                                        setAlertType('error');
                                    }
                                    if (error.code === -32603) {
                                        console.log("Check Your Balance");
                                        setOpen(true);
                                        setAlertTitle("Check Your Balance");
                                        setAlertType('error');
                                    }
                                    else {
                                        // Gestisci altri errori
                                        console.log("An error occurred during the transaction.");
                                        setOpen(true);
                                        setAlertTitle('An error occurred during the transaction.');
                                        setAlertType('error');
                                    }
                                }
                            };


                            if (typeof tokenData.image === 'string' && tokenData.name.slice(0, 6) !== 'Marble') {
                                image = 'https://ipfs.io/ipfs/' + tokenData.image.slice(7);
                            } else {
                                image = tokenData.image
                            }

                            const ticketPriceInEth = data.ticketPrice / 1e18;

                            const card = (
                                <Card key={index} className={classes.cardz} style={{ width: 345, gap: 10, marginTop: 10, marginBottom: 10 }}>
                                    <CardHeader
                                        style={{ height: 150 }}
                                        title={
                                            <Typography gutterBottom variant="h5" className={classes.catchyTextSupplyCard} component="div">
                                                {data.name} {'#' + data.tokenId}
                                            </Typography>
                                        }
                                        subheader={
                                            <Typography gutterBottom className={classes.titleCount} variant="h5" component="div">
                                                {data.rank > 0 && (
                                                    <div>Rank: {data.rank}</div>
                                                )}
                                            </Typography>
                                        }
                                    />
                                    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                                        <Chip
                                            icon={<BadgeIcon />}
                                            label={'Only Holders'}
                                            color={data.poolStruct.isOnlyHolders ? 'success' : 'primary'}
                                            variant="outlined"
                                        />

                                        <Chip
                                            style={{ marginTop: 10 }}
                                            icon={<PaidIcon />}
                                            className={data.poolStruct.isFreeTickets ? classes.success : classes.denied}
                                            label={'Free Tickets'}
                                            color={data.poolStruct.isFreeTickets ? 'success' : 'error'}
                                        />

                                        <Chip
                                            style={{ marginTop: 10 }}
                                            icon={<NotificationIcon />}
                                            label={'Active'}
                                            color={data.poolStruct.isOnlyHolders ? 'success' : 'primary'}
                                        />
                                    </div>
                                    { /*  <Box sx={{ width: 300 }}>
                                        <Slider
                                            size="small"
                                            defaultValue={1}
                                            value={typeof value === 'number' ? value : 1}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                        />
                                        <Input
                                            value={value}
                                            size="small"
                                            onChange={handleInputChangeBlur}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: 0,
                                                max: 20,
                                                type: 'number',
                                                'aria-labelledby': 'input-slider',
                                            }}
                                        />
                                    </Box>*/}
                                    <Button style={{ marginTop: 10 }} color="secondary" variant="contained" size="large" onClick={buyRaffle}>Join</Button>
                                    {data.poolStruct.isFreeTickets
                                        ?
                                        <Button style={{ marginTop: 10, marginLeft: 10, marginRight: -5 }} color="primary" variant="outlined" onClick={joinFreeRaffle} size="large"><Badge style={{ marginRight: 10 }}><ConfirmationNumberIcon /></Badge>Join Free</Button>
                                        : <div />}
                                    <CardMedia
                                        sx={{ height: 200, mt: 5 }}
                                        image={image}
                                        title={data.name}
                                    />
                                    <CardContent>

                                        <Typography className={classes.titleCount} variant="body2">
                                            PRICE: {ticketPriceInEth ?? 0} CRO
                                        </Typography>
                                        <Typography className={classes.titleCount} variant="body2">
                                            PLAYERS: {data.players.length} / {data.maxTickets}
                                        </Typography>

                                        {data.poolStruct.isFreeTickets === true && (
                                            <Typography className={classes.titleCount} variant="body2">
                                                FREE TICKETS: {data.numFreeTickets}
                                            </Typography>

                                        )}
                                        <Typography className={classes.titleCount} variant="body2">
                                            RECENT WINNER: {data.recentWinner.slice(0, 5) + '...' + data.recentWinner.slice(-5)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {data.poolStruct.history.slice(-3).map((item: any, index: any) => (
                                                <div key={index}>
                                                    {item.recentWinner !== undefined && (
                                                        <p>Recent Winner: {item.recentWinner.slice(0, 5) + '...' + item.recentWinner.slice(-5)}</p>
                                                    )}
                                                </div>
                                            ))}
                                        </Typography>
                                        {context.addressSigner?.toLowerCase() && context.addressSigner?.toLowerCase() === deployer.toLowerCase() && (
                                            <div>
                                                <Button style={{ marginTop: 10, marginLeft: 10, marginRight: -5 }} color="primary" variant="outlined" onClick={cancelRaffle} size="large">Cancel Raffle</Button>
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                            );

                            newTokenCards.push(card);
                        } catch (error) {
                            console.error('Errore durante il recupero dei dati:', error);
                            // Gestisci l'errore in modo appropriato, ad esempio, escludendo l'elemento.
                        } finally {
                            setLoading(false)
                        }
                    }
                }

                setTokenCards(newTokenCards);
            } catch (error) {
                console.error('Errore durante il recupero dei dati:', error);
                // Gestisci l'errore in modo appropriato
                setLoading(false); // Assicurati che il caricamento sia impostato su false anche in caso di errore
            }
        };


        fetchTokenCards();
    }, [context, raffleStruct, enabledPoolsRaffle]);

    console.log(enabledPoolsRaffle)

    const PoolStructure = enabledPoolsRaffle.map(number => {
        return {
            value: number,
            poolData: poolData,
        };
    });

    const getRaffleData = async (poolIds: number[], context: IEtherContext) => {
        const raffleDataArray = [];

        for (const poolId of poolIds) {
            try {
                const raffleData = await EtherHelper.getPoolRaffle(poolId, context);

                // Verifica se i dati ricevuti sono validi (ad esempio, se non è un errore)
                if (raffleData && !raffleData.error) {
                    const poolStruct = await EtherHelper.getRaffleStruct(context, poolId);
                    raffleData.poolStruct = poolStruct; // Aggiungi poolStruct a raffleData
                    raffleDataArray.push(raffleData);
                }
            } catch (error) {
                console.error(`Errore durante il recupero dei dati per il poolId ${poolId}:`, error);
            }
        }

        return raffleDataArray;
    };

    const withdrawRaffle = async () => {
        try {
            EtherHelper.withdrawRaffle(context)
        } catch (e) {
            console.log(e)
        }
    }

    const claimFreeTickets = async () => {
        try {
            const tx = await EtherHelper.claimFreeTicket(context);
            setAlertType('success');
        } catch (error: any) {
            if (error.code === 4001) {
                // L'utente ha rifiutato la transazione in MetaMask
                console.log("User denied transaction signature.");
                setAlertTitle('User denied transaction signature.');
                setAlertType('error');
            }
            if (error.code === -32603) {
                console.log("Check Your Balance");
                setOpen(true);
                setAlertTitle("Check Your Balance");
                setAlertType('error');
            }
            else {
                // Gestisci altri errori
                console.log("An error occurred during the transaction.");
                setOpen(true);
                setAlertTitle('An error occurred during the transaction.');
                setAlertType('error');
            }
        }
    };

    useEffect(() => {
        if (context.connected === true) {
            getEnabledPools()
            getRaffleData(enabledPoolsRaffle, context)
                .then((raffleDataArray) => {
                    setRaffleStruct(raffleDataArray)
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            getEnabledPools()
            getRaffleData(enabledPoolsRaffle, context)
                .then((raffleDataArray) => {
                    setRaffleStruct(raffleDataArray)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [context.connected, context])

    const handleAlertClose = () => {
        setOpen(false);
    };

    const handleAddPoolIds = () => {
        if (inputValueId !== '') {
            const newPoolIds = inputValueId
                .split(',')
                .map(id => Number(id.trim()))
                .filter(id => !isNaN(id));
            setPoolIds(newPoolIds);
            setInputValueId('');
        }
    };

    const getEnabledPools = () => {
        try {
            EtherHelper.getEnabledPoolsRaffle(context).then(
                (pools) => { setEnabledPoolsRaffle(pools) }
            )
        } catch (e) {
        }
    }

    const handleClickAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
    }

    const setApprovalForAll = async () => {
        const operator = '0x6De751fc359a32eDd3cd4164f8750f303f4BCE03'
        try {
            EtherHelper.setApprovalForAll(_nftContract, operator, context)
        } catch (e) {
            console.log("Error Approval NFT: ", e)
        }
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const refreshRaffle = async () => {
        setLoading(true)
        getEnabledPools()
        getRaffleData(enabledPoolsRaffle, context)
            .then((raffleDataArray) => {
                setRaffleStruct(raffleDataArray)
            })
            .catch((error) => {
                console.error(error);
            });
        setLoading(false)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPoolData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleInputChangeInp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPoolDataInp({
            isOnlyHolders: isOnlyHolders,
            isFreeTickets: isFreeTickets,
            rafflFundsPerc: rafflFundsPerc,
        });
    };

    console.log(_poolId)
    const handleSetPool = async () => {
        try {
            await EtherHelper.setPoolRaffle(poolId, isOnlyHolders, isFreeTickets, rafflFundsPerc, context)
            setIsFreeTicket(false)
            setRafflFundsPerc(80)
            setOnlyHolders(false)
            console.log('Pool set successfully!');
        } catch (error) {
            console.error('Error setting pool:', error);
        }
    };

    const openRaffle = async () => {
        try {
            await EtherHelper.openRaffle(
                context,
                _poolId,
                _maxTickets,
                _ticketPrice,
                _nftContract,
                _name,
                _tokenId,
                _rank
            )
        } catch (e) {

        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [ticketData, setTicketData] = useState<any>({
        qty: 0,
        lastClaim: 0,
    });

    const getFreeTicketAmount = async () => {
        if (context.connected === true) {
            try {
                const tx = await EtherHelper.getFreeTickets(context, context.addressSigner ?? '');
                // Eseguire la conversione dai BigNumber ai numeri JavaScript
                const qtyValue = tx[0].toNumber();
                const lastClaimValue = tx[1].toNumber();
                setTicketData({ qty: qtyValue, lastClaim: lastClaimValue });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const convertUnixTimestampToDateString = (timestamp: number) => {
        if (timestamp > 0) {
            const date = new Date(timestamp * 1000); // Moltiplica per 1000 per convertire da secondi a millisecondi
            return date.toLocaleString(); // Converte la data in una stringa leggibile
        }
        return ''; // Restituisce una stringa vuota se il timestamp è <= 0
    };

    useEffect(() => {
        getFreeTicketAmount();
    }, [context.connected]);

    return (
        <div className={classes.root}>
            <div className={classes.overlay} />
            <Snackbar open={open}
                autoHideDuration={6000} onClose={handleAlertClose}>
                <MuiAlert
                    onClose={handleAlertClose}
                    severity={alertType === 'success' ? 'success' : 'warning'}
                    elevation={24}
                    style={{ marginBottom: 50 }}
                >
                    {alertType === 'success' ? 'Successfully!' : ''}
                    {alertType !== 'success' ? alertFailedTitle : ''}
                </MuiAlert>
            </Snackbar>
            <Grid container spacing={3} style={{ marginTop: 0 }}>
                <Grid item xs={12} md={12}>
                    <Paper elevation={3} style={{ minHeight: 650 }} className={classes.paper}>
                        <div className={classes.inputContainer}>
                            <div style={{ marginBottom: 0 }}>
                                <Typography className={classes.sectionTitleRF}>HYENA RAFFLES</Typography>
                                <div>
                                    {loading ? (
                                        // Mostra uno spinner durante il caricamento
                                        <Button
                                            onClick={refreshRaffle}
                                            style={{ marginBottom: 20 }}
                                            color="secondary"
                                            variant="contained"
                                            size="large"
                                        >
                                            Join
                                        </Button>
                                    ) : (
                                        // Mostra il pulsante quando non è in corso alcun caricamento
                                        <Button
                                            onClick={refreshRaffle}
                                            style={{ marginBottom: 20 }}
                                            color="secondary"
                                            variant="contained"
                                            size="large"
                                        >
                                            Refresh Raffles
                                        </Button>
                                    )}                                    {loading === false && context.connected ?
                                        (
                                            <div>
                                                <Typography className={classes.sectionTitle}>FREE TICKETS</Typography>
                                                <Button onClick={getFreeTicketAmount} style={{ marginTop: 10 }} color="primary" variant="outlined" size="large"> Check</Button>
                                                <Button onClick={claimFreeTickets} style={{ marginLeft: 10, marginTop: 10 }} color="primary" variant="contained" size="large"> Claim</Button>
                                                <div style={{ marginTop: 10, marginBottom: 10 }} className={classes.titleCount}>Your Free Tickets: {ticketData.qty}</div>
                                                <div style={{ marginTop: 10, marginBottom: 10 }} className={classes.titleCount}>Last Claim: {convertUnixTimestampToDateString(ticketData.lastClaim)}</div>
                                                <div style={{ marginTop: 10, marginBottom: 10 }} className={classes.titleCount}>Hypa: {context.hyenaTokenIds?.length}</div>
                                            </div>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    <Grid container spacing={2} style={{ justifyContent: 'center', display: 'flex' }}>
                                        {loading ? (
                                            <div></div>
                                        ) : (
                                            tokenCards.map((card, index) => (
                                                <Grid item xs={12} sm={4} key={index} style={{ justifyContent: 'center', display: 'flex' }}>
                                                    <div key={index}>{card}</div>
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>
                                </div>
                                {!isMobile && (
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>

                                    </div>
                                )}
                            </div>
                            {isMobile && (
                                <div>

                                </div>
                            )}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12}>
                </Grid>
                <div style={{ marginBottom: 100, height: 100 }}></div>
                {context.addressSigner?.toLowerCase() && context.addressSigner?.toLowerCase() === deployer.toLowerCase() && (
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={3} style={{ marginTop: 20, marginBottom: 80 }}>
                            <Grid item xs={12} md={6}>
                                <Paper className={classes.paper2} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        className={classes.textField}
                                        label="Pool ID"
                                        type="number"
                                        value={poolId}
                                        onChange={(e) => setPoolId(parseFloat(e.target.value))}
                                    />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={isOnlyHolders}
                                            onChange={(e) => setOnlyHolders((e.target as HTMLInputElement).checked)}
                                        />}
                                        label="Only Holders" />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={isFreeTickets}
                                            onChange={(e) => setIsFreeTicket((e.target as HTMLInputElement).checked)}
                                        />}
                                        label="Free Tickets" />
                                    <TextField
                                        className={classes.textField}
                                        label="Raffle Funds Perc"
                                        type="number"
                                        name="Raffle Funds Perc"
                                        value={rafflFundsPerc}
                                        onChange={(e) => setRafflFundsPerc(parseFloat(e.target.value))}
                                    />
                                    <Button variant="contained" color="primary" onClick={handleSetPool}>
                                        Set Pool
                                    </Button>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper className={classes.paper2} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Pool ID"
                                                type="number"
                                                value={_poolId}
                                                onChange={(e) => set_PoolId(parseFloat(e.target.value))}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Max Tickets"
                                                type="number"
                                                value={_maxTickets}
                                                onChange={(e) => setMaxTickets(parseInt(e.target.value))}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Ticket Price"
                                                type="number"
                                                value={_ticketPrice}
                                                onChange={(e) => setTicketPrice(parseFloat(e.target.value))}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="NFT Contract"
                                                value={_nftContract}
                                                onChange={(e) => setNftContract(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Name"
                                                value={_name}
                                                onChange={(e) => setName(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Token ID"
                                                type="number"
                                                value={_tokenId}
                                                onChange={(e) => setTokenId(parseInt(e.target.value))}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Rank"
                                                type="number"
                                                value={_rank}
                                                onChange={(e) => setRank(parseInt(e.target.value))}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button variant="contained" style={{ marginTop: 10 }} color="primary" onClick={openRaffle}>
                                        Open Raffle
                                    </Button>
                                    <Button variant="contained" color="primary" style={{ marginTop: 10 }} onClick={setApprovalForAll}>
                                        Approve NFT
                                    </Button>
                                    <Button variant="contained" color="primary" style={{ marginTop: 10 }} onClick={withdrawRaffle}>
                                        Withdraw
                                    </Button>


                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <div style={{ height: 100 }}></div>
            </Grid>
        </div >
    );
};

export default Raffle;
