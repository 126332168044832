/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Modal,
    makeStyles,
    useMediaQuery,
    Grid,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
    Fab,
    Tooltip,
    TextField,
    Divider,
    Button,
    CardContent,
    Card,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    Badge,
} from '@material-ui/core';
import { AvatarGroup } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';
import SyncIcon from '@mui/icons-material/Sync';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EtherHelper from '../../../ethers/EtherHelper';
import { IEtherContext } from '../../../ethers/IEtherContext';
import LinearBuffer from './LinearBuffer';
import { ILevel } from '../../../entities/ILevel';
import { EtherContext } from '../../../ethers/EtherContext';
import { EtherContextRepository } from '../../../ethers/EtherContextRepository';

const useStyles = makeStyles((theme) => ({
    modal: {
        background: 'black',
        minWidth: 400,
        minHeight: 300,
    },
    gridContainer: {
        flexGrow: 1,
    },
    gridItem: {
        padding: theme.spacing(2),
        textAlign: 'center',
        minHeight: 50,
        width: '100%',
    },
    avatarGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        cursor: 'pointer',
    },
    selectedAvatar: {
        border: '2px solid green',
        cursor: 'pointer',
    },
    dialogTitle: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    successText: {
        color: theme.palette.success.main,
    },
    title: {
        marginBottom: theme.spacing(2),
        fontSize: 24,
        color: '#f57c00',
        fontWeight: 'bold',
        fontFamily: 'Rubik Wet Paint',
    },
    accordion: {
        backgroundColor: '#f57c00',
        border: '#f57c00',
        borderRadius: 5,
        margin: '10px 0',
    },
    accordionSummary: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    card: {
        marginTop: 10
    }
}));

interface DataCollection {
    collection: string[];
    tokenId?: number[];
    contractName: string;
}

const URIBooster = async (address: string, tokenId: number, context: IEtherContext) => {
    const uri = await EtherHelper.getURI(address, tokenId, context)
    return uri
}

const fetchNFTData = async (objData: DataCollection, context: IEtherContext) => {
    const nftData = await Promise.all(
        objData.tokenId?.map(async (nft) => {
            const uri_nft = await URIBooster(objData.collection[0], nft, context);
            return { nft, uri_nft };
        }) || []
    );

    return nftData;
};

export const selectedBooster = (level: number, selectedAvatars: { nft: number; uri_nft_image: string, uri_nft_name: string, uri_0x: string }[]) => {
    console.log(selectedAvatars);
    if (selectedAvatars.length > level) {
        console.log(`You can select max ${level} boosters.`);
        alert(`You can select max ${level} boosters.`);
        return;
    }
};



export const ModalBooster = ({
    level,
    openModal,
    onClose,
    handleSelectedAvatars,
    hh_id,
    opt,
}: {
    level: number;
    openModal: boolean;
    hh_id: number;
    opt: number;
    onClose: () => void;
    handleSelectedAvatars: (selectedAvatars: {
        nft: number;
        uri_nft_image: string;
        uri_nft_name: string;
        uri_0x: string;
    }[]) => void;
}) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:960px)');
    const [isLoading, setIsLoading] = useState(false);
    const [StakedBoosterCards, setStakedBoosterCards] = useState<JSX.Element[]>([]);
    const [level_data, setLevelData] = useState<ILevel>({
        cro_price: 0,
        xhyn_price: 0,
        boost_slot: 0,
        last_upgrade: 0,
    });

    const [selectedAvatars, setSelectedAvatars] = useState<{
        nft: number;
        uri_nft_image: string;
        uri_nft_name: string;
        uri_0x: string;
    }[]>([]);
    const [state_selectedAvatars, setState_SelectedAvatars] = useState<{
        nft: number;
        uri_nft_image: string;
        uri_nft_name: string;
        uri_0x: string;
    }[]>([]);
    const [alertInfo, setAlertInfo] = useState<{ severity: string, message: string } | null>(null);
    const { context, saveContext } = useContext(EtherContext) as EtherContextRepository;
    const [isLoadingTx, setLoadingTx] = useState(false)
    const [isSuccess, setSuccess] = useState(false)

    const handleRemoveAll = () => {
        setSelectedAvatars([]);
    };

    const joinStaking = async (tokenId: number, vesting: number, boost_0x: string[], boost_Id: string[]) => {
        try {
            setIsLoading(true)
            const ctx = await EtherHelper.joinStaking(context, tokenId, vesting, boost_0x, boost_Id)
            saveContext(ctx);
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })
            } else if (ctx.toastStatus === 'error') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })

            }
            console.log("JoinStaking", ctx)
            setIsLoading(false)
            setSuccess(true)
        } catch (e: any) {
            console.log("Error on staking section: ", e)
            return { ...context }
        } finally {
            setIsLoading(false)
        }
    }

    const selectedNfts: number[] = [];
    const selectedCollections: string[] = [];

    let Id_Boost = selectedAvatars.map((nft) => nft.nft.toString());
    let Ox_Boost = selectedAvatars.map((nft) => nft.uri_0x);
    console.log(Id_Boost, Ox_Boost)

    const getStakeLevelData = async (level: number) => {
        try {
            const lvl_data = await EtherHelper.getStakeLevelData(context, level)
            setLevelData(lvl_data ?? undefined)
            return lvl_data
        } catch (e) {
            console.log('Error on apr: ', e)
        }
    }

    useEffect(() => {
        getStakeLevelData(level + 1)
        fetchBoosterCards()
    }, [])

    const fetchBoosterCards = async () => {
        setIsLoading(true);
        try {
            const add_booster = [
                '0x257f30fbD890840FA00c2e0f043cF5Ad9A631546', '0x4ab89f361a7db22beee8df47630c52b9167f7bb7',
                '0x7841dA84C0eC87744306C6420Eb039b8Cbd39d70', '0xC5Fe01a64e63EDefC40d620A5716B90c52D444f9',
                '0x41C7874A2fE9D1ea126bfBD44597A1f96546152e', '0xC0D28282A4780e099884f344A9746A2D75A1BAF7',
                '0x8f2836874DC85B81C2CF0421aF593E6E8d5DffA1',
                '0x272D3ad398742D5f04751fbf8b6cc17401beAe11', '0xe65FAA8791F25E9fB034f1C8913f14F6Db12C6C0',
                '0xA560c70220c32c81217cF993676d177d43D48349', '0xE1049178296ce004996AFb16B0816c5A95aC8482',
                '0x933851f01d146c04D051b8FC7ccb8035828873F7', '0x757Df1C3a75f07A6E3F440E480Ac1422cD91e2a6',
                '0x8333624EEb54E0c9FF6C96D41645d60463CE9b22', '0x08FB1f6625d034019f2F6a3E70bab2FaA55CA068',
                '0xc427e0b2e4C9b79F856391b1EF37fD7ee7457d8D',
                '0x48A81b2120EA832CEcd83f35eC5F84D4c7249B50',
                '0xBd366a51cDE8615C8DAcab70E9f9A045ffdc898b', '0x254c76deB8F7eCBAB0eA67C5AC54B2266a306Ef9',
                '0x37f25F400983256e84c39749d05d65fD96Ca461d', '0x72a5fE7E627faeF0B0b7DF0b7677d1E43b429958',
                '0xf601FfEA6A76E83921750361E613d82286c4Ad9B', '0x1375D3D2169C66d325514EB61933A08c276Ca531',
                '0xdbFDf81D1fDD2e79e8ffaDE50c219452587e9488',
            ];

            const dataCollection: DataCollection[] = [];

            for (const address of add_booster) {
                const tokenId = await EtherHelper.getBalanceOf(context, address);
                const name = await EtherHelper.nameOfErc721(context, address);
                if (tokenId !== undefined && name !== undefined) {
                    const collectionData: DataCollection = {
                        collection: [address],
                        tokenId: tokenId,
                        contractName: name,
                    };
                    dataCollection.push(collectionData);
                }
            }

            console.log(dataCollection);

            const isAvatarSelected = (nft: number) =>
                selectedAvatars.some((avatar) => avatar.nft === nft);

            const newStakedBoosterCards: JSX.Element[] = [];

            console.log("selectedNfts&selectedCollection: ", selectedCollections, selectedNfts)

            for (const objData of dataCollection) {
                const x_add = objData.collection.toString()
                if (objData.tokenId?.length && objData.tokenId.map) {
                    const avatarCards = objData.tokenId.map((nft, index) => {

                        selectedNfts.push(nft);
                        selectedCollections.push(objData.collection[0]);

                        return (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <AvatarGroup max={5}>
                                    <Tooltip title={isAvatarSelected(nft) ? 'Selected' : 'Select Booster'} arrow>
                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                            <IconButton
                                                onClick={() => handleAvatarClick(nft, 'Hye.jpg', objData.contractName, x_add)}
                                                disabled={selectedAvatars.length === level || isAvatarSelected(nft)}
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                <Avatar
                                                    alt={`NFT ${nft}`}
                                                    src={'Hye.jpg'}
                                                    className={isAvatarSelected(nft) ? classes.selectedAvatar : ''}
                                                />
                                            </IconButton>
                                            <Typography style={{ color: 'black' }} variant="body1">{nft}</Typography>

                                        </div>
                                    </Tooltip>
                                </AvatarGroup>
                            </div>
                        )
                    });

                    // Group avatars in pairs
                    const avatarsInPairs = [];
                    for (let i = 0; i < avatarCards.length; i += 2) {
                        avatarsInPairs.push(
                            <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                                {avatarCards.slice(i, i + 2)}
                            </div>
                        );
                    }

                    newStakedBoosterCards.push(
                        <Accordion key={objData.contractName} className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-${objData.contractName}-content`}
                                id={`panel-${objData.contractName}-header`}
                                className={classes.accordionSummary}
                            >
                                <Typography style={{ fontWeight: 'bold', color: 'black' }}>{`${objData.contractName} - (${objData.tokenId?.length})`}</Typography>
                                <div>
                                    {/* Add any specific actions for AccordionSummary here */}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.avatarGroup}>{avatarsInPairs}</div>
                            </AccordionDetails>
                        </Accordion>
                    );
                }
            }



            setStakedBoosterCards(newStakedBoosterCards);
        } catch (error) {
            console.error('Error fetching booster cards:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAvatarClick = (nft: number, uri_nft_image: string, uri_nft_name: string, uri_0x: string) => {
        setSelectedAvatars((prev) => {
            console.log("Previous state:", prev);

            if (prev.length >= level) {
                console.log(`Avatar already selected or max limit reached.`);
                return prev;
            }

            const isNewAvatarSelected = !prev.some((avatar) => avatar.nft === nft);
            const newState = isNewAvatarSelected ? [...prev, { nft, uri_nft_image, uri_nft_name, uri_0x }] : prev;

            if (newState.length > level) {
                newState.pop();
            }

            console.log("New state:", newState);

            setState_SelectedAvatars(newState);
            return newState;
        });
    };


    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        address: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
    });

    const handleConfirmSelection = () => {
        selectedBooster(level, selectedAvatars);
        handleSelectedAvatars(selectedAvatars);
        onClose();
    };

    //selected vesting opt

    const options = ['2W', '1M', '6M', '12M'];
    const selectedOption = options[opt];

    return (
        <Modal open={openModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={onClose}>
            <Box
                style={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isMobile ? '400px' : '800px',
                    backgroundColor: 'black',
                    border: 'black',
                    padding: 4,
                    borderRadius: 5,
                    maxHeight: 700,
                    overflowY: 'auto',
                }}
            >
                {isLoading ? (
                    <LinearBuffer />
                ) : isSuccess ? (
                    <Dialog open={isSuccess} onClose={onClose} maxWidth="sm" fullWidth>
                        <DialogTitle className={classes.dialogTitle}>Successfully!</DialogTitle>
                        <DialogContent>
                            <Typography className={classes.successText} variant="body1">
                            Great news, you've entered the staking!
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={onClose}>
                                CLOSE
                            </Button>
                        </DialogActions>
                    </Dialog>
                ) : (
                    <Grid container spacing={2}>
                        {/* Grid a destra */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" className={classes.title} gutterBottom>
                                BOOSTER
                            </Typography>
                            <Divider style={{ marginTop: 20 }} />
                            <div style={{ marginTop: 20 }}>{StakedBoosterCards}</div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography variant="h4" className={classes.title} gutterBottom>
                                    CHECKOUT STAKING
                                </Typography>
                                <Divider style={{ marginTop: 20 }} />
                                {selectedAvatars && (
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginLeft: 1 }}>
                                        {selectedAvatars.map((avatar, index) => (
                                            <div key={index} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginLeft: 10}}>
                                                <Typography style={{ color: 'white' }} variant="caption"> {`${avatar.uri_nft_name}`}</Typography>
                                                <Avatar
                                                    key={index}
                                                    alt={avatar.nft.toString()}
                                                    src={avatar.uri_nft_image}
                                                    className={classes.selectedAvatar}
                                                    onClick={() => handleAvatarClick(avatar.nft, avatar.uri_nft_image, '', avatar.uri_0x)}
                                                />
                                                <Typography style={{ color: 'white' }} variant="caption"> {`ID: ${avatar.nft}`}</Typography>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {!selectedAvatars && <div>No Boosters Selected</div>}
                                <Fab variant="extended" style={{ marginTop: 20, marginLeft: 0, zIndex: 1000 }}>
                                    <Chip color='primary' label={'Vesting ' + selectedOption} />
                                    <IconButton>
                                        <NavigationIcon sx={{ mr: 1 }} onClick={() => joinStaking(hh_id, opt, Ox_Boost, Id_Boost)} />
                                    </IconButton>
                                    <IconButton>
                                        <SyncIcon sx={{ mr: 1 }} onClick={fetchBoosterCards} />
                                    </IconButton>
                                    <IconButton>
                                        <Badge badgeContent={selectedAvatars.length ?? 0} color="primary">
                                            <CancelIcon sx={{ mr: 1 }} onClick={handleRemoveAll} />
                                        </Badge>
                                    </IconButton>
                                </Fab>

                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Modal>
    );
};
