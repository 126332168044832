import React, { ReactNode, useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Connector from './components/Connector';
import { ModeSwitcher } from './components/ModeSwitcher';
import Home from './components/dapp/Home';
import { Flex, Icon, FlexProps, useDisclosure } from '@chakra-ui/react';
import {
  FiHome,
  FiGitlab,
  FiDollarSign,
  FiHelpCircle,
  FiCreditCard,
  FiTarget,
  FiUsers,
  FiAward
} from 'react-icons/fi';
import { EtherContext } from './ethers/EtherContext';
import { EtherContextRepository } from './ethers/EtherContextRepository';
import { IconType } from 'react-icons';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Welcome from './components/Welcome';
import Launchpad from './components/dapp/Launchpad';
import FAQ from './components/dapp/FAQ';
import Team from './components/dapp/Team';
import Hyenas from './components/dapp/Hyenas';
import Casino from './components/dapp/Casinò';
import Lottery from './components/dapp/Lottery';
import Raffle from './components/dapp/Raffle';
import { useLocation } from 'react-router-dom';
import { Coinflip } from './components/dapp/Coinflip';
import { HiLow } from './components/dapp/HiLow';
import AdminCardManager from './components/dapp/Partnership';
import Staking from './components/dapp/Staking';
import Royalties from './components/dapp/Royalties';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      background: '#333',
      color: '#fff',
      minHeight: '100vh',
      width: '100%'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: 'rgba(17, 17, 17, 0.7)',
      backdropFilter: 'blur(10px)',
      border: '2px solid transparent',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
      height: 56,
      width: '100%',
    },
    hyStyle: {
      background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      animation: '$rainbow 5s infinite', fontFamily: 'Rubik Glitch', // Sostituisci con il nome del tuo font "pazzachi" per HY
    },
    enaStyle: {
      background: `linear-gradient(225deg, #FE6B8B 30%, #FF8E53 90%)`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      animation: '$rainbow 5s infinite', fontFamily: 'Rubik Glitch', // Sostituisci con il nome del tuo font "pazzachi" per ENA
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#111',
      border: '2px solid transparent',
      borderTop: 'none',
      borderBottom: 'none',
      borderLeft: 'none',
      borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
    },
    drawerPaperDesktop: {
      width: drawerWidth,
      background: '#111',
      border: '2px solid transparent',
      borderTop: 'none',
      borderBottom: 'none',
      borderLeft: 'none',
      borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
    },
    content: {
      flexGrow: 1,
      marginTop: 20,
      minWidth: "100%"
    },
    nav: {
      width: '100%',
      background: '#111', // Darker drawer background
      color: 'white',
    },
    gradient: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    navItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(0),
      borderRadius: theme.spacing(2),
      cursor: 'pointer',
      textDecoration: 'none',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        background: '#FF8053',
        color: 'white',
      },
    },
    navIcon: {
      marginRight: theme.spacing(1),
      fontSize: 18,
    },
    catchyText: {
      alignItems: 'center',
      textAlign: 'center',
      marginLeft: 10,
      marginRight: 10,
      color: 'white',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: 16,
    },
    rootLoader: {
      position: 'relative',
      flexGrow: 1,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      minWidth: '100%',
      height: '100%',
      backgroundImage: 'url("bghome.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      marginLeft: 0,
      marginRight: 0,
      alignItems: 'center',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
        zIndex: -1,
      },
    },
    overlayLoader: {
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
      backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
      zIndex: 0,
    },
    logoSpin: {
      animation: '$spin 2s linear infinite', // Applying the spinning animation
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Adjust this value based on your layout
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)', // Initial rotation angle
      },
      '100%': {
        transform: 'rotate(360deg)', // Final rotation angle after 360 degrees
      },
    },
    logoImage: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    rainbowText: {
      background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      animation: '$rainbow 5s infinite',
      fontWeight: 'bold', // Aggiunto il grassetto
      fontFamily: 'Roboto',
    },
    rainbowText2: {
      background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      animation: '$rainbow 5s infinite',
      fontWeight: 'bold', // Aggiunto il grassetto
      fontFamily: 'Rubik Wet Paint',
    },
    '@keyframes rainbow': {
      '0%': { color: theme.palette.secondary.main },
      '25%': { color: theme.palette.error.main },
      '50%': { color: theme.palette.primary.main },
      '75%': { color: theme.palette.info.main },
      '100%': { color: theme.palette.success.main },
    },
  })
);

interface NavItemProps extends FlexProps {
  to: string;
  icon: IconType;
}

const NavItem = ({ icon, to, children, ...rest }: NavItemProps) => {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.navItem}>
      {icon && (
        <Icon
          ml="4"
          mb="3"
          fontSize="14"
          className={classes.navIcon}
          _groupHover={{
            color: 'white',
          }}
          style={{ color: 'white' }}
          as={icon}
        />
      )}
      <Typography variant="body1" className={classes.catchyText}>{children}</Typography>
    </Link>
  );
};

interface LinkItemProps {
  paths: string[];
  name: string;
  icon: IconType;
  element?: ReactNode;
  isExternal?: boolean;
  divider?: boolean;
  submenu?: any[];
}

const SubmenuItems = [
  { name: 'Lottery', icon: FiCreditCard, path: '/casino/lottery', element: <Lottery /> },
  { name: 'Raffle', icon: FiDollarSign, path: '/casino/raffle', element: <Raffle /> },
  { name: 'Coinflip', icon: FiTarget, path: '/casino/coinflip', element: <Coinflip /> },
  { name: 'Hi-Lo', icon: FiAward, path: '/casino/hilow', element: <HiLow /> },
];

const LinkItems: Array<LinkItemProps> = [
  { name: 'Home', icon: FiHome, paths: ['/home'], element: <Home /> },
  { name: 'HYPA', icon: FiCreditCard, paths: ['/HYPA'], element: <Launchpad /> },
  {
    name: 'Casinò',
    icon: FiDollarSign,
    paths: ['/casino'],
    element: <Casino />,
    submenu: SubmenuItems,
  },
  { name: 'Launchpad Hyenas', icon: FiGitlab, paths: ['/hyenas'], element: <Hyenas /> },
  { name: 'Staking', icon: FiCreditCard, paths: ['/staking'], element: <Staking /> },
  { name: 'Royalties', icon: FiCreditCard, paths: ['/royalties'], element: <Royalties/>},
  { name: 'Partnerships', icon: FiUsers, paths: ['/partnerships'], element: <AdminCardManager /> },
  { name: 'FAQ', icon: FiHelpCircle, paths: ['/faq'], element: <FAQ /> },
  { name: 'Team', icon: FiUsers, paths: ['/team'], element: <Team /> },
];

  //{ name: 'Royalties', icon: FiCreditCard, paths: ['/royalties'], element: <Royalties /> },


const App: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:960px)');
  const [mobileOpen, setMobileOpen] = useState(false);
  const { context } = React.useContext(EtherContext) as EtherContextRepository;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false)
  const [isCasinoSubMenuOpen, setIsCasinoSubMenuOpen] = useState(false); // Stato per il sottomenu "Casinò"

  const handleCasinoSubMenuToggle = () => {
    setIsCasinoSubMenuOpen(!isCasinoSubMenuOpen);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Stato per il drawer

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(loadingTimer); // Clear the timeout on unmount
    };
  }, []);

  const currentPathname = window.location.pathname;

  // Conditionally render the logo based on the current pathname
  const shouldRenderLogo = !['/casino/raffle', '/casino/lottery', '/casino/coinflip', '/casino/hilow'].includes(currentPathname);


  if (isLoading) {
    return (
      <div className={classes.rootLoader}>
        <div className={classes.logoSpin}>
          <img src="loader.png" alt="Logo" className={classes.logoImage} />
        </div>
      </div>
    )
  }

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {shouldRenderLogo ? (
              <img src="clogo.png" alt={'Logo'} style={{ height: '30px', marginRight: '10px' }} />
            ) : (
              <Typography variant="h5">
                <span className={classes.hyStyle}>HY</span>
                <span className={classes.enaStyle}>ENA</span>
              </Typography>
            )}
            {!isMobile && (
              <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', gap: 10 }}>
                {LinkItems.slice(0, -4).map((link, key) => (
                  <div key={key}>
                    <NavItem
                      icon={link.icon}
                      to={link.paths[0]}
                      onClick={() => {
                        if (link.name === 'Casinò') {
                          handleCasinoSubMenuToggle(); // Gestisci il sottomenu "Casinò" quando l'utente fa clic
                        } else {
                          onClose();
                        }
                      }}
                    >
                      {link.name}
                    </NavItem>
                    {link.name === 'Casinò' && isCasinoSubMenuOpen && (
                      <div className={classes.nested}>
                        {SubmenuItems.map((submenuItem, submenuKey) => (
                          <NavItem
                            key={submenuKey}
                            icon={submenuItem.icon}
                            to={submenuItem.path}
                            onClick={onClose}
                          >
                            {submenuItem.name}
                          </NavItem>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {isMobile && (
              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 0, marginRight: 10, width: '100%', justifyContent: 'flex-end' }}>

                <Connector>
                  <ModeSwitcher mr={4} />
                </Connector>
              </div>
            )}
            {!isMobile && (
              <>
                <IconButton
                  color="secondary"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <Connector>
                  <ModeSwitcher mr={4} />
                </Connector>
              </>
            )}
          </Toolbar>
        </AppBar>
        {isMobile && (
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', gap: 20, flexDirection: 'column', marginTop: 10 }}>
              {LinkItems.map((link, key) => (
                <React.Fragment key={key}>
                  <NavItem icon={link.icon} to={link.paths[0]} onClick={onClose}>
                    {link.name}
                  </NavItem>
                  {link.submenu && (
                    <div className={classes.nested}>
                      {link.submenu.map((submenuItem, submenuKey) => (
                        <NavItem key={submenuKey} icon={submenuItem.icon} to={submenuItem.path} onClick={onClose}>
                          {submenuItem.name}
                        </NavItem>
                      ))}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </Drawer>
        )}
        {!isMobile && (
          <Drawer
            variant="temporary"
            anchor="right"
            open={isDrawerOpen}
            onClose={handleDrawerToggle} // Chiudi il drawer quando si fa clic sull'icona dell'hamburger
            classes={{
              paper: classes.drawerPaperDesktop,
            }}
          >
            <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', gap: 20, marginTop: 20, flexDirection: 'column' }}>
              {LinkItems.slice(-4).map((link, key) => (
                <div key={key}>
                  <NavItem
                    icon={link.icon}
                    to={link.paths[0]}
                    onClick={handleDrawerToggle} // Chiudi il drawer quando si fa clic su un elemento
                  >
                    {link.name}
                  </NavItem>
                </div>
              ))}
            </div>
          </Drawer>
        )}
        <div className={classes.content}>
          {isLoading && (
            <div className={classes.rootLoader}>
              <div className={classes.logoSpin}>
                <img src="loader.png" alt="Logo" className={classes.logoImage} />
              </div>
              <div className={classes.overlayLoader} />
            </div>
          )}
          <Routes>
            {LinkItems.map((link, key) => {
              return link.paths.map((path, pathKey) => <Route key={`${key}_${pathKey}`} path={path} element={link.element} />)
            })}
            <Route path="/" element={<Welcome />} />
            <Route path="/partnerships" element={<AdminCardManager />} /> {/* Add this route */}
            <Route path="/casino/lottery" element={<Lottery />} /> {/* Add this route */}
            <Route path="/casino/raffle" element={<Raffle />} /> {/* Add this route */}
            <Route path="/casino/coinflip" element={<Coinflip />} /> {/* Add this route */}
            <Route path="/casino/hilow" element={<HiLow />} /> {/* Add this route */}
          </Routes>
        </div>
        <div
          style={{
            width: '100%',
            background: 'rgba(17, 17, 17, 0.7)',
            border: '2px solid transparent',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60px',
            color: 'white',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fontSize: 16,
          }}
        >
          © Hyena - 2023 | <a> Powered by Ethercode</a>
        </div>
      </div>
    </Router>
  );
};

export default App;