/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { Container, Typography, Button, Grid, createStyles, makeStyles, LinearProgress, Paper, Box, Collapse, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Switch, Tooltip, Select, FormControl, MenuItem, withStyles, InputBase, Modal, CircularProgress, Color, FormControlLabel, Radio, FormLabel, RadioGroup } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Skeleton } from "@mui/material";
import { EtherContext } from "../../ethers/EtherContext";
import { EtherContextRepository } from "../../ethers/EtherContextRepository";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { AvatarGroup, CardMedia, Divider, InputLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import EtherHelper from "../../ethers/EtherHelper";
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import GetAppIcon from '@material-ui/icons/GetApp'; // Import the GetAppIcon
import React from "react";
import BadgeIcon from '@mui/icons-material/Badge';
import PaidIcon from '@mui/icons-material/Paid';
import GradeIcon from '@mui/icons-material/Grade';
import { Badge, Chip, Slider } from "@mui/material";
import { styled } from '@mui/material/styles';
import { BadgeProps } from "@chakra-ui/react";
import AddTaskIcon from '@mui/icons-material/AddTask';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { ILevel } from "../../entities/ILevel";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { BigNumber } from "ethers";
import { getLevelHH } from "./lvl_helper/lvl_helper";
import { SplitButton } from "./comp/SplitButton";
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import SyncIcon from '@mui/icons-material/Sync';
import { Rotate90DegreesCcw } from "@material-ui/icons";
import LinearBuffer from "./comp/LinearBuffer";
import { ModalBooster, selectedBooster } from "./comp/ModalBooster";
import { truncate } from "fs";
import { IEtherContext } from "../../ethers/IEtherContext";
import { IPFSolver } from "./comp/IPFSolver";
import { Pagination } from "@material-ui/lab";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) =>
    createStyles({
        rootLoader: {
            flexGrow: 1,
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            maxWidth: '100%',
            height: '100%',
            backgroundImage: 'url("bghome.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
        },
        overlay: {
            position: 'absolute',
            height: '100%',
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: 0,
        },

        logoSpin: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            vertficalAlign: 'center',
            position: 'relative',
            top: '25%',
            width: '100%',
            flexDirection: 'column'
        },
        '@keyframes spin': {
            '0%': {
                transform: 'rotate(0deg)', // Initial rotation angle
            },
            '100%': {
                transform: 'rotate(360deg)', // Final rotation angle after 360 degrees
            },
        },
        logoImage: {
            maxWidth: '38vh',
            maxHeight: '38vh',
            animation: '$spin 2s linear infinite', // Applying the spinning animation
        },
        rainbowText: {
            background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            animation: '$rainbow 5s infinite',
            fontWeight: 'bold', // Aggiunto il grassetto
            fontFamily: 'Roboto',
        },
        '@keyframes rainbow': {
            '0%': { color: theme.palette.secondary.main },
            '25%': { color: theme.palette.error.main },
            '50%': { color: theme.palette.primary.main },
            '75%': { color: theme.palette.info.main },
            '100%': { color: theme.palette.success.main },
        },
        countdown: {
            fontSize: "3rem",
            marginTop: theme.spacing(2),
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontFamily: 'Rubik Wet Paint',
        },
        progressBar: {
            width: "50%", // Larghezza della barra di avanzamento
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginLeft: "auto",
            marginRight: "auto",
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
            height: '790px',
        },
        paperTitle: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
            height: '210px',
        },
        paperAlert: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.9)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
            position: 'fixed',
            zIndex: 9999,
            width: '100%',
            minHeight: 10,
            height: 'auto',
            maxHeight: 'auto',
            top: 55,
        },
        paperMobile: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
        },
        myPassSection: {
            padding: theme.spacing(2),
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
            height: '790px',
            overflowY: 'auto',
        },
        title: {
            marginBottom: theme.spacing(2),
            fontSize: 24,
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontWeight: 'bold',
            fontFamily: 'Rubik Wet Paint',
        },
        titleCount: {
            marginBottom: theme.spacing(2),
            fontSize: 24,
            color: 'white',
            fontFamily: 'Rubik Wet Paint',
            marginTop: 50
        },
        description: {
            marginBottom: theme.spacing(2),
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
        },
        descriptionValue: {
            color: 'white',
            fontWeight: 'bold',
        },
        button: {
            marginTop: theme.spacing(2),
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            color: 'white',
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: '#FF8E53',
            },
            width: 160
        },
        buttonClaim: {
            marginTop: theme.spacing(0),
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            color: 'white',
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: '#FF8E53',
            },
            width: '100%'
        },
        buttonRefresh: {
            marginTop: theme.spacing(0),
            background: 'linear-gradient(45deg, #0288d1 30%, #FF8E53 90%)',
            color: 'white',
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: '#4fc3f7',
            },
            width: '100%'
        },
        buttonText: {
            marginTop: theme.spacing(2),
            border: '2px solid linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '20px',
            width: 160
        },
        gif: {
            marginTop: 20,
            width: '30%',
            height: '30%',
            border: '2px solid transparent',
            borderRadius: '30%',
            marginBottom: 20,
        },
        gifd: {
            marginTop: 20,
            width: '40%',
            height: '40%',
            border: '2px solid transparent',
            borderRadius: '30%',
            marginBottom: 20,
        },
        boxSupply: {
            display: 'flex',
            justifyContent: 'center'
        },
        catchyTextSupply: {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 32,
            letterSpacing: 0.2,
            lineHeight: 1.2,
        },
        catchyTextSupplyCard: {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 28,
            letterSpacing: 0.2,
            lineHeight: 1.2,
        },
        catchyTextSupplyCardNFT: {
            color: '#f57c00',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 28,
            letterSpacing: 0.2,
            lineHeight: 1.2,
        },
        media: {
            position: 'relative',
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        overlayCard: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        tooltip: {
            padding: theme.spacing(1),
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            borderRadius: 4,
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        imageList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        titlePagination: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        alert: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        soldOutText: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 42,
            fontWeight: 'bold',
            color: 'white',
            zIndex: 1,
        },
        soldOutTextDesktop: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 82,
            color: 'white',
            zIndex: 1,
        },
        success: {
            backgroundColor: 'green',
            color: 'white',
            fontSize: '20px',
            fontWeight: 'bold',
        },
        denied: {
            backgroundColor: 'red',
            color: 'white',
        },
        themeDark: {
            background: 'url("launchpad.jpg")',
            maxWidth: 345,
            border: "2px solit white",
            borderRadius: 50
        },
        themeDarkSt: {
            background: 'url("bghome.jpg")',
            maxWidth: 345,
            border: "2px solit white",
            borderRadius: 50
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
            color: 'white'
        },
        resetContainer: {
            padding: theme.spacing(3),
            color: 'white'
        },
        rotation: {
            animation: '$spin 2s linear infinite', // Applying the spinning animation
        },
        customPaginationItem: {
            "&.Mui-selected": {
                backgroundColor: "green",
                "&.Mui-focusVisible": { background: "orange" }
            }
        },
        ul: {
            "& .MuiPaginationItem-root": {
                color: "#fff"
            }
        }
    })
);

const CustomizedHeader = styled(CardHeader)`
  background: 'launchpad.jpg';

`;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Staking: React.FC = () => {
    const classes = useStyles()
    const { context, saveContext } = useContext(EtherContext) as EtherContextRepository;
    const isMobile = useMediaQuery('(max-width:960px)');
    const [loading, setLoading] = useState(false);
    const [load_data_stake, setLoadDataStake] = useState(false)
    const [loadingStakedCard, setLoadingStakedCard] = useState(false)

    const [disabled, setDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [alertInfo, setAlertInfo] = useState<{ severity: "success" | "error", message: string } | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [showAttributes, setShowAttributes] = useState(false);
    const [levelData, setLevelData] = useState<ILevel>()
    const [isOpenModal, setOpenModal] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [currentNft, setCurrentNftToStake] = useState(0);
    const [opt, setOpt] = useState(0);
    const [isModalBooster, setModalBooster] = useState(false)
    const [lvlBooster, setLevelBooster] = useState(0)
    const [hh, setHH] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageNotStaked, setCurrentPageNotStaked] = useState(1);
    const itemsPerPage = 1
    const [tokenCards, setTokenCards] = useState<JSX.Element[]>([]);
    const [currentTokenCardsStaked, setCurrentTokenCardsStaked] = useState<JSX.Element[]>([]);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    //AVATAR BOOSTER STATE
    const [avatar_selected, setAvatarSelected] = useState<{ nft: number; uri_nft_image: string, uri_nft_name: string }[]>()
    const [isAvatarSelected, setIsAvatarSelected] = useState(false)
    const handleSelectedAvatars = (selectedAvatars: { nft: number; uri_nft_image: string, uri_nft_name: string }[]) => {
        setIsAvatarSelected(true)
        setAvatarSelected(selectedAvatars)
        //that's the callback fn to get Avatars seleted
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getSteps() {
        return ['Join vault', 'Create an ad group',];
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return `Welcome to the Den! I hope you've already approved your HH. Enter the Vault to start the hunt!`;
            case 1:
                return context?.toastTitle;
            default:
                return 'Unknown';
        }
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };


    const handleCloseAlert = () => {
        setAlertInfo(null);
    };

    async function getNFTData(tokenId: number) {
        try {
            const res = await EtherHelper.nftStakingData(context, tokenId) as [BigNumber, string, boolean, number, number, string[], BigNumber[], BigNumber]
            return res
        } catch (e) {
        }
    }


    useEffect(() => {
        console.log("isDisabled()", isDisabled());
        setDisabled(isDisabled());
        saveContext(context);
    }, [context.connected]);


    function isDisabled(): boolean {
        if (!context?.connected ?? true) return true;
        return false;
    }

    const [StakedTokenCards, setStakedTokenCards] = useState<JSX.Element[]>([]);

    const joinStaking = async (tokenId: number, vesting: number, boost_0x: string[], boost_Id: string[]) => {
        try {
            setDisabled(true)
            setLoading(true)
            setLoadingStakedCard(true)
            const ctx = await EtherHelper.joinStaking(context, tokenId, vesting, boost_0x, boost_Id)
            saveContext(ctx);
            setLoading(false)
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })
            } else if (ctx.toastStatus === 'error') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })

            }
            console.log("JoinStaking", ctx)
            setDisabled(false)
            handleStakingCard()
            handleTokenCard()
            setLoadingStakedCard(false)
        } catch (e: any) {
            console.log("Error on staking section: ", e)
            setDisabled(false)
            return { ...context }
        }
    }

    const enterVault = async (tokenId: number) => {
        try {
            setLoading(true)
            const ctx = await EtherHelper.enterVault(context, tokenId)
            saveContext({ ...ctx, reload: true });
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })
            } else if (ctx.toastStatus === 'error') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })

            }
            setLoading(false)
            console.log("enterVault", ctx)
            handleNext()
            handleStakingCard()
            handleTokenCard()
        } catch (e) {
            console.log("Error on staking section: ", e)
            setAlertInfo({ severity: 'error', message: (e as any)?.reason ?? 'Execution Reverted — Something went wrong!' })
            setLoading(false)
            return { ...context }
        }
    }

    const setApproveForAllHustlers = async () => {
        try {
            setLoading(true)

            const isApproved = await EtherHelper.setApproveForAllHustlers(context)
                .then((x) => {
                    if (x === true) {
                        setAlertInfo({ severity: "success", message: "All your HH have been approved for the staking contract!" })
                    } else {
                        setAlertInfo({ severity: "error", message: "Something went wrong!" })
                    }
                })
            console.log("Staking.setApproveForAllHustlers: ", isApproved)
            setLoading(false)

        } catch (e) {
            console.log("Staking.setApproveForAllHustlers: ", e)
            setAlertInfo({ severity: "error", message: "Something went wrong!" })
            setLoading(false)

        }
    }

    const exitVault = async (tokenId: number) => {
        try {
            setLoading(true)
            setLoadingStakedCard(true)
            const ctx = await EtherHelper.exitVault(context, tokenId)
            saveContext({ ...ctx, reload: true });
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })
            } else if (ctx.toastStatus === 'error') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })

            }
            setLoading(false)
            console.log("exitVault", ctx)
            handleStakingCard()
            handleTokenCard()
            handlePageChange(1)
            setLoadingStakedCard(false)

        } catch (e) {
            console.log("Error on staking section: ", e)
            setAlertInfo({ severity: 'error', message: context.toastDescription ?? '' })
            return { ...context }
        }
    }

    const exitStaking = async (tokenId: number, xHynClaimed: string) => {
        try {
            setLoading(true)
            setLoadingStakedCard(true)
            const ctx = await EtherHelper.exitStaking(context, tokenId, xHynClaimed)
            saveContext(ctx);
            setLoading(false)
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })
            } else if (ctx.toastStatus === 'error') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })

            }
            console.log("exitStaking", ctx)
            handleStakingCard()
            handleTokenCard()
            setLoadingStakedCard(false)
        } catch (e) {
            console.log("Error on staking section: ", e)
            setAlertInfo({ severity: 'error', message: context.toastDescription ?? '' })
            return { ...context }
        }
    }

    const upgradeLevel = async (tokenId: number, xhyn_price: number, cro_price: number, level: number, isStaked: boolean) => {
        try {
            console.log("tokenId: ", tokenId)
            const ctx = await EtherHelper.storeStaking(context, tokenId, xhyn_price, cro_price, level, isStaked)
            saveContext(ctx);
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })
            } else if (ctx.toastStatus === 'error') {
                setAlertInfo({ severity: ctx.toastStatus, message: ctx.toastDescription ?? '' })

            }
            handleStakingCard()
            console.log("upgradeLevel", ctx)
        } catch (e) {
            console.log("Error on staking section: ", e)
            setAlertInfo({ severity: 'error', message: context.toastDescription ?? '' })
            return { ...context }
        }
    }

    const checkIsBoostPartner = async (address: string) => {
        try {
            const bool_partner = await EtherHelper.checkIsPartner(context, address)
            console.log("Is_Partner[[1][2]]: ", bool_partner)
            return bool_partner
        } catch (e) {
            console.log("Error on staking section: ", e)
        }
    }

    const getMalus = async (tokenId: number) => {
        try {
            const malus = await EtherHelper.getMalusStaking(context, tokenId)
            return malus
        } catch (e) {
            console.log('Error on Malus: ', e)
        }
    }

    const mBooster = async (level: number, nft: number) => {
        setModalBooster(true)
        setLevelBooster(level)
        setHH(nft)
    }

    const getPendingRewards = async (tokenId: number) => {
        try {
            const rewards = await EtherHelper.getPendingRewardsStaking(context, tokenId)
            return rewards
        } catch (e) {
            console.log('Error on Malus: ', e)
        }
    }

    const getApr = async (vesting: number | undefined) => {
        if (vesting === undefined) return
        try {
            const apr = await EtherHelper.getAprOptionData(context, vesting)
            return apr
        } catch (e) {
            console.log('Error on apr: ', e)
        }
    }


    const getStakeLevelData = async (level: number) => {
        try {
            const lvl_data = await EtherHelper.getStakeLevelData(context, level)
            setLevelData(lvl_data)
            return lvl_data
        } catch (e) {
            console.log('Error on apr: ', e)
        }
    }

    const getVesting = async (vestingOpt: number | undefined) => {
        if (vestingOpt === undefined) return
        try {
            const vesting = await EtherHelper.getStakingOptionData(context, vestingOpt)
            return vesting
        } catch (e) {
            console.log('Error on apr: ', e)
        }
    }

    const openModalStepper = (tokenId: number) => {
        setOpenModal(true)
        setCurrentNftToStake(tokenId)
    }

    useEffect(() => {
        if (!context) return context

        if (context && StakedTokenCards) {
            EtherHelper.userDataStaking(context).then((ctx) => { saveContext(ctx); console.log("Updated userDataStaking: ", ctx) })
        }
    }, [context])

    const fetchStakedTokenCards = async (cPage?: number) => {
        const newTokenCards: JSX.Element[] = [];
        const hyenaTokenIds = context.hustlers_staked ?? [];
        const nft = hyenaTokenIds[(cPage ?? 1) - 1];
        //console.log("hyenaTokenIds: ", hyenaTokenIds, " nft: ", nft, "currentPage: ", cPage, 'index: ', (cPage ?? 1) - 1)
        try {
            //setLoadDataStake(true)
            setIsAvatarSelected(true)
            //setLoadingStakedCard(true) - todo implement this logic
            //CARDS FUNCTIONS — STAKING
            const tokenImage = await EtherHelper.getHustlerTokenURI(context, nft);
            const malus = await getMalus(nft);
            const getDataStaking = await getNFTData(nft) as [BigNumber, string, boolean, number, number, string[], BigNumber[], BigNumber]
            const converted_data = getDataStaking.map((n) => ((n instanceof BigNumber) ? n.toNumber() : n)) as [number, string, boolean, number, number, string[], number[], number];
            const lvl_perc = getLevelHH(context)
            const vesting = await getVesting(opt)
            const vestingOption = await EtherHelper.getStakingOptionData(context, (opt ?? 0))
            const apr_perc_d = await getApr(opt);
            const pending_rew = await getPendingRewards(nft)
            const malus_perc = (malus ?? 0) / 100000
            const pending_rew_wo_malus = malus_perc * (pending_rew ?? 0);
            const stake_level_data = await getStakeLevelData(converted_data[4] + 1) as ILevel
            const boost_0x: string[] = []
            const boost_id: string[] = []
            let alignDataRes: any[] = [];
            let booster: {} = {}

            const refresh = () => {
                try {
                    fetchStakedTokenCards()
                } catch (e) {
                }
            } // todo - implement this logic

            const alignData = () => {
                const boost_0x = converted_data[5];
                const boost_id = converted_data[6];

                if (boost_0x.length !== boost_id.length) {
                    console.error("Errore: gli array boost_0x e boost_id devono avere la stessa lunghezza.");
                    return;
                }

                for (let i = 0; i < boost_0x.length; i++) {
                    const booster = {
                        boost_0x: boost_0x[i],
                        boost_id: boost_id[i],
                        level: converted_data[4], // Aggiungi il livello da converted_data[4] (sempre lo stesso per ogni booster?)
                    };

                    alignDataRes.push(booster);
                }
            };

            const numb = converted_data[3]
            const options = ['2W', '1M', '6M', '12M'];
            const selectedOption = options[numb];

            const real_claim = (Number(pending_rew?.toFixed(3)) - Number(pending_rew_wo_malus.toFixed(3))).toFixed(3)
            //console.log("Malus: ", malus + "Converted Data: ", converted_data, "lvl_perc: ", lvl_perc, "apr: ", (apr_perc_d ?? 0) * 1e18, "vestingOption: ", vestingOption, "booster: ", booster);
            const tokenData = tokenImage;
            const image = await IPFSolver(tokenData.image)
            const handleDownloadClick = () => {
                const imageDownloadLink = document.createElement('a');
                imageDownloadLink.href = "h1.png";
                imageDownloadLink.setAttribute('download', 'nft_image.png');
                document.body.appendChild(imageDownloadLink);
                imageDownloadLink.click();
                document.body.removeChild(imageDownloadLink);
            };
            const card = (
                <Card className={classes.themeDarkSt} key={nft} style={{ marginBottom: 30 }}>
                    <CustomizedHeader
                        className={classes.themeDarkSt}
                        title={
                            <div style={{ gap: 10 }}>
                                <Typography variant="h4" className={classes.catchyTextSupplyCardNFT} color="textSecondary" component="p">
                                    <Chip
                                        style={{ marginLeft: -2 }}
                                        label={context.connected ? 'Staked' : ''}
                                        color={context.connected ? 'warning' : 'warning'}
                                        variant="outlined"
                                    />    Hyena Hustlers {nft}
                                </Typography>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <GradeIcon style={{ color: "white", marginTop: 10 }} /> <Typography style={{ marginTop: 10, marginLeft: 5 }} className={classes.descriptionValue}>{load_data_stake ? <Skeleton variant="text"></Skeleton> : converted_data[4]}</Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: 'row' }}>
                                    <LinearProgress style={{ marginTop: 10, display: "flex", width: '100%', flexDirection: 'row' }} color="primary" variant="determinate" value={lvl_perc.percentage ?? 0} /> <Typography style={{ marginLeft: 10 }} className={classes.descriptionValue}>{lvl_perc.level}</Typography>
                                </div>
                                {lvl_perc.level > converted_data[4] && (
                                    <div>
                                        <Typography style={{ marginTop: 10, fontStyle: 'italic' }} className={classes.descriptionValue}>Upgrade Level to {load_data_stake ? <Skeleton variant="text"></Skeleton> : converted_data[4] + 1}</Typography>
                                    </div>
                                )}
                            </div>
                        }
                    />
                    < CardMedia
                        className={classes.media}
                        image={image}
                        title="NFT Image"
                    >
                    </CardMedia >
                    <CardContent>
                        {converted_data[2] ? (
                            <div>
                                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 10 }}>

                                    <Chip
                                        style={{ marginLeft: 0, fontWeight: 'bold' }}
                                        label={pending_rew ? 'Pending Rewards: ~' + pending_rew?.toFixed(3) : ''}
                                        color={context.connected ? 'info' : 'warning'}
                                        variant="outlined"
                                    />
                                    <Chip
                                        style={{ marginLeft: 0, fontWeight: 'bold' }}
                                        label={pending_rew ? 'Rewards W/O Malus: ~' + (Number(pending_rew?.toFixed(3)) - Number(pending_rew_wo_malus.toFixed(3))).toFixed(3) : ''}
                                        color={pending_rew ? 'info' : 'warning'}
                                        variant="outlined"
                                    />
                                    <Chip
                                        style={{ marginLeft: 0, fontWeight: 'bold' }}
                                        label={apr_perc_d ? 'APR: ~%' + (apr_perc_d * 1e18) : ''}
                                        color={apr_perc_d ? 'info' : 'warning'}
                                        variant="outlined"
                                    />

                                </div>
                                <div>
                                    <Typography style={{ marginTop: 10 }} className={classes.descriptionValue}>MALUS ({selectedOption})</Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: 'row' }}>
                                    <LinearProgress style={{ marginTop: 10, display: "flex", width: '100%', flexDirection: 'row' }} color="secondary" variant="determinate" value={(malus ?? 0) / 1000} /> <Typography style={{ marginLeft: 10 }} className={classes.descriptionValue}>{((malus ?? 0) / 1000).toFixed(1)}%</Typography>
                                </div>
                                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10 }}>
                                    <Chip
                                        style={{ marginLeft: 0, fontWeight: 'bold' }}
                                        label={`${converted_data[6].length} BOOSTER`}
                                        color={converted_data[4] > 0 ? 'success' : 'warning'}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography style={{ marginTop: 0, marginBottom: 20 }} className={classes.descriptionValue}>
                                    VESTING
                                    <Tooltip style={{ marginLeft: 10, marginTop: 10 }} title="Select your vesting time, you can unlock your token anytime you want" arrow>
                                        <InfoIcon />
                                    </Tooltip>
                                </Typography>
                                <div>
                                    <SplitButton onSelect={(selectedValue: number) => setOpt(selectedValue)} />
                                </div>
                                <Typography style={{ marginTop: 10 }} className={classes.descriptionValue}>
                                    BOOSTER
                                    <Tooltip style={{ marginLeft: 10, marginTop: 10 }} title="STAKE WITH BOOSTER: This functions is available from level 1. " arrow>
                                        <InfoIcon />
                                    </Tooltip>
                                </Typography>
                                <div>
                                    {converted_data[4] > 0 && (
                                        <div>
                                            <Button className={classes.buttonClaim} variant="contained" style={{ marginTop: 10 }} onClick={() => mBooster(converted_data[4], nft)}>STAKE W BOOST</Button>
                                        </div>
                                    )}
                                </div>
                                {converted_data[4] === 0 && (
                                    <AvatarGroup max={converted_data[4]}>
                                        <IconButton disabled>
                                            <AddIcon />
                                        </IconButton>
                                    </AvatarGroup>
                                )}
                                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10 }}>
                                    <Chip
                                        style={{ marginLeft: 0, fontWeight: 'bold' }}
                                        label={(context.balance ?? 0) >= stake_level_data.cro_price ? `${stake_level_data.cro_price}` + ' CRO' : ''}
                                        color={(context.xhynAmount ?? 0) >= stake_level_data.xhyn_price ? 'success' : 'warning'}
                                        variant="outlined"
                                    />
                                    <Chip
                                        style={{ marginLeft: 0, fontWeight: 'bold' }}
                                        label={(context.balance ?? 0) >= stake_level_data.cro_price ? `${stake_level_data.xhyn_price}` + ' xHYN' : ''}
                                        color={(context.xhynAmount ?? 0) >= stake_level_data.xhyn_price ? 'success' : 'warning'}
                                        variant="outlined"
                                    />
                                </div>
                                <Button className={classes.buttonClaim} style={{ marginTop: 10 }} disabled={(context.balance ?? 0) >= stake_level_data.cro_price && (context.xhynAmount ?? 0) >= stake_level_data.xhyn_price ? false : true} onClick={() => upgradeLevel(nft, stake_level_data.xhyn_price, stake_level_data.cro_price, converted_data[4], converted_data[2])}>UPGRADE LVL</Button>
                                <Button className={classes.buttonClaim} style={{ marginTop: 10 }} onClick={() => exitVault(nft)}>EXIT VAULT</Button>
                            </div>
                        )}
                    </CardContent >
                    <CardActions disableSpacing
                        className={classes.themeDarkSt}
                    >
                        <IconButton aria-label="download" onClick={handleDownloadClick}>
                            <GetAppIcon color="primary" />
                        </IconButton>
                        {converted_data[2] ? (
                            <Button variant="contained" className={classes.buttonClaim} onClick={() => exitStaking(nft, real_claim.toString())} disabled={loading ? disabled : false}>
                                CLAIM & EXIT
                            </Button>
                        ) : (
                            <Button variant="contained" className={classes.buttonClaim} onClick={() => joinStaking(nft, opt, boost_0x ?? [], boost_id ?? [])} disabled={loading ? disabled : false}>
                                STAKE
                            </Button>
                        )}
                        <IconButton aria-label="download" onClick={refresh}>
                            {loading ? (
                                <SyncIcon className={classes.rotation} color="primary" />
                            ) : (
                                <SyncIcon color="primary" />
                            )}
                        </IconButton>
                    </CardActions>
                </Card >
            );
            newTokenCards.push(card);

            setStakedTokenCards(newTokenCards);
            setCurrentTokenCardsStaked(newTokenCards);
        } catch (error) {
            console.error('Error in fetchStakedTokenCards:', error);
        } finally {
            setLoadDataStake(false);
            setIsAvatarSelected(false);
        }
    }


    const handlePageChange = (newPage: number) => {
        try {
            setLoadDataStake(true);
            setCurrentPage(newPage);
            fetchStakedTokenCards(newPage);

        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        console.log("isDisabled()", isDisabled());
        setDisabled(isDisabled());
        saveContext(context);
    }, [context.connected]);

    const handlePageChangeNotStaked = (newPage: number) => {  // Modificato il tipo di newPage
        try {
            setCurrentPageNotStaked(newPage);
            fetchTokenCards(newPage);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (!context) return
        handlePageChangeNotStaked(1)
        handleTokenCard()
        fetchTokenCards(1);
        handlePageChange(1)
        fetchStakedTokenCards(1)
    }, [context.connected]);

    const handleTokenCard = async () => {
        const ctx = await EtherHelper.queryHustlersInfo(context)
        saveContext({ ...ctx })
        fetchTokenCards()
    }

    const handleStakingCard = async () => {
        const ctx = await EtherHelper.queryHustlersInfo(context)
        saveContext({ ...ctx })
        fetchStakedTokenCards(currentPage)
    }

    const fetchTokenCards = async (cPage?: number) => {
        const newTokenCards: JSX.Element[] = [];
        const hyenaTokenIds = context.hustlers_tokenId ?? []
        //NOT STAKED
        const nft = hyenaTokenIds[(cPage ?? 1) - 1];
        console.log(nft)
        try {
            //NOT STAKED
            const tokenImage = await EtherHelper.getHustlerTokenURI(context, nft);
            console.log(tokenImage)
            const tokenData = tokenImage;
            const image = await IPFSolver(tokenData.image)
            const handleDownloadClick = () => {
                const imageDownloadLink = document.createElement('a');
                imageDownloadLink.href = "h1.png";
                imageDownloadLink.setAttribute('download', 'nft_image.png'); // Set the desired file name
                document.body.appendChild(imageDownloadLink);
                imageDownloadLink.click();
                document.body.removeChild(imageDownloadLink);
            };
            const card = (
                <Card className={classes.themeDark} key={nft} style={{ marginTop: 10, marginBottom: 30 }}>
                    <CustomizedHeader
                        className={classes.themeDark}
                        title={
                            <div style={{ gap: 10 }}>
                                <Typography variant="h4" className={classes.catchyTextSupplyCardNFT} color="textSecondary" component="p">
                                    <Chip
                                        style={{ marginLeft: -2 }}
                                        label={context.connected ? 'In Wallet' : ''}
                                        color={context.connected ? 'info' : 'warning'}
                                        variant="outlined"
                                    />    {tokenData.name}
                                </Typography>
                            </div>
                        }
                    />
                    <CardMedia
                        className={classes.media}
                        image={image}
                        title="NFT Image"
                    >
                    </CardMedia>
                    <CardContent>
                        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10 }}>
                        </div>
                    </CardContent>
                    <CardActions disableSpacing
                        className={classes.themeDarkSt}
                    >
                        <div style={{ alignItems: 'center', width: '100%' }}>
                            <Button variant="contained" onClick={() => openModalStepper(tokenData.edition)} style={{ maxWidth: 250 }} className={classes.buttonRefresh}>
                                Stake
                            </Button>
                        </div>
                    </CardActions>
                </Card >
            );
            newTokenCards.push(card)
        } catch (error) {
            console.error('Error fetching token data:', error);
        }

        setTokenCards(newTokenCards);
    };

    const resetModalEnter = () => {
        setOpenModal(false)
        setActiveStep(0)
    }

    return (
        <div className={classes.rootLoader}>
            <div className={classes.overlay}></div>
            {isModalBooster ? <ModalBooster level={lvlBooster} opt={opt} hh_id={hh} openModal={isModalBooster} onClose={() => setModalBooster(false)} handleSelectedAvatars={handleSelectedAvatars} /> : <></>}
            {isOpenModal && (
                <Modal
                    open={isOpenModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box style={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 430,
                        backgroundColor: '#f57c00',
                        border: '#f57c00',
                        padding: 4,
                        borderRadius: 5,
                    }}>
                        <Stepper style={{
                            backgroundColor: 'black',
                            border: '2px solid #000',
                        }} activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    <StepContent>
                                        <Typography style={{ color: 'white' }}>{getStepContent(index)}</Typography>
                                        <div className={classes.actionsContainer}>
                                            <div>
                                                {activeStep === 0 && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => enterVault(currentNft)}
                                                        className={classes.button}
                                                        style={{ marginLeft: 5 }}
                                                    >
                                                        {loading ? <CircularProgress style={{ color: 'white' }} /> : 'ENTER VAULT'}
                                                    </Button>
                                                )}
                                                {activeStep === 1 && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={resetModalEnter}
                                                        className={classes.button}
                                                        style={{ marginLeft: 5 }}
                                                    >
                                                        {activeStep === steps.length - 1 ? 'DONE' : 'Next'}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Modal>
            )}


            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                        <Collapse in={alertInfo !== null}>

                            <Paper elevation={3} className={classes.paperAlert}>
                                <Alert
                                    variant="outlined"
                                    severity={alertInfo?.severity || "error"}
                                    onClose={handleCloseAlert}
                                >
                                    <AlertTitle>{alertInfo?.severity === "error" ? "Error" : "Success"}</AlertTitle>
                                    {alertInfo?.message}
                                </Alert>
                            </Paper>
                        </Collapse>
                        <Paper elevation={3} className={classes.paperTitle}>

                            <Typography variant="body1" className={classes.description}>
                                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                                    <Chip
                                        icon={<BadgeIcon />}
                                        label={context.connected ? 'Connected' : 'Not Connected'}
                                        color={context.connected ? 'success' : 'warning'}
                                        variant="outlined"
                                    />
                                </div>
                            </Typography>
                            <div>
                                <Button style={{ marginTop: 10 }} onClick={() => setApproveForAllHustlers()} color="secondary" variant='outlined'>{"APPROVE ALL HH"}</Button>
                            </div>
                            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', padding: 5, gap: 10, justifyContent: 'center' }}>

                                <Chip
                                    style={{ marginTop: 10 }}
                                    icon={<AddTaskIcon />}
                                    className={classes.success}
                                    label={`Staked ${context.hustlers_staked?.length}`}
                                    color={'success'}
                                />
                                <Chip
                                    style={{ marginTop: 10 }}
                                    icon={<AdsClickIcon />}
                                    className={classes.success}
                                    label={`HYPA ${context.hyenaTokenIds?.length}`}
                                    color={'success'}
                                />
                                <Chip
                                    style={{ marginTop: 10 }}
                                    icon={<PaidIcon />}
                                    className={classes.success}
                                    label={`${(context.xhynAmount ?? 0).toFixed(3)} xHYN`}
                                    color={'info'}
                                />
                            </div>
                            <Box style={{ marginTop: 10 }} className={classes.boxSupply}>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} justifyContent="center" style={{ alignItems: 'center' }}>
                        <Paper elevation={3} className={classes.myPassSection}>
                            <Divider style={{ border: '1px solid #0288d1', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }} light={true}>
                                <Chip style={{ marginTop: -25 }} color={'info'} label="IN MY WALLET" />
                            </Divider>
                            <div style={{ borderRadius: '10%', marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                <Button className={classes.buttonRefresh} onClick={() => handleTokenCard()}><SyncIcon /></Button>
                                <div style={{ marginTop: 20 }}>
                                    <Pagination
                                        size="large"
                                        count={Math.ceil((context.hustlers_tokenId?.length ?? 0) / itemsPerPage)}
                                        page={currentPageNotStaked}
                                        onChange={(event, newPage) => handlePageChangeNotStaked(newPage)}
                                        variant="outlined"
                                        color="primary"
                                        classes={{ ul: classes.ul }} />
                                </div>
                                {tokenCards}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} justifyContent="center" style={{ alignItems: 'center' }}>
                        <Paper elevation={3} className={classes.myPassSection}>
                            <Divider style={{ border: '1px solid #f57c00', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }} light={true}>
                                <Chip style={{ marginTop: -25 }} color={'warning'} label="STAKED" />
                            </Divider>
                            {(context.hustlers_staked?.length ?? 0) >= itemsPerPage && (
                                <div style={{ borderRadius: '10%', marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <Button className={classes.buttonClaim} onClick={() => handleStakingCard()}><SyncIcon /></Button>
                                    <div style={{ marginTop: 20 }}>
                                        <Pagination
                                            size="large"
                                            variant="outlined"
                                            color="secondary"
                                            classes={{ ul: classes.ul }}
                                            count={Math.ceil((context.hustlers_staked?.length ?? 0) / itemsPerPage)}
                                            page={currentPage}
                                            onChange={(event, newPage) => handlePageChange(newPage)}
                                            defaultPage={1}
                                        />
                                    </div>
                                </div>
                            )}
                            {load_data_stake ? (
                                <div style={{ marginTop: 20 }}>

                                    <LinearBuffer />
                                </div>
                            ) : (

                                <div style={{ borderRadius: '10%', marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ marginTop: 10 }}>
                                        {currentTokenCardsStaked}
                                    </div>
                                </div>

                            )}

                        </Paper>
                    </Grid>
                    <Grid />
                </Grid>
            </div>
            <div style={{ height: 50 }}></div>
        </div >
    );
};

export default Staking