import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Carousel from 'react-material-ui-carousel';
import { IconButton, useMediaQuery } from "@material-ui/core";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import TokenIcon from '@mui/icons-material/Token';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import DiscordIcon from '@mui/icons-material/Forum';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        flexGrow: 1,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minWidth: '100%',
        height: '100%',
        backgroundImage: 'url("bghome.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: -1,
        },
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
        zIndex: 0,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: 'rgba(17, 17, 17, 0.7)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    paper2: {
        padding: '6px 16px',
    },
    paper3: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: 'rgba(17, 17, 17, 0.3)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        height: 'auto',
        maxHeight: 80,
        borderRadius: 100,
        marginBottom: 50
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    catchyText: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontWeight: 'bold',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 36,
        letterSpacing: 2,
        lineHeight: 1.2,
    },
    catchyTextTimeline: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontWeight: 'bold',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 18,
        letterSpacing: 0.2,
        lineHeight: 1.2,
    },
    featureText: {
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 18,
    },
    featureTextTitle: {
        color: 'white',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontWeight: 'bold',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 20,
    },
    carouselImage: {
        width: '20%',
        height: '20%',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        borderRadius: '20px',
        marginBottom: theme.spacing(2),
        display: 'block',
        margin: '0 auto',
    },
    carouselImageMobile: {
        width: '80%',
        height: '80%',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        borderRadius: '20px',
        marginBottom: theme.spacing(2),
        display: 'block',
        margin: '0 auto',
    },
    topicsContainer: {
        marginTop: 50,
        marginBottom: 50,
    },
    topic: {
        marginBottom: theme.spacing(2),
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        fontSize: 20,
    },
    additionalSection: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(3),
        background: 'rgba(17, 17, 17, 0.3)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        alignItems: 'center',
        textAlign: 'center'
    },
    featureTextTitlePartner: {
        color: 'white',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 20,
    },
    gif: {
        marginTop: 20,
        width: '30%',
        height: '30%',
        border: '2px solid transparent',
        borderRadius: '30%',
        marginBottom: 20,
    },
    paperMobileText: {
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16,
    },
    paperMobile: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: 'rgba(17, 17, 17, 0.7)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
        margin: '20px auto',
    },
}));

const Home = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:960px)');

    return (
        <div className={classes.root}>
            <div className={classes.overlay}></div>
            <Container maxWidth="lg" style={{ marginTop: 50, gap: 20, padding: 20 }}>
                <Paper className={classes.paper3} style={{ marginBottom: 100 }}>
                    <Typography variant="h1" className={classes.catchyText} align="center">WELCOME TO THE HYENA SAVANNAH</Typography>
                </Paper>
                <Paper elevation={3} className={isMobile ? classes.paperMobile : classes.paper}>
                    <img src="preview.gif" alt="GIF" className={isMobile ? classes.carouselImageMobile : classes.carouselImage} />
                </Paper>
                <Grid container spacing={3} style={{ marginTop: 100 }}>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" className={classes.featureTextTitle}>
                                Empowering Cronos: The Hyena Mission Unveiled
                            </Typography>
                            <Typography className={classes.featureText}>
                                We Hyenas have a mission. A Mission to make Cronos a better place to be! To bring back the promise of solidity in projects.
                                Our objective is to demonstrate that Cronos is not only a great chain to be on, but also a home for amazing and profitable projects. To show what a strong team of dedicated DeFi enthusiats can achieve working together with the community always in mind
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" className={classes.featureTextTitle}>
                                Crafting Excellence: The Hyena Approach
                            </Typography>
                            <Typography className={classes.featureText}>
                                We're bringing together all of the things that make NFT projects great, NFT Staking, Effective tokenomics, GameFi, a strong and integrated ecosystem for our community to earn that ROI and more.
                                Our intial Launchpad will release 350 Alpha Invitations to the community and those passes will be stocked with some sweet utilities. More on those later!
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Paper className={classes.paper3} style={{ marginTop: 50 }}>
                    <Typography variant="h1" className={classes.catchyText} align="center">ROADMAP</Typography>
                </Paper>
                <div className={classes.topicsContainer}>
                    <Timeline align="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" style={{ color: "whitesmoke" }}>
                                    Aug 20 - 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <ConfirmationNumberIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1" className={classes.catchyTextTimeline}>
                                        Launchpad Alpha Invitation
                                    </Typography>
                                    <Typography style={{ color: 'white' }}>We'll provide FREE Mint and Airdrops</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" style={{ color: "whitesmoke" }}>
                                    Aug - 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <LaptopMacIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1" className={classes.catchyTextTimeline}>
                                        Hustlers Casinò
                                    </Typography>
                                    <Typography style={{ color: 'white' }}>Take a breath and get ready for our immersive Casinò</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" style={{ color: "whitesmoke" }}>
                                    Sept - 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary" variant="outlined">
                                    <HotelIcon />
                                </TimelineDot>
                                <TimelineConnector className={classes.secondaryTail} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1" className={classes.catchyTextTimeline}>
                                        Alpha Game Preview
                                    </Typography>
                                    <Typography style={{ color: 'white' }}>// NO SPOILER //</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" style={{ color: "whitesmoke" }}>
                                    Oct - 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <RocketLaunchIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1" className={classes.catchyTextTimeline}>
                                        Hyena Hustlers - Launchpad
                                    </Typography>
                                    <Typography style={{ color: 'white' }}>Explore our exclusive NFT Launchpad</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" style={{ color: "whitesmoke" }}>
                                    Oct - 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary" variant="outlined">
                                    <ConnectWithoutContactIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1" className={classes.catchyTextTimeline}>
                                        Staking, Partnerships, News
                                    </Typography>
                                    <Typography style={{ color: 'white' }}>Stay tuned for exciting updates</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" style={{ color: "whitesmoke" }}>
                                    Oct - 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <TokenIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1" className={classes.catchyTextTimeline}>
                                        $HYN & $XHYN
                                    </Typography>
                                    <Typography style={{ color: 'white' }}>$HYN / $XHYN - Utility & Rewards Tokens</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" style={{ color: "whitesmoke" }}>
                                    Nov - 2023
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <ShoppingCartIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1" className={classes.catchyTextTimeline}>
                                        Hustlers Shop
                                    </Typography>
                                    <Typography style={{ color: 'white' }}>NFTs/Accessories/Merch - Explore our collection of NFTs, accessories, and merchandise</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" style={{ color: "whitesmoke" }}>
                                    ??? ?? - 2024
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <SportsEsportsIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1" className={classes.catchyTextTimeline}>
                                        P2E Game
                                    </Typography>
                                    <Typography style={{ color: 'white' }}>// NO SPOILER //</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </div>

                <div className={classes.additionalSection}>
                    <Typography variant="h5" className={classes.catchyTextTimeline}>
                        TOP 3 PARTNERSHIP:
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <Typography variant="subtitle1" className={classes.featureTextTitlePartner}>
                                Crazzzy Monsters
                                </Typography>
                                <img src="crazym.png" alt="Bob Adventure" style={{ maxWidth: '100%', border: "2px solid white", borderRadius: '50%' }} />
                                <Typography className={classes.featureText}>
                                 Crazzzy Monsters are 10,000 hand-drawn NFTs, each unique in detail, with varying characteristics. Features: Exclusive Ownership, Vibrant Community, Drops, Royalties, Investment, Innovative Utilities.</Typography>
                                <div style={{ marginTop: 3 }}>
                                    <a href="http://crazzzymonsters.com/" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <LanguageIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                    <a href="https://twitter.com/CrazzzyMonsters" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <TwitterIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                    <a href="https://discord.gg/MmBrcna4UM" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <DiscordIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <Typography variant="subtitle1" className={classes.featureTextTitlePartner}>
                                    Steakhouse
                                </Typography>
                                <img src="Steakhouse.png" alt="Bob Adventure" style={{ maxWidth: '100%', border: "2px solid white", borderRadius: '50%' }} />
                                <Typography className={classes.featureText}>
                                Are you looking for a juicy experience? Well look no further than The Cronos Steakhouse, Since 2022 they have been serving up the finest food Cronos has to offer, from Chefz to Staking they have it all!                                </Typography>
                                <div>
                                    <a href="https://cronossteakhouse.com/" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <LanguageIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                    <a href="https://twitter.com/CronoSteakHouse" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <TwitterIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                    <a href="https://discord.gg/VDbdTpC7" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <DiscordIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <Typography variant="subtitle1" className={classes.featureTextTitlePartner}>
                                    Crooks Finance
                                </Typography>
                                <img src="crooks.webp" alt="Crooks Finance" style={{ maxWidth: '100%', border: "2px solid white", borderRadius: '50%' }} />
                                <Typography className={classes.featureText}>
                                    Crooks Finance is a DeFi project launched on the CRONOS Chain which provides its users with an automated high-yield cronos reward protocol which makes passive income easy, secure, and sustainable.                                </Typography>
                                <div>
                                    <a href="https://crooks.finance/" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <LanguageIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                    <a href="https://twitter.com/crooksfinance" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <TwitterIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                    <a href="https://discord.gg/crooksfinance" target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <DiscordIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </a>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
};

export default Home;
