import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Button, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundImage: 'url("savana.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: -1,
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 20,
        marginTop: 50,
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column', // Imposta la colonna per dispositivi mobili
          alignItems: 'center', // Allinea i bottoni al centro verticalmente
        },
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
        zIndex: 0,
    },
    floatingImages: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    floatingImagesMobile: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        pointerEvents: 'none',
    },
    gradient: {
        background: 'rgba(0, 0, 0, .2)',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        border: '2px solid transparent',
        borderRadius: 0,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 43,
        padding: '0 30px',
    },
    floatingImage: {
        marginTop: 150,
        maxWidth: '50%', // Set maximum width for responsiveness
        width: 'auto', // Let the image adjust its width while maintaining aspect ratio
        height: '50%', // Let the image adjust its height while maintaining aspect ratio
        borderRadius: '10%',
        animation: '$floatAnimation 4s infinite',
        transform: 'translateX(-50%)', // Center the image horizontally
    },
    '@keyframes floatAnimation': {
        '0%': { transform: 'translateY(0)' },
        '50%': { transform: 'translateY(-20px)' },
        '100%': { transform: 'translateY(0)' },
    },
    floatingImageMobile: {
        marginTop: 200, // Posizione leggermente più in alto per mobile
        maxWidth: '80%', // Riduci la larghezza per mobile
        width: 'auto',
        height: 'auto',
        borderRadius: '10%',
        animation: '$floatAnimation 4s infinite',
        transform: 'translateX(-50%)',
    },
}));

const Welcome = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:960px)');

    return (
        <div className={classes.root}>
            <div className={classes.overlay}></div>
            <div className={isMobile ? classes.floatingImagesMobile : classes.floatingImages}>
                {isMobile && (
                    <img
                        className={classes.floatingImageMobile}
                        src="logoHyena.png"
                        alt="Hyena Logo"
                    />
                )}
                {!isMobile && (
                    <img
                        className={classes.floatingImage}
                        src="logoHyena.png"
                        alt="Hyena Logo"
                    />
                )}
            </div>
            <Container maxWidth="xl">
                <div className={classes.buttonContainer}>
                    <Button className={classes.gradient} component={Link} to="/launchpad">Mint Alpha Invitation</Button>
                    {isMobile ? (
                            <Button className={classes.gradient} component={Link} to="/home">Home</Button>
                    ) : (
                        <Button component={Link} to="/home" className={classes.gradient}>Home</Button>
                    )}
                    <Button className={classes.gradient}  component={Link} to="/casino" variant="contained">Join the Savana Casino</Button>
                </div>
            </Container>
        </div>
    );
};

export default Welcome;
