/* eslint-disable @typescript-eslint/no-unused-vars */

import { IEtherContext } from "./IEtherContext";
import { IAccount } from "../entities/IAccount";
import { IWalletInfo } from "../entities/IWalletInfo";
import { IToast } from "../entities/IToast";
import { BigNumber, Contract, ethers } from "ethers";
import AddressFactory from "../common/AddressFactory";
import HyenaPassABI from './abi/HyenaPass.json'
import XHYNABI from './abi/XHYN.json'
import HYLottery from './abi/HY_lottery.json'
import HYRaffle from './abi/HY_raffle.json'
import HYCasino from './abi/HY_Casino.json'
import DivitrendRewardsABI from './abi/DivitrendRewards.json'
import HYRolaties from './abi/RoyaltiesDistribution.json'
import Hustlers from './abi/HyenaHustlers_launchpad.json'
import { HyenasPass_launchpad } from "../entities/typechain-type/HyenasPass_launchpad";
import { HyenaHustlers_launchpad } from "../entities/typechain-type/HyenaHustlers_launchpad";
import { HY_lottery } from "../entities/typechain-type/HY_lottery";
import { LotteryStruct } from "../entities/ILottery";
import { HY_raffle } from "../entities/typechain-type/HY_raffle";
import { FreeTicketStruct, RaffleStruct } from "../entities/IRaffle";
import { HY_Casino } from "../entities/typechain-type";
import { DivitrendRewards } from "../entities/typechain-type/DivitrendRewards";
import { NumberInputStepperProps } from "@chakra-ui/react";
import { RoyaltiesDistribution } from "../entities/typechain-type/RoyaltiesDistribution";
import { Context } from "vm";
import { XHYN } from "../entities/typechain-type/XHYN";

// @ts-ignore
const { ethereum } = window;
type NftStakingDataArray = NftStakingData[];

type Listener = (...args: Array<any>) => void;

interface ILevel {
    cro_price: number;
    xhyn_price: number;
    boost_slot: number;
    last_upgrade: number;
}

interface NftStakingData {
    hustlerId: BigNumber;
    owner: string;
    isStaked: boolean;
    stakingOption: BigNumber; // uint8 type
    level: BigNumber; // 'uint8' type
    addrBoosters: string[]; //0x[]
    nftIdsBoosters: BigNumber[]; //[0,0]
    timestamp: BigNumber;
}
export default class EtherHelper {

    public static getChainId(): number { return process.env.REACT_APP_CHAINID ? Number(process.env.REACT_APP_CHAINID) : 25; }

    private static initProvider(): ethers.providers.Web3Provider {
        const chainid = this.getChainId();
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(chainid)) as ethers.providers.Web3Provider;
        if (process.env.REACT_APP_WEB && process.env.REACT_APP_WEB === "1") return provider;
        return ethereum ? new ethers.providers.Web3Provider(ethereum) : provider;
    }

    public static initialAccount(): IAccount {
        return {
            balance: undefined,
            croAmount: 0,
            crooksAmount: 0,
            withdrawable: false,
            connected: false
        } as IAccount;
    }

    public static initialToast(): IToast {
        return {
            toastId: undefined,
            toastDescription: '',
            toastStatus: "success",
            toastTitle: '',
            toastLink: undefined
        } as IToast;
    }

    /* CASINO - CoinFLip */

    public static async CoinFlip(
        context: IEtherContext,
        side: boolean,
        bet: number

    ): Promise<any> {
        console.log(side, bet)
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            const weiValue = ethers.utils.parseEther(bet.toString());
            return hyenaCasino.coinFlip(side, { value: weiValue, gasLimit: 180000 });
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async RefillContract(
        context: IEtherContext,
        amount: number

    ): Promise<any> {
        console.log(amount)
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            const weiValue = ethers.utils.parseEther(amount.toString());
            return hyenaCasino.refillContract({ value: weiValue, gasLimit: 34000 });
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async withdrawCoinHi(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            return hyenaCasino.withdraw({ gasLimit: 34000 });
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async VaultBalance(
        context: IEtherContext,

    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            const balance: BigNumber = await hyenaCasino.get_balance();
            return ethers.utils.formatEther(balance)
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async CoinRecentWinAmount(
        context: IEtherContext,

    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            return hyenaCasino.coinRecentWinAmount();
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async CoinRecentWinner(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            return hyenaCasino.coinRecentWinner();
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async getEnabledBets(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            return hyenaCasino.get_enabledBets();
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async eventOn(context: IEtherContext): Promise<any> {
        const eventName = "CoinFlip__bet";

        return new Promise((resolve, reject) => {
            try {
                const provider = EtherHelper.initProvider();
                const signer = provider.getSigner(context.addressSigner);
                const hyenaCasino = new Contract(
                    AddressFactory.getHyenaCasino(context.chainId ?? 338),
                    HYCasino,
                    signer
                ) as HY_Casino;

                hyenaCasino.on(eventName, (player: any, betId: { toString: () => any; }, won: any, event: any) => {
                    console.log(`Event ${eventName} received:`);
                    console.log(`Player: ${player}`);
                    console.log(`Bet ID: ${betId.toString()}`);
                    console.log(`Won: ${won}`);

                    // Create an event data object
                    const eventData = {
                        eventName,
                        player,
                        betId: betId.toString(),
                        won,
                    };

                    // Resolve the Promise with the event data
                    resolve(eventData);
                });

                hyenaCasino.on("error", (error: any) => {
                    console.error("Error on Coinflip:", error);
                    reject(error); // Reject the Promise if there's an error
                });
            } catch (e) {
                console.error("Error:", e);
                reject(e); // Reject the Promise if there's an error
            }
        });
    }

    //————————————————————————————————————————————————

    /* ROYALTIES */

    public static async RoyalRelease(
        context: IEtherContext,
        token_ids: number[] | [],
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const user = context.addressSigner ?? ''
            const hyenaCasino = new Contract(AddressFactory.getRoyaltiesAddress(context.chainId ?? 338), HYRolaties, signer) as RoyaltiesDistribution
            return hyenaCasino.multiRelease(token_ids, context.addressSigner ?? user);
        } catch (e) {
            console.log("Error on RoyalRelease", JSON.stringify(e))
        }
    }

    public static async MultiPayout(
        context: IEtherContext,
        token_ids: number[] | [],
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const user = context.addressSigner ?? ''
            const hyenaCasino = new Contract(AddressFactory.getRoyaltiesAddress(context.chainId ?? 338), HYRolaties, signer) as RoyaltiesDistribution
            return hyenaCasino.mymultiPAYOUT(token_ids);
        } catch (e) {
            console.log("Error on MultiPayout", JSON.stringify(e))
        }
    }

    public static async RoyaltyBalance(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const user = context.addressSigner ?? ''
            const hyenaCasino = new Contract(AddressFactory.getRoyaltiesAddress(context.chainId ?? 338), HYRolaties, signer) as RoyaltiesDistribution
            return hyenaCasino.getContractBalance();
        } catch (e) {
            console.log("Error on RoyaltyBalance", JSON.stringify(e))
        }
    }

    public static async TotalShares(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(25)) as ethers.providers.Web3Provider;
            const hyenaCasino = new Contract(AddressFactory.getRoyaltiesAddress(context.chainId ?? 338), HYRolaties, provider) as RoyaltiesDistribution
            return hyenaCasino.totalShares();
        } catch (e) {
            console.log("Error on TotalShares", JSON.stringify(e))
        }
    }

    public static async TotalReleased(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(25)) as ethers.providers.Web3Provider;
            const hyenaCasino = new Contract(AddressFactory.getRoyaltiesAddress(context.chainId ?? 338), HYRolaties, provider) as RoyaltiesDistribution
            return hyenaCasino.totalReleased();
        } catch (e) {
            console.log("Error on TotalReleased", JSON.stringify(e))
        }
    }

    //————————————————————————————————————————————————

    /* CASINO HILOW */

    public static async HiLowRecentWinAmount(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            return hyenaCasino.get_hiLoRecentWinAmount();
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async HiLowRecentWinner(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            return hyenaCasino.get_hiLoRecentWinner();
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async HiLowTotalBets(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            return hyenaCasino.get_hiLoTotalBets();
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async getBalanceCasino(
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            return hyenaCasino.get_balance();
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async HiLoCard(
        context: IEtherContext,
        high: boolean,
        amount: number,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaCasino = new Contract(AddressFactory.getHyenaCasino(context.chainId ?? 338), HYCasino, signer) as HY_Casino
            const weiValue = ethers.utils.parseEther(amount.toString());

            return hyenaCasino.hiLo_card(high, { value: weiValue, gasLimit: 180000 });
        } catch (e) {
            console.log("Error on coinflip", JSON.stringify(e))
        }
    }

    public static async eventOnHiLow(context: IEtherContext): Promise<any> {
        const eventName = "HiLo__bet";

        return new Promise((resolve, reject) => {
            try {
                const provider = EtherHelper.initProvider();
                const signer = provider.getSigner(context.addressSigner);
                const hyenaCasino = new Contract(
                    AddressFactory.getHyenaCasino(context.chainId ?? 338),
                    HYCasino,
                    signer
                ) as HY_Casino;

                hyenaCasino.on(eventName, (player: any, betId: { toString: () => any; }, card: any, won: any, event: any) => {
                    console.log(`Event ${eventName} received:`);
                    console.log(`Player: ${player}`);
                    console.log(`Bet ID: ${betId.toString()}`);
                    console.log(`Won: ${won}`);

                    // Create an event data object
                    const eventData = {
                        eventName,
                        player,
                        betId: betId.toString(),
                        card: card.toString(),
                        won,
                    };

                    // Resolve the Promise with the event data
                    resolve(eventData);
                });

                hyenaCasino.on("error", (error: any) => {
                    console.error("Error on Coinflip:", error);
                    reject(error); // Reject the Promise if there's an error
                });
            } catch (e) {
                console.error("Error:", e);
                reject(e); // Reject the Promise if there's an error
            }
        });
    }


    //————————————————————————————————————————————————

    public async getRaffle(poolId: BigNumber, context: IEtherContext): Promise<any> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, provider) as HY_raffle;
        return hyenaRaffle.getActualRaffle(poolId);
    }
    // Function to get a FreeTicketStruct for a player
    public async getFreeTickets(context: IEtherContext, player: string): Promise<any> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
        console.log(signer._address)
        return hyenaRaffle.getFreeTickets(player).then((value) => {
            const [big, big2] = value
            const bigToNum = big.toNumber()
            const big2ToNum = big2.toNumber()
            return {
                big: bigToNum,
                big2: big2ToNum
            };
        });
    }
    // Function to get a PoolStruct by poolId

    public static async buyTicketsRaffle(
        context: IEtherContext,
        _poolId: number,
        _numTickets: number,
        price: BigNumber,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
            return hyenaRaffle.buyTickets(_poolId, _numTickets, { value: price });
        } catch (error: any) {
            console.error('Error buying tickets:', error);
            throw error; // Rilancia l'errore per propagarlo
        }
    }

    public static async cancelRaffle(
        context: IEtherContext,
        _poolId: number,
    ): Promise<ethers.ContractTransaction> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
        return hyenaRaffle.cancelRaffle(_poolId);
    }

    public static async claimFreeTicket(
        context: IEtherContext,

    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
            return hyenaRaffle.claimFreeTicket();
        } catch (error: any) {
            console.error('Error buying tickets:', error);
            if (error === undefined) {
                return 'ERROR: USER REJECTED TX';
            } else {
                throw JSON.stringify(error);
            } // Rilancia l'errore per propagarlo
        }
    }

    public static async getActualPlayersRaffle(
        context: IEtherContext,
        _poolId: number,

    ): Promise<string[]> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, provider) as HY_raffle;
        return hyenaRaffle.getActualPlayers(_poolId);
    }

    public static async setPoolRaffle(poolId: number, isOnlyHolders: boolean, isFreeTickets: boolean, rafflFundsPerc: number, context: IEtherContext): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
            return hyenaRaffle.setPool(poolId, isOnlyHolders, isFreeTickets, rafflFundsPerc);
        } catch (e) {
            console.log(e)
        }
    }

    public static async setEnabledPoolRaffle(pools: number[], context: IEtherContext): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
            return hyenaRaffle.setEnabledPools(pools);
        } catch (e) {
            console.log(e)
        }
    }

    public static async setRafflePaused(poolId: number, paused: boolean, context: IEtherContext): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
            return hyenaRaffle.setRafflePaused(poolId, paused);
        } catch (e) {
            console.log(e)
        }
    }

    public static async withdrawRaffle(context: IEtherContext): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
            return hyenaRaffle.withdraw();
        } catch (e) {
            console.log(e)
        }
    }

    public static async getURI(collectionAddress: string, tokenId: number, context: IEtherContext) {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            let uri_solved

            if (collectionAddress === '0x20F2221084ae06926E8bD042CeEF5dF33486b0b5') return

            const collectionContract = new ethers.Contract(
                collectionAddress,
                ['function tokenURI(uint256 tokenId) view returns (string)'],
                provider
            );

            const uri = await collectionContract.tokenURI(tokenId);
            console.log(uri)

            const uriWO = uri.slice(8)
            console.log(uri)
            if (uriWO.slice(0, 3) === 'cdn') {
                uri_solved = uri
            } else {
                if (uri.slice(0, 5) === 'https') {
                    uri_solved = uri
                    console.log(collectionAddress, uri_solved)
                } else if (uri.slice(0, 7) === 'ipfs://') {
                    uri_solved = 'https://ipfs.io/ipfs/' + uri.slice(7);
                } else {
                    uri_solved = 'https://ipfs.io/ipfs/' + uri.slice(7);
                }
            }

            console.log('URI del token NFT:', uri_solved);

            const response = await fetch(uri_solved);

            if (!response.ok) {
                console.log(`Errore durante il recupero dell'URI risolto: ${response.status}`, collectionAddress);
            }

            const tokenData = await response.json();
            return tokenData;
        } catch (error) {
            console.error('Errore durante il recupero dei dati NFT:', error);
            throw error;
        }
    }

    public static async getRaffleStruct(context: IEtherContext, poolId: number): Promise<any> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, provider) as HY_raffle;

        const value = await hyenaRaffle.getPool(poolId);

        // Restituisci un oggetto con i valori desiderati
        return {
            isOnlyHolders: value[0],
            isFreeTickets: value[1],
            rafflFundsPerc: value[2],
            isRaffleActive: value[3],
            history: value[4].map((raffleStruct) => {
                return {
                    paused: raffleStruct.paused,
                    poolId: raffleStruct.poolId,
                    players: raffleStruct.players,
                    maxTickets: raffleStruct.maxTickets,
                    ticketPrice: raffleStruct.ticketPrice,
                    numFreeTickets: raffleStruct.numFreeTickets,
                    nftContract: raffleStruct.nftContract,
                    name: raffleStruct.name,
                    tokenId: raffleStruct.tokenId,
                    rank: raffleStruct.rank,
                    lastOpenTimestamp: raffleStruct.lastOpenTimestamp,
                    lastWinTimestamp: raffleStruct.lastWinTimestamp,
                    recentWinner: raffleStruct.recentWinner
                };
            })
        };
    }



    public static async getPoolRaffle(poolId: number, context: IEtherContext): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, provider) as HY_raffle;
            const value = await hyenaRaffle.getActualRaffle(poolId);

            // Restituisci un oggetto con i valori desiderati
            return {
                paused: value[0],
                poolId: value[1].toNumber(),
                players: value[2],
                maxTickets: value[3].toNumber(),
                ticketPrice: value[4],
                numFreeTickets: value[5].toNumber(),
                nftContract: value[6],
                name: value[7],
                tokenId: value[8].toNumber(),
                rank: value[9].toNumber(),
                lastOpenTimestamp: value[10].toNumber(),
                lastWinTimestamp: value[11].toNumber(),
                recentWinner: value[12]
            };
        } catch (e: any) {
            console.log(e);
            // In caso di errore, puoi restituire un oggetto vuoto o un valore che indica un errore
            return { error: e.message || 'Errore sconosciuto' };
        }
    }


    public static async openRaffle(
        context: IEtherContext,
        _poolId: number,
        _maxTickets: number,
        _ticketPrice: number,
        _nftContract: string,
        _name: string,
        _tokenId: number,
        _rank: number,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaRaffle = new Contract(
                AddressFactory.getHyenaRaffle(context.chainId ?? 338),
                HYRaffle,
                signer
            ) as HY_raffle;

            // Utilizza ethers.utils.parseUnits per convertire in wei
            const priceETH = ethers.utils.parseUnits(_ticketPrice.toString(), 'ether');

            console.log(_poolId,
                _maxTickets,
                _ticketPrice,
                _nftContract,
                _name,
                _tokenId,
                _rank,)

            return await hyenaRaffle.openRaffle(
                _poolId,
                _maxTickets,
                priceETH,
                _nftContract,
                _name,
                _tokenId,
                _rank,
            );
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    public static async setApprovalForAll(collectionAddress: string, operatorAddress: string, context: IEtherContext) {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);

            const collectionContract = new ethers.Contract(
                collectionAddress, // Indirizzo del contratto della collezione NFT
                ['function setApprovalForAll(address operator, bool approved)'],
                signer
            );

            const transaction = await collectionContract.setApprovalForAll(operatorAddress, true);

            await transaction.wait();

            console.log('transaction: ', transaction.hash);

            return true;
        } catch (error) {
            console.error('Error on Etherhelper.setApprovalForAll', error);
            return false;
        }
    }

    public static async getActualPlayersLengthRaffle(
        context: IEtherContext,
        _poolId: number,

    ): Promise<BigNumber> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, provider) as HY_raffle;
        return hyenaRaffle.getActualPlayersLength(_poolId);
    }

    public static async joinFreeRaffle(
        _poolId: number,
        _numTickets: number,
        context: IEtherContext,
    ): Promise<any> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
            const tx = await hyenaRaffle.joinFreeRaffle(_poolId, _numTickets);
            await tx.wait();
        } catch (e) {
            console.log(e)
        }
    }

    public static async getActualRaffle(
        _poolId: number,
        context: IEtherContext,
    ): Promise<any> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, provider) as HY_raffle;
        return hyenaRaffle.getActualRaffle(_poolId);
    }

    public static async getCasinoVaultRaffle(
        context: IEtherContext,

    ): Promise<string> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
        return hyenaRaffle.getCasinoVault();
    }

    public static async getEnabledPoolsRaffle(
        context: IEtherContext,

    ): Promise<number[]> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, provider) as HY_raffle;
        return hyenaRaffle.getEnabledPools().then((bn) => bn.map((bigNumber) => bigNumber.toNumber()));
    }

    public static async getFreeTickets(
        context: IEtherContext,
        _player: string,

    ): Promise<HY_raffle.FreeTicketStructOutput> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaRaffle = new Contract(AddressFactory.getHyenaRaffle(context.chainId ?? 338), HYRaffle, signer) as HY_raffle
        return hyenaRaffle.getFreeTickets(_player);
    }

    public static async buyTickets(
        poolId: ethers.BigNumberish,
        numTickets: number,
        price: number,
        context: IEtherContext
    ): Promise<void> {
        const price_based_on = price * numTickets;
        console.log("TicketData: ", poolId, price_based_on, numTickets);

        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery;

            // Convert ethers to wei
            const etherValue = ethers.utils.parseEther(price_based_on.toString());

            // Call the buyTickets function with the converted wei value as the value parameter
            const tx = await hyenaLottery.buyTickets(poolId, numTickets, { value: etherValue });
            await tx.wait();
        } catch (error: any) {
            console.error('Error buying tickets:', error);
            throw error; // Rilancia l'errore per propagarlo
        }
    }


    public static async getActualLottery(
        poolId: ethers.BigNumberish,
        context: IEtherContext
    ): Promise<any> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery;

        const lottery: any = await hyenaLottery.getActualLottery(poolId);

        const formattedLottery = {
            paused: lottery.paused,
            poolId: lottery.poolId,
            players: lottery.players,
            lastOpenTimestamp: new Date(lottery.lastOpenTimestamp * 1000), // Convert UNIX timestamp to Date
            lastWinTimestamp: new Date(lottery.lastWinTimestamp * 1000), // Convert UNIX timestamp to Date
            recentWinner: lottery.recentWinner,
        };

        return formattedLottery;
    }


    public static async getActualPlayers(

        poolId: ethers.BigNumberish,
        context: IEtherContext
    ): Promise<string[]> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        return hyenaLottery.getActualPlayers(poolId);
    }

    public static async getActualPlayersLength(

        poolId: ethers.BigNumberish,
        context: IEtherContext
    ): Promise<ethers.BigNumber> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        return hyenaLottery.getActualPlayersLength(poolId);
    }

    public static async getCasinoVault(

        context: IEtherContext
    ): Promise<string> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        return hyenaLottery.getCasinoVault();
    }

    public static async getEnabledPools(

        context: IEtherContext
    ): Promise<ethers.BigNumber[]> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        return hyenaLottery.getEnabledPools();
    }

    public static async getHYPAaddress(

        context: IEtherContext
    ): Promise<string> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        return hyenaLottery.getHYPAaddress();
    }

    public static async getHustlersaddress(

        context: IEtherContext
    ): Promise<string> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        return hyenaLottery.getHustlersaddress();
    }

    public static async setPool(
        poolId: number,
        poolData: {
            onlyHolders?: boolean;
            maxTickets?: number;
            ticketPrice?: number;
            winnerPerc?: number;
            maxTime?: number;
        },
        context: IEtherContext
    ): Promise<void> {
        console.log(poolId, poolData)
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery;
            const tx = await hyenaLottery.setPool(
                poolId,
                poolData.onlyHolders ?? true,
                poolData.maxTickets ?? 0,
                poolData.ticketPrice ?? 0,
                poolData.winnerPerc ?? 0,
                poolData.maxTime ?? 0,
            );
            await tx.wait();
        } catch (error) {
            console.error('Error setting pool:', error);
            throw error;
        }
    }

    public static async getPool(poolId: number, context: IEtherContext): Promise<IEtherContext> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery;

        try {
            const pool = await hyenaLottery.getPool(poolId);

            const thePrice = ethers.utils.formatEther(pool[2])

            context.onlyHolders = pool[0] ?? true;
            context.maxTickets = pool[1].toNumber() ?? 0;
            context.ticketPrice = parseFloat(thePrice);
            context.winnerPerc = pool[3].toNumber() ?? 0;
            context.maxTime = pool[4].toNumber() ?? 0;
            context.isLotteryActive = pool[5] ?? true;
            if (pool[6]) {
                context.history = pool[6].map((lottery) => ({
                    paused: lottery[0],
                    poolId: lottery[1].toNumber(),
                    players: lottery[2],
                    lastOpenTimestamp: new Date(lottery[3].toNumber() * 1000),
                    lastWinTimestamp: new Date(lottery[4].toNumber() * 1000),
                    recentWinner: lottery[5],
                }));
            } else {
                context.history = [];
            }
        } catch (error) {
            console.error('Error getting pool:', error);
        }

        return context;
    }

    public static async Lottery(poolId: number, context: IEtherContext): Promise<IEtherContext> {
        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery;

            const pool = await hyenaLottery.getActualLottery(poolId);

            context.paused = pool[0] ?? false;
            context.poolId = pool[1].toNumber() ?? 0;
            context.players = pool[2] ?? [];
            context.lastOpenTimestamp = new Date(pool[3].toNumber() * 1000);
            context.lastWinTimestamp = new Date(pool[4].toNumber() * 1000);
            context.recentWinner = pool[5] ?? '';

            console.log("EtherHelper.getActualLottery Success:", context);
        } catch (e) {
            console.error("EtherHelper.getActualLottery Error:", e);
        }

        return context;
    }


    public static async getPoolHistory(
        poolId: ethers.BigNumberish,
        context: IEtherContext,
    ): Promise<[]> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery;

        const history = await hyenaLottery.getPoolHistory(poolId);

        const formattedHistory: any = history.map((lottery: any) => ({
            paused: lottery.paused,
            poolId: lottery.poolId,
            players: lottery.players,
            lastOpenTimestamp: new Date(lottery.lastOpenTimestamp * 1000), // Convert UNIX timestamp to Date
            lastWinTimestamp: new Date(lottery.lastWinTimestamp * 1000), // Convert UNIX timestamp to Date
            recentWinner: lottery.recentWinner,
        }));

        return formattedHistory;
    }


    public static async owner(

        context: IEtherContext
    ): Promise<string> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        return hyenaLottery.owner();
    }

    public static async renounceOwnership(

        context: IEtherContext
    ): Promise<void> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        const tx = await hyenaLottery.renounceOwnership();
        await tx.wait();
    }

    public static async resetAndCloseLottery(

        poolId: ethers.BigNumberish,
        context: IEtherContext
    ): Promise<void> {

        try {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)
            const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
            const tx = await hyenaLottery.resetAndCloseLottery(poolId);
            await tx.wait();
        } catch (e) {
            console.log("Error during reset lottery: ", JSON.stringify(e))
        }
    }

    public static async setEnabledPools(

        poolIds: ethers.BigNumberish[],
        context: IEtherContext
    ): Promise<void> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        const tx = await hyenaLottery.setEnabledPools(poolIds);
        await tx.wait();
    }

    public static async setHypaTokenAddress(

        tokenAddress: string,
        context: IEtherContext
    ): Promise<void> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        const tx = await hyenaLottery.set_HYPAaddress(tokenAddress);
        await tx.wait();
    }

    public static async setHustlersPoolAddress(

        poolAddress: string,
        context: IEtherContext
    ): Promise<void> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        const tx = await hyenaLottery.set_Hustlersaddress(poolAddress);
        await tx.wait();
    }

    public static async transferOwnership(

        newOwner: string,
        context: IEtherContext
    ): Promise<void> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        const tx = await hyenaLottery.transferOwnership(newOwner);
        await tx.wait();
    }

    public static async withdraw(

        context: IEtherContext
    ): Promise<void> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaLottery = new Contract(AddressFactory.getHyenaLottery(context.chainId ?? 338), HYLottery, signer) as HY_lottery
        const tx = await hyenaLottery.withdraw();
        await tx.wait();
    }

    public static async connect(context: IEtherContext): Promise<IEtherContext> {
        try {
            console.log("EtherHelper.connect");
            const provider = EtherHelper.initProvider();

            if (!context.chainId) context = await this.getNetwork(provider, context);

            let accounts = await provider.send("eth_requestAccounts", []);
            // return this.querySignerInfo({...context, addressSigner: accounts[0], connected: true});
            return this.queryProviderInfo({ ...context, addressSigner: accounts[0], connected: true }).then(this.querySignerInfo)

        } catch (error) {
            console.log("EtherHelper.connect FAILED: ", JSON.stringify(error))
        }

        return context;
    }

    public static async getNetwork(provider: ethers.providers.Web3Provider, context: IEtherContext): Promise<IEtherContext> {
        const network = await provider.getNetwork();
        const chainId = network.chainId ? BigNumber.from(network.chainId).toNumber() : 25;

        return {
            ...context,
            chainId: chainId,
            chainSymbol: network.ensAddress ? await provider.getCode(network.ensAddress) : "CRO"
        };
    }

    public static async disconnect(context: IEtherContext): Promise<IEtherContext> {
        this.disconnectListeners(); // TODO correct place?
        return this.queryProviderInfo({ loaded: false, reload: true });
    }

    public static async mint(context: IEtherContext, amount: number): Promise<IEtherContext> {
        try {
            if (!context.connected) return context;
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner)

            const hyenaPass = new Contract(AddressFactory.getHyenaAddress(context.chainId ?? 338), HyenaPassABI, signer) as HyenasPass_launchpad

            const tx = await hyenaPass.connect(signer).mint(amount)
            let transactionResult = await tx.wait();
            console.log('EtherHelper.mint Transaction Hash: ', JSON.stringify(transactionResult.transactionHash));
            context = {
                ...context, toastId: `mint_${transactionResult.transactionHash}`, toastStatus: 'success', toastTitle: 'Hyena Mint', toastDescription: `Successfully minted your Hyena Invitations`,
            }
        } catch (e) {
            context = { ...context, toastId: `mintError_${Date.now()}`, toastStatus: 'error', toastTitle: 'Hyena Invitations Mint', toastDescription: `FAILED to mint: ${(e as Error)?.message.split(';')[0]}` };
            console.log("EtherProvider.mint Error: ", JSON.stringify(e))
        }
        console.log("mint")
        return await this.querySignerInfo({ ...context }).then(this.queryProviderInfo);
    }

    public static async querySignerInfo(context: IEtherContext): Promise<IEtherContext> {
        if (!context.addressSigner) return context;
        const provider = EtherHelper.initProvider();
        const chainId = EtherHelper.getChainId()

        // const provider = new ethers.providers.Web3Provider(ethereum);

        if (!context.chainId) context = await this.getNetwork(provider, context);

        const signer = provider.getSigner(context.addressSigner);
        const xhyn = new Contract(AddressFactory.getXHYN(context.chainId ?? 338), XHYNABI, signer) as XHYN;
        EtherHelper.userDataStaking(context)


        function toNumberSafe(bn: BigNumber): number {
            try {
                return bn.toNumber();
            } catch (error) {
                console.error('Error converting BigNumber to number:', error);
                return 0; // todo — fix to find sol
            }
        }

        const croBalancePromise = signer
            .getBalance()
            .then((result: BigNumber) => context.balance = Number(ethers.utils.formatEther(result)))
            .catch((error: any) => console.log("EtherHelper.queryProviderInfo.croBalance: ", JSON.stringify(error)));
        // context.nfts = [];
        const userAddress: string = context.addressSigner || '';

        const xHYNBalancePromise = xhyn
            .balanceOf(userAddress)
            .then((result: BigNumber) => context.xhynAmount = Number(ethers.utils.formatEther(result)))
            .catch((error: any) => console.log("EtherHelper.queryProviderInfo.xhynBalance: ", JSON.stringify(error)));
        // context.nfts = [];

        await Promise.all([croBalancePromise, xHYNBalancePromise]);

        return context;
    }

    /**  STAKING  **/

    public static async enterVault(context: IEtherContext, tokenId: number): Promise<IEtherContext> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, signer) as DivitrendRewards;
        console.log(tokenId)
        try {
            const tx = await HyenaStaking.connect(signer).enterVault(tokenId)
            let transactionResult = await tx.wait();
            console.log('EtherHelper.enterVault Transaction Hash: ', JSON.stringify(transactionResult.transactionHash));
            context = {
                ...context, toastId: `enterVault_${transactionResult.transactionHash}`, toastStatus: 'success', toastTitle: 'ENTERED THE VAULT!', toastDescription: `Great news, you've entered the vault!`,
            }

        } catch (e: any) {
            context = { ...context, toastId: `enterVault_${Date.now()}`, toastStatus: 'error', toastTitle: 'NOT ENTERED THE VAULT!', toastDescription: `FAILED TO JOIN: ${e?.message.split('(')[0] || e?.reason.split('(')[0]} ` };
            console.log("EtherProvider.enterVault Error: ", JSON.stringify(e))
        }

        return await this.querySignerInfo({ ...context });
    }

    public static async exitVault(context: IEtherContext, tokenId: number): Promise<IEtherContext> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, signer) as DivitrendRewards;

        try {
            const tx = await HyenaStaking.connect(signer).exitVault(tokenId, { from: context.addressSigner })
            let transactionResult = await tx.wait();
            console.log('EtherHelper.exitVault Transaction Hash: ', JSON.stringify(transactionResult.transactionHash));
            context = {
                ...context, toastId: `exitVault_${transactionResult.transactionHash}`, toastStatus: 'success', toastTitle: 'EXIT', toastDescription: `You have successfully exited the vault`,
            }
        } catch (e: any) {
            context = { ...context, toastId: `mintError_${Date.now()}`, toastStatus: 'error', toastTitle: 'ERROR!', toastDescription: `FAILED TO EXIT: ${e?.message.split('(')[0] || e?.reason.split('(')[0]} ` };
            console.log("EtherProvider.exitVault Error: ", JSON.stringify(e))
        }

        return await this.querySignerInfo({ ...context });
    }

    public static async exitStaking(context: IEtherContext, tokenId: number, xHynClaimed?: string): Promise<IEtherContext> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, signer) as DivitrendRewards;

        try {
            const tx = await HyenaStaking.connect(signer).exitStaking(tokenId)
            let transactionResult = await tx.wait();
            console.log('EtherHelper.exitVault Transaction Hash: ', JSON.stringify(transactionResult.transactionHash));
            context = {
                ...context, toastId: `exitVault_${transactionResult.transactionHash}`, toastStatus: 'success', toastTitle: 'CLAIMED xHYN & EXITED!', toastDescription: `Great news, you've claimed your rewards! xHYN Claimed: ${xHynClaimed}`,
            }
        } catch (e: any) {
            context = { ...context, toastId: `mintError_${Date.now()}`, toastStatus: 'error', toastTitle: 'ERROR!', toastDescription: `FAILED TO EXIT: ${e?.message.split('(')[0] || e?.reason.split('(')[0]} ` };
            console.log("EtherProvider.exitVault Error: ", JSON.stringify(e))
        }

        return await this.querySignerInfo({ ...context });
    }

    public static async getStakingOptionData(context: IEtherContext, vesting: number): Promise<number> {
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(context.chainId ?? 338))
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, provider) as DivitrendRewards;

        try {
            const vesting_data = await HyenaStaking.getStakingOptionData(vesting) // [0] vesting; [1] APR; [2] last option changed (timestamp)
            return vesting_data[0]
        } catch (e) {
            console.log("Error EtherHelper.getStakingOptionData: ", JSON.stringify(e))
        }

        return 0
    }

    public static async getStakeLevelData(context: IEtherContext, level: number): Promise<ILevel> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, signer) as DivitrendRewards;
        const defData: ILevel = {
            cro_price: 0,
            xhyn_price: 0,
            boost_slot: 0,
            last_upgrade: 0,
        }

        try {
            const staking_level_data = await HyenaStaking.getStakeLevelData(level)
            const data: string[] = staking_level_data.map((n) => ethers.utils.formatEther(n.toString()));
            const dataLevel: ILevel = {
                cro_price: Number(data[0]),
                xhyn_price: Number(data[1]),
                boost_slot: Number(data[3]),
                last_upgrade: Number(data[4]),
            }
            console.log("EtherHelper.getStakeLevelData.dataLevel: ", dataLevel)
            return dataLevel
        } catch (e) {
            console.log("Error EtherHelper.getStakeLevelData: ", JSON.stringify(e))
            return defData
        }
    }

    public static async nftStakingData(context: IEtherContext, tokenId: number): Promise<any> {
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(25))
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, provider) as DivitrendRewards;

        try {
            const data_nft = await HyenaStaking.getNftStakingData(tokenId);
            //console.log("EtherHelper.nftStakingData.data_nft: ", data_nft);
            return data_nft;
        } catch (e) {
            console.log("Error EtherHelper.nftStakingData.data_nft: ", e)
            return
        }
    }

    public static async setApproveForAllHustlers(context: IEtherContext): Promise<boolean> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const HyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;

        try {

            const tx = await HyenaHustlers.setApprovalForAll(AddressFactory.getStaking(context.chainId ?? 338), true);
            tx.wait()
            console.log("EtherHelper.nftStakingData.setApproveForAll: ", true);
            return true;
        } catch (e) {
            console.log("Error EtherHelper.nftStakingData.data_nft: ", e)
            return false
        }
    }

    public static async getAprOptionData(context: IEtherContext, vesting: number): Promise<number> {
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(context.chainId ?? 338))
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, provider) as DivitrendRewards;

        try {
            const apr_data = await HyenaStaking.getStakingOptionData(vesting) // [0] vesting; [1] APR; [2] last option changed (timestamp)
            const aprValue = ethers.BigNumber.from(apr_data[1]);
            const aptString = ethers.utils.formatUnits(aprValue, 'ether');
            return Number(aptString)
        } catch (e) {
            console.log("Error EtherHelper.getAprOptionData: ", JSON.stringify(e))
        }
        return 0
    }

    public static async getPendingRewardsStaking(context: IEtherContext, tokenId: number): Promise<number> {
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(context.chainId ?? 338))
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, provider) as DivitrendRewards;

        try {
            const rew_data = await HyenaStaking.pendingStakingRew(tokenId) // daily rew
            const rewValue = ethers.BigNumber.from(rew_data);
            const rewString = ethers.utils.formatUnits(rewValue, 'ether');
            return Number(rewString)
        } catch (e) {
            console.log("Error EtherHelper.getPendingRewardsStaking: ", JSON.stringify(e))
        }
        return 0
    }

    public static async getMalusStaking(context: IEtherContext, tokenId: number): Promise<number> {
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(context.chainId ?? 338))
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, provider) as DivitrendRewards;

        try {
            const malus_data = await HyenaStaking.getActualMalus(tokenId) // daily malus
            return malus_data
        } catch (e) {
            console.log("Error EtherHelper.getMalusStaking: ", JSON.stringify(e))
        }
        return 0
    }

    public static async checkIsPartner(context: IEtherContext, address: string): Promise<boolean[][]> {
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(context.chainId ?? 338))
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, provider) as DivitrendRewards;

        try {
            const p_list1 = await HyenaStaking.getPartnershipList1() // p1 — x1.20
            const check_is_partner1 = p_list1.map((v, i) => {
                if (v === address) {
                    return true
                } else {
                    return false
                }
            })
            const p_list2 = await HyenaStaking.getPartnershipList2() // p2 — x1.15
            const check_is_partner2 = p_list2.map((v, i) => {
                if (v === address) {
                    return true
                } else {
                    return false
                }
            })

            return [check_is_partner1, check_is_partner2]

        } catch (e) {
            console.log("Error EtherHelper.checkIsPartner: ", JSON.stringify(e))
        }
        return [[false], [false]]
    }

    public static async userDataStaking(context: IEtherContext): Promise<IEtherContext> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, signer) as DivitrendRewards;

        try {
            const userDataTokenIds = await HyenaStaking.getUserIds(context.addressSigner ?? '').then((n) => n.map(i => i.toNumber()))
            context.hustlers_staked = userDataTokenIds
        } catch (e) {
            console.log("Error EtherHelper.userDataStaking: ", e)
        }
        return context
    }

    public static async approveXHYN(context: IEtherContext, amount: number): Promise<IEtherContext> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const xhyn = new Contract(AddressFactory.getXHYN(context.chainId ?? 338), XHYNABI, signer) as XHYN;
        try {
            const approve = await xhyn.approve(AddressFactory.getStaking(context.chainId ?? 338) ?? '', ethers.utils.parseEther(amount.toString()))
            context = { ...context, toastId: 'approveXHYN' + approve.hash, toastStatus: 'success', toastTitle: 'Approved! ', toastDescription: "Congratulations! approved successfully!" }
        } catch (e) {
            context = { ...context, toastId: 'approveXHYN', toastStatus: 'error', toastTitle: 'Error while approving xHYN!', toastDescription: "Something went wrong!" }
        }

        return await this.queryProviderInfo({ ...context })
    }


    public static async storeStaking(
        context: IEtherContext,
        tokenId: number,
        xhyn_price: number,
        cro_price: number,
        level: number,
        isStaked: boolean
    ): Promise<IEtherContext> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const HyenaStaking = new Contract(
            AddressFactory.getStaking(context.chainId ?? 338),
            DivitrendRewardsABI,
            signer
        ) as DivitrendRewards;

        // Ottenere l'NFT data
        const croBalance = context.balance ?? 0;
        const xhynBalance = context.xhynAmount ?? 0;

        // Approva l'importo XHYN se necessario
        await this.approveXHYN(context, xhyn_price);

        const v_eth = cro_price * 1e18;
        console.log(v_eth);

        // Converti da CRO a Wei
        const weiAmount = ethers.utils.parseEther(cro_price.toString());
        console.log('Quantità in Wei:', weiAmount.toString());

        if (!isStaked) {
            if (croBalance >= cro_price && xhynBalance >= xhyn_price) {
                try {
                    // Esegui l'upgrade del livello
                    const upgradeLevel = await HyenaStaking.upgradeLevel(tokenId, { value: weiAmount, gasLimit: 250000 });

                    context = {
                        ...context,
                        toastId: 'storeStaking' + upgradeLevel.hash,
                        toastStatus: 'success',
                        toastTitle: `Upgraded! Level ${level + 1}`,
                        toastDescription: 'Congratulations! Level up successfully achieved!'
                    };
                } catch (e: any) {
                    console.error('Error EtherHelper.storeStaking.upgradeLevel: ', e);

                    // contesto con l'errore dell'upgrade
                    context = {
                        ...context,
                        toastId: 'storeStaking::error',
                        toastStatus: 'error',
                        toastTitle: 'Not upgraded!',
                        toastDescription: e.message
                    };
                }
            } else {
                // contesto con un messaggio di errore per fondi insufficienti
                context = {
                    ...context,
                    toastId: 'storeStaking',
                    toastStatus: 'error',
                    toastTitle: 'Insufficient Funds',
                    toastDescription: 'Insufficient Funds — Check your balance vesting status!'
                };
            }
        } else {
            // contesto con un messaggio di errore se l'HH è già in staking!!
            context = {
                ...context,
                toastId: 'storeStaking',
                toastStatus: 'error',
                toastTitle: 'HH Already Staked',
                toastDescription: 'HH Staked — Check your vesting status!'
            };
        }

        // Restituisci il contesto aggiornato
        return await this.querySignerInfo({ ...context });
    }

    public static async joinStaking(context: IEtherContext, tokenId: number, vesting: number, boost_0x: string[], boost_Id: string[]): Promise<IEtherContext> {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, signer) as DivitrendRewards;
        //const vesting_data = await EtherHelper.getOptionVestingData(context, vesting);

        const uniqueBoost_0x = [...new Set(boost_0x)]; // Rimuove gli elementi duplicati da boost_0x

        console.log("JoinStaking Props", tokenId, vesting, boost_0x, boost_Id)
        try {
            for (const boost_0xUnique of uniqueBoost_0x) {
                await this.setApprovalForAll(boost_0xUnique, AddressFactory.getStaking(context.chainId ?? 25), context);
            }
            const tx = await HyenaStaking.connect(signer).joinStaking(tokenId, vesting, boost_0x, boost_Id)
            let transactionResult = await tx.wait();
            console.log('EtherHelper.joinStaking Transaction Hash: ', JSON.stringify(transactionResult.transactionHash));
            context = {
                ...context, toastId: `joinStaking${transactionResult.transactionHash}`, toastStatus: 'success', toastTitle: 'JOIN THE VAULT!', toastDescription: `Great news, you've entered the staking!`,
            }
        } catch (e) {
            context = { ...context, toastId: `mintError_${Date.now()}`, toastStatus: 'error', toastTitle: 'NOT ENTERED THE VAULT!', toastDescription: `FAILED TO JOIN: ${(e as any)?.code.split(';')[0]}` };
            console.log("EtherProvider.joinStaking Error: ", JSON.stringify(e))
        }

        return await this.querySignerInfo({ ...context });
    }

    /**———————————**/
    public static async hyenaBalanceOf(context: IEtherContext) {
        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const hyenaPass = new Contract(AddressFactory.getHyenaAddress(context.chainId ?? 338), HyenaPassABI, signer) as HyenasPass_launchpad

        if (context.connected) {
            try {
                const balance = await hyenaPass.balanceOf(context.addressSigner ?? '').then((i) => i.toNumber())
                console.log("balance and tokenIds: ", balance)

                const tokenIds = [];
                for (let i = 0; i < balance; i++) {
                    const tokenId = await hyenaPass.tokenOfOwnerByIndex(context.addressSigner ?? '', i);
                    tokenIds.push(tokenId.toNumber());
                }

                console.log("balance and tokenIds: ", balance, tokenIds);
                return tokenIds;
            } catch (e) {
                console.log("Can't fetch balanceOf owner:", e)
            }
        }
    }

    public static async getTokenURI(context: IEtherContext, tokenId: number) {
        if (context.connected) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaPass = new Contract(AddressFactory.getHyenaAddress(context.chainId ?? 338), Hustlers, signer) as HyenasPass_launchpad;

            const URIpath = await hyenaPass.tokenURI(tokenId);
            const response = await fetch(URIpath);
            const tokenData = await response.json();
            return tokenData;
        }
    }

    public static async getTokenURIBooster(context: IEtherContext, add: string, tokenId: number) {
        if (context.connected) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);

            const abi = [
                {
                    "constant": true,
                    "inputs": [
                        {
                            "name": "_tokenId",
                            "type": "uint256"
                        }
                    ],
                    "name": "tokenURI",
                    "outputs": [
                        {
                            "name": "",
                            "type": "string"
                        }
                    ],
                    "payable": false,
                    "stateMutability": "view",
                    "type": "function"
                }
            ]

            const general = new Contract(add, abi, signer) as HyenasPass_launchpad;

            const URIpath = await general.tokenURI(tokenId);
            const uri_solved = URIpath.slice(21)
            const response = await fetch(`https://gateway.ipfs.io/ipfs/${uri_solved}`);
            const tokenData = await response.json();
            return tokenData;
        }
    }

    /*
    ------------------ HUSTLERS —-------------------------------------------------------------------------------------------------------------------
    */

    public static async getHustlerId(context: IEtherContext) {
        if (context.connected) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;
            try {
                if (context.connected) {
                    try {
                        const balance = await hyenaHustlers.balanceOf(context.addressSigner ?? '').then((i) => i.toNumber())
                        console.log("balance and tokenIds: ", balance)

                        const tokenIds = [];
                        for (let i = 0; i < balance; i++) {
                            const tokenId = await hyenaHustlers.tokenOfOwnerByIndex(context.addressSigner ?? '', i);
                            tokenIds.push(tokenId.toNumber());
                        }

                        console.log("balance and tokenIds: ", balance, tokenIds);
                        return tokenIds;
                    } catch (e) {
                        console.log("Can't fetch balanceOf owner:", e)
                    }
                }
            } catch (e) {
                console.log("ERROR.EtherHelper.getHustlerId: ", JSON.stringify(e))
            }
        }
    }

    private static async getHustlerBalanceOf(context: IEtherContext) {
        if (context.connected) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;

            try {
                const response = await hyenaHustlers.balanceOf(context.addressSigner || '');
                const tokenData = response;
                return tokenData;
            } catch (e) {
                console.log("ERROR.EtherHelper.getHustlerBalanceOf: ", JSON.stringify(e))
            }
        }
    }

    private static async getHustlerMintStart(context: IEtherContext) {
        if (context.connected) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;

            try {
                const data = await hyenaHustlers.hypaTimeStart()
                    .then((res) => res.toNumber());
                return data
            } catch (e) {
                console.log("ERROR.EtherHelper.getHustlerMintStart: ", JSON.stringify(e))
            }
        }
    }

    public static async getHustlerTokenURI(context: IEtherContext, tokenId: number) {
        if (context.connected) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;

            const URIpath = await hyenaHustlers.tokenURI(tokenId);
            const response = await fetch(URIpath);
            console.log(response)
            const tokenData = await response.json();
            return tokenData;
        }
    }

    public static isHYPAHustlers(context: IEtherContext) {
        if (context.connected === true) {
            if (context.hyenaTokenIds?.length || 0 >= 1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    public static async isWLHustlers(context: IEtherContext) {
        if (context.connected === true) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;

            try {
                const wl = await hyenaHustlers.isWhiteList(signer._address)
                    .then((result) => result)
                return wl
            } catch (e) {
                console.log("ERROR.EtherHelper.isWLHustlers: ", JSON.stringify(e))
            }
        }
    }

    public static async hustlerSupply() {
        const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(25));

        const hyenaHustlers = new Contract(AddressFactory.getHustlers(25), Hustlers, provider) as HyenaHustlers_launchpad;

        if (provider) {
            const num = await hyenaHustlers.totalSupply()
                .then((result) => result.toNumber())

            return num;
        } else {
            return 0;
        }
    }

    public static async hustlers_mint(context: IEtherContext, amount: number, price: number): Promise<IEtherContext> {
        try {
            if (context.connected === true) {
                const provider = EtherHelper.initProvider();
                const signer = provider.getSigner(context.addressSigner);
                const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;
                const importoInWei = ethers.utils.parseEther(price.toString());

                try {
                    const transactionResult = await hyenaHustlers.mint(amount, { value: importoInWei })
                    const tx = await transactionResult.wait()
                    console.log('EtherHelper.mint Transaction Hash: ', JSON.stringify(tx.transactionHash));
                    if (tx) {
                        context = {
                            ...context, toastId: `mint_${tx.transactionHash}`, toastStatus: 'success', toastTitle: 'Hyena Mint', toastDescription: `Successfully minted ${amount} Hustlers`,
                        }
                    }
                } catch (e: any) {
                    context = { ...context, toastId: `mintError_${Date.now()}`, toastStatus: 'error', toastTitle: e.reason, toastDescription: `FAILED to mint: ${(e as Error)?.message.split('(')[0]}` };
                    console.log("EtherProvider.mint Error: ", JSON.stringify(e))
                }

                await this.queryHustlersInfo({ ...context, connected: true }).then(this.queryHustlersInfo)

                return context
            }
        } catch (e) { console.log(e) }
        return context
    }

    public static async hustlers_mint_wl(context: IEtherContext, amount: number, price: number): Promise<IEtherContext> {
        if (context.connected === true) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;
            const importoInWei = ethers.utils.parseEther(price.toString());

            try {
                const transactionResult = await hyenaHustlers.whiteMint(amount, { value: importoInWei })
                const tx = await transactionResult.wait()
                console.log('EtherHelper.mint Transaction Hash: ', JSON.stringify(tx.transactionHash));
                context = {
                    ...context, toastId: `mint_${tx.transactionHash}`, toastStatus: 'success', toastTitle: 'Hyena Mint', toastDescription: `Successfully minted ${amount} Hustlers`,
                }
            } catch (e: any) {
                context = { ...context, toastId: `mintError_${Date.now()}`, toastStatus: 'error', toastTitle: e.reason, toastDescription: `FAILED to mint: ${(e as Error)?.message.split('(')[0]}` };
                console.log("ERROR.EtherHelper.hustlers_mint: ", JSON.stringify(e))
            }

            await this.queryHustlersInfo({ ...context, connected: true }).then(this.queryHustlersInfo)

            return context

        }
        return context
    }

    public static async hustlers_mint_hypa(context: IEtherContext, amount: number, price: number): Promise<IEtherContext> {
        try {
            if (context.connected === true) {
                const provider = EtherHelper.initProvider();
                const signer = provider.getSigner(context.addressSigner);
                const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad;
                const importoInWei = ethers.utils.parseEther(price.toString());

                try {
                    const transactionResult = await hyenaHustlers.hypaMint(amount, { value: importoInWei });
                    const tx = await transactionResult.wait()
                    console.log('EtherHelper.mint Transaction Hash: ', JSON.stringify(tx.transactionHash));
                    context = {
                        ...context, toastId: `mint_${tx.transactionHash}`, toastStatus: 'success', toastTitle: 'Hyena Mint', toastDescription: `Successfully minted ${amount} Hustlers`,
                    }
                } catch (e: any) {
                    context = { ...context, toastId: `mintError_${Date.now()}`, toastStatus: 'error', toastTitle: e.reason, toastDescription: `FAILED to mint: ${(e as Error)?.message.split('(')[0]}` };
                    console.log("EtherProvider.mint Error: ", JSON.stringify(e))
                }

                await this.queryHustlersInfo({ ...context, connected: true }).then(this.queryHustlersInfo)

                return context
            }
        } catch (e) { console.log(e) }
        return context
    }

    public static async getBalanceOf(context: IEtherContext, address: string) {
        if (context.connected) {
            const provider = EtherHelper.initProvider();
            const signer = provider.getSigner(context.addressSigner);
            const hyenaHustlers = new Contract(address, Hustlers, signer) as HyenaHustlers_launchpad;
            try {
                if (context.connected) {
                    try {
                        const balance = await hyenaHustlers.balanceOf(context.addressSigner ?? '').then((i) => i.toNumber())
                        const tokenIds = [];
                        if (balance !== 0) {
                            for (let i = 0; i < balance; i++) {
                                const tokenId = await hyenaHustlers.tokenOfOwnerByIndex(context.addressSigner ?? '', i);
                                tokenIds.push(tokenId.toNumber());
                            }
                        }
                        return tokenIds;
                    } catch (e) {
                        console.log("Can't fetch balanceOf owner:", e)
                    }
                }
            } catch (e) {
                console.log("ERROR.EtherHelper.getHustlerId: ", JSON.stringify(e))
            }
        }
    }

    public static async nameOfErc721(context: IEtherContext, address: string) {
        if (context.connected) {
            const provider = new ethers.providers.JsonRpcProvider(AddressFactory.getRpcUrl(25))
            //const signer = provider.getSigner(context.addressSigner);
            const generalHustlers = new Contract(address, Hustlers, provider) as HyenaHustlers_launchpad;
            try {
                try {
                    const name = await generalHustlers.name()
                    return name;
                } catch (e) {
                    console.log("Can't fetch name:", e)
                }

            } catch (e) {
                console.log("ERROR.EtherHelper.nameOfErc721: ", JSON.stringify(e))
            }
        }
    }


    /*
    ------------------—-------------------------------------------------------------------------------------------------------------------
    */

    public static async queryHustlersInfo(context: IEtherContext): Promise<IEtherContext> {
        if (context.loaded && !context.reload) return context;
        const today = new Date();

        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner)
        const hyenaHustlers = new Contract(AddressFactory.getHustlers(context.chainId ?? 338), Hustlers, signer) as HyenaHustlers_launchpad

        const hustlersId = EtherHelper.getHustlerId(context)
            .then((result) => context.hustlers_tokenId = result ?? [])


        const isHYPAHustlers = EtherHelper.isHYPAHustlers(context)
        context.hustlers_isHYPA_mint = isHYPAHustlers

        const isWLHustlers = EtherHelper.isWLHustlers(context)
            .then((result) => context.hustlers_isWL_mint = result ?? false)

        context.hustlers_mint_start = "6/10/2023, 20:00:00"

        const totalSupply = EtherHelper.hustlerSupply()
            .then((result) => context.hustelrs_tSupply = result)
            .catch((e) => console.log(JSON.stringify(e)))


        if (!context.chainId) context = await this.getNetwork(provider, context);

        await Promise.all([hustlersId, isWLHustlers, totalSupply, isHYPAHustlers]);

        return context;
    }


    public static async queryProviderInfo(context: IEtherContext): Promise<IEtherContext> {
        if (context.loaded && !context.reload) return context;


        const provider = EtherHelper.initProvider();
        const signer = provider.getSigner(context.addressSigner);
        const hyenaPass = new Contract(AddressFactory.getHyenaAddress(context.chainId ?? 338), HyenaPassABI, signer) as HyenasPass_launchpad
        const HyenaStaking = new Contract(AddressFactory.getStaking(context.chainId ?? 338), DivitrendRewardsABI, signer) as DivitrendRewards;

        const tokenIds = EtherHelper.hyenaBalanceOf(context)
            .then((result) => context.hyenaTokenIds = result ?? [])
            .catch((e) => JSON.stringify(e))

        const userDataTokenIds = await HyenaStaking.getUserIds(context.addressSigner ?? '')
            .then((n) => context.hustlers_staked = n.map(i => i.toNumber()))
            .catch((e) => console.log("EtherHelper.queryProviderInfo.userDataTokenIds: ", JSON.stringify(e)))

        const mintStart = hyenaPass
            .timeStart()
            .then((timestart: BigNumber) => {
                const r_timestart = timestart.toNumber()
                const date = new Date(r_timestart * 1000);
                const formattedDate = date.toLocaleString();
                return context.hyenaMintStartAt = formattedDate;
            })
            .catch((e) => console.log("EtherHelper.queryProviderInfo.hyenaMinted: ", JSON.stringify(e)))

        const hyenaMinted = hyenaPass
            .totalSupply()
            .then((r: BigNumber) => context.hyenaMinted = r.toNumber())
            .catch((e) => console.log("EtherHelper.queryProviderInfo.hyenaMinted: ", JSON.stringify(e)))

        if (!context.chainId) context = await this.getNetwork(provider, context);

        await Promise.all([tokenIds, hyenaMinted, mintStart, userDataTokenIds]);
        await Promise.all([this.queryHustlersInfo(context)])

        return context;
    }

    public static async queryOwnerProviderInfo(context: IEtherContext): Promise<IEtherContext> {

        const provider = EtherHelper.initProvider();

        /*
        if (!context.chainId) context = await this.getNetwork(provider, context);
        await Promise.all([isTokenPausedPromise, isRewardsPausedPromise, isAutoSwapEnabledPromise,
            rewardsBalancePromise, taxTokensromise, liqTokensPromise, thresholdPromise]);
        */

        return context;
    }

    //#endregion


    public static connectChainListener(chainChanged: Listener) {
        ethereum?.on('chainChanged', chainChanged);
    }

    public static connectAccountListener(accountsChanged: Listener) {
        ethereum?.on('accountsChanged', accountsChanged);
    }

    public static connectErrorListener(error: Listener) {
        ethereum?.on("error", error);
    }

    public static async getBlockTimeStamp(): Promise<number> {
        const provider = EtherHelper.initProvider();
        const block = await provider.getBlock("latest");
        return block.timestamp * 1000;
    }

    public static disconnectListeners() {
        ethereum?.removeAllListeners();
    }
}