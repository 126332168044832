import { useContext, useEffect, useState } from "react";
import { Container, Typography, Button, Grid, createStyles, makeStyles, LinearProgress, Paper, Box, Collapse, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Switch, Tooltip } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EtherContext } from "../../ethers/EtherContext";
import { EtherContextRepository } from "../../ethers/EtherContextRepository";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { CardMedia, Divider } from '@mui/material';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import EtherHelper from "../../ethers/EtherHelper";
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CountdownTimer from '../Countdown';
import GetAppIcon from '@material-ui/icons/GetApp'; // Import the GetAppIcon
import React from "react";
import BadgeIcon from '@mui/icons-material/Badge';
import PaidIcon from '@mui/icons-material/Paid';
import NotificationIcon from '@mui/icons-material/Notifications';
import { Badge, Chip, Slider } from "@mui/material";
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { BadgeProps } from "@chakra-ui/react";
import hyena from '../../common/h2.png'
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const useStyles = makeStyles((theme) =>
    createStyles({
        rootLoader: {
            flexGrow: 1,
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            maxWidth: '100%',
            height: '100%',
            backgroundImage: 'url("bghome.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginLeft: 0,
            marginRight: 0,
            alignItems: 'center',
        },
        overlay: {
            position: 'absolute',
            height: '100%',
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: 0,
        },
        logoSpin: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            vertficalAlign: 'center',
            position: 'relative',
            top: '25%',
            width: '100%',
            flexDirection: 'column'
        },
        '@keyframes spin': {
            '0%': {
                transform: 'rotate(0deg)', // Initial rotation angle
            },
            '100%': {
                transform: 'rotate(360deg)', // Final rotation angle after 360 degrees
            },
        },
        logoImage: {
            maxWidth: '38vh',
            maxHeight: '38vh',
            animation: '$spin 2s linear infinite', // Applying the spinning animation
        },
        rainbowText: {
            background: `linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            animation: '$rainbow 5s infinite',
            fontWeight: 'bold', // Aggiunto il grassetto
            fontFamily: 'Roboto',
        },
        '@keyframes rainbow': {
            '0%': { color: theme.palette.secondary.main },
            '25%': { color: theme.palette.error.main },
            '50%': { color: theme.palette.primary.main },
            '75%': { color: theme.palette.info.main },
            '100%': { color: theme.palette.success.main },
        },
        countdown: {
            fontSize: "3rem",
            marginTop: theme.spacing(2),
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontFamily: 'Rubik Wet Paint',
        },
        progressBar: {
            width: "50%", // Larghezza della barra di avanzamento
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginLeft: "auto",
            marginRight: "auto",
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
            height: '790px',
        },
        paperAlert: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.7)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
            position: 'fixed',
            zIndex: 9999,
            width: '100%',
            minHeight: 10,
            height: 'auto'
        },
        paperMobile: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
        },
        myPassSection: {
            padding: theme.spacing(2),
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
            height: '790px',
            overflowY: 'auto',
        },
        myPassSectionMobile: {
            padding: theme.spacing(2),
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.secondary,
            background: 'rgba(17, 17, 17, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
            borderRadiusTopRight: 100,
            borderRadiusTopLeft: 100,
            minHeight: '790px',
            overflowY: 'auto',
        },
        title: {
            marginBottom: theme.spacing(2),
            fontSize: 24,
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontWeight: 'bold',
            fontFamily: 'Rubik Wet Paint',
        },
        titleCount: {
            marginBottom: theme.spacing(2),
            fontSize: 24,
            color: 'white',
            fontFamily: 'Rubik Wet Paint',
            marginTop: 50
        },
        description: {
            marginBottom: theme.spacing(2),
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
        },
        descriptionValue: {
            color: 'white',
            fontWeight: 'bold',
        },
        button: {
            marginTop: theme.spacing(2),
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            color: 'white',
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: '#FF8E53',
            },
            width: 160
        },
        buttonText: {
            marginTop: theme.spacing(2),
            border: '2px solid linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '20px',
            width: 160
        },
        gif: {
            marginTop: 20,
            width: '30%',
            height: '30%',
            border: '2px solid transparent',
            borderRadius: '30%',
            marginBottom: 20,
        },
        gifd: {
            marginTop: 20,
            width: '40%',
            height: '40%',
            border: '2px solid transparent',
            borderRadius: '30%',
            marginBottom: 20,
        },
        boxSupply: {
            display: 'flex',
            justifyContent: 'center'
        },
        catchyTextSupply: {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 32,
            letterSpacing: 0.2,
            lineHeight: 1.2,
        },
        catchyTextSupplyCard: {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 28,
            letterSpacing: 0.2,
            lineHeight: 1.2,
        },
        catchyTextSupplyCardNFT: {
            color: '#f57c00',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 28,
            letterSpacing: 0.2,
            lineHeight: 1.2,
        },
        media: {
            position: 'relative',
            height: 0,
            paddingTop: '100%', // 16:9
        },
        overlayCard: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        tooltip: {
            padding: theme.spacing(0),
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            color: '#fff',
            borderRadius: 4,
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        pagination: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        imageList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        titlePagination: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        alert: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        soldOutText: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 42,
            fontWeight: 'bold',
            color: 'white',
            zIndex: 1,
        },
        soldOutTextDesktop: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'Rubik Wet Paint',
            fontSize: 82,
            color: 'white',
            zIndex: 1,
        },
        success: {
            backgroundColor: 'green',
            color: 'white',
            fontSize: '20px',
            fontWeight: 'bold',
        },
        denied: {
            backgroundColor: 'red',
            color: 'white',
        },
        themeDark: {
            background: 'url("launchpad.jpg")',
            maxWidth: 345,
            border: "2px solit white",
            borderRadius: 50
        }
    })
);

const CustomizedHeader = styled(CardHeader)`
  background: 'launchpad.jpg';

`;

const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 8,
    width: '70%',
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Hyenas: React.FC = () => {
    const classes = useStyles()
    const deadline = new Date("2023-10-05T00:00:00").getTime();
    const { context, saveContext } = useContext(EtherContext) as EtherContextRepository;
    const isMobile = useMediaQuery('(max-width:960px)');
    const [open, setOpen] = useState(false);
    const [mintLoading, setMintLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [alertInfo, setAlertInfo] = useState<{ severity: "success" | "error", message: any } | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const hyenaMintStartAt = new Date(context.hustlers_mint_start ?? "2023-10-06T20:00:00");
    const [expanded, setExpanded] = useState(false); // Define the 'expanded' state
    const [num_tickets, setNumTickets] = useState<number>(0)
    const [showAttributes, setShowAttributes] = useState(false);
    const [totalSupplyMinted, setTotalSupply] = useState<any>();

    // Stato per memorizzare il tempo rimanente
    const [timeRemaining, setTimeRemaining] = useState<any>(0);

    const toggleOverlay = () => {
        setShowAttributes(!showAttributes);
    };

    useEffect(() => {
        // Calcola il tempo rimanente ad ogni secondo
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const timeDiff = deadline - now;

            if (timeDiff <= 0) {
                // La scadenza è scaduta
                clearInterval(interval);
                setTimeRemaining(0);
            } else {
                // Calcola il tempo rimanente in giorni, ore, minuti e secondi
                const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

                // Aggiorna lo stato con il tempo rimanente formattato
                setTimeRemaining({ days, hours, minutes, seconds });
            }
        }, 1000);

        // Pulisci l'intervallo quando il componente si smonta
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (context.connected === true) {
            if (context.hustelrs_tSupply) {
                setInterval(() => {
                    setTotalSupply(context.hustelrs_tSupply)
                }, 3200)
            }
        } else {
            EtherHelper.hustlerSupply()
                .then((result) => {
                    setTotalSupply(result)
                    context.hustelrs_tSupply = result
                })
                .catch((e) => console.log(JSON.stringify(e)))
        }
    }, [context])

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseAlert = () => {
        setAlertInfo(null);
    };

    useEffect(() => {
        console.log("isDisabled()", isDisabled());
        setDisabled(isDisabled());
        saveContext(context);
    }, [context.connected]);

    const handleClose = () => {
        setOpen(false);
    };


    function isDisabled(): boolean {
        if (!context?.connected ?? true) return true;
        return false;
    }

    const mintHandlerPublic = async () => {
        setDisabled(true);
        setMintLoading(true);

        try {
            const price = public_price * num_tickets
            const ctx = await EtherHelper.hustlers_mint(context, num_tickets, price)
            saveContext(ctx);
            console.log(ctx)
            if (ctx.toastTitle || ctx.toastStatus === 'error') {
                setAlertInfo({ severity: "error", message: ctx.toastDescription + '' });
            }
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: "success", message: ctx.toastDescription });
            }
            if (context.balance && context.balance < price) {
                setAlertInfo({ severity: "error", message: "Check your balance!" });
            }
            setTimeout(() => {
                saveContext(context);
            }, 5000);
            console.log(context)
        } catch (error: any) {
            console.error("Error on mint: ", JSON.stringify(error));
            if (error === "execution reverted: limit is one mint per address") {
                setAlertInfo({ severity: "error", message: "Sorry, you have already minted one NFT." });
            } else {
                setAlertInfo({ severity: "error", message: error.reason });
            }
        } finally {
            setDisabled(false);
            setMintLoading(false);
            saveContext(context);

            if (alertInfo?.severity === "error") {
                setTimeout(() => {
                    handleCloseAlert();
                }, 3000);
            }
        }
    };

    const mintHandlerHYPA = async () => {
        setDisabled(true);
        setMintLoading(false);
        const price = hypa_price * num_tickets

        try {
            console.log(price)
            const ctx = await EtherHelper.hustlers_mint_hypa(context, num_tickets, price)
            console.log("NFT.mint(%s): ", JSON.stringify(ctx));
            saveContext(ctx);
            if (ctx.toastTitle && ctx.toastStatus === 'error') {
                setAlertInfo({ severity: "error", message: ctx.toastDescription + '' });
            }
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: "success", message: ctx.toastDescription });
            }
            if (context.balance && context.balance < price) {
                setAlertInfo({ severity: "error", message: "Check your balance!" });
            }
            setTimeout(() => {
                saveContext(context);
            }, 5000);
        } catch (error: any) {
            console.error("Error on mint: ", JSON.stringify(error));
            if (error === "execution reverted: limit mint per address") {
                setAlertInfo({ severity: "error", message: "Sorry, you have already minted 50 NFT." });
            } else {
                setAlertInfo({ severity: "error", message: error.reason });
            }

        } finally {
            setDisabled(false);
            setMintLoading(false);
            saveContext(context);

            if (alertInfo?.severity === "error") {
                setTimeout(() => {
                    handleCloseAlert();
                }, 3000);
            }
        }
    };

    const mintHandlerWL = async () => {
        setDisabled(true);
        setMintLoading(true);

        try {
            const price = wl_price * num_tickets
            const ctx = await EtherHelper.hustlers_mint_wl(context, num_tickets, price);
            console.log("NFT.mint(%s): ", JSON.stringify(ctx));
            saveContext(ctx);
            if (ctx.toastTitle || ctx.toastStatus === 'error') {
                setAlertInfo({ severity: "error", message: ctx.toastDescription + '' });
            }
            if (ctx.toastStatus === 'success') {
                setAlertInfo({ severity: "success", message: ctx.toastDescription });
            }
            if (context.balance && context.balance < price) {
                setAlertInfo({ severity: "error", message: "Check your balance!" });
            }
            setTimeout(() => {
                saveContext(context);
            }, 5000);
            console.log(context)
        } catch (error: any) {
            console.error("Error on mint: ", JSON.stringify(error));
            if (error === "execution reverted: limit mint per address") {
                setAlertInfo({ severity: "error", message: "Sorry, you have already mint 50 NFT." });
            } else {
                setAlertInfo({ severity: "error", message: error.reason });
            }
        } finally {
            setDisabled(false);
            setMintLoading(false);
            saveContext(context);

            if (alertInfo?.severity === "error") {
                setTimeout(() => {
                    handleCloseAlert();
                }, 3000);
            }
        }
    };

    const [tokenCards, setTokenCards] = useState<JSX.Element[]>([]);

    const trattiHyena = [
        'Divine Energy: Time Cube Keeper',
        'Celestial Guardianship: Starry Adornment',
        'Biological Anomaly: Cosmic Tri-Eye',
        'Mystical Hues: Pale Prophet',
        'Role: Master',
        'Skin: Wood IV',
        'Skill: Whale',
        'Alias: Hyena Prophet'
    ];

    const NumPerTicket = 200 * num_tickets

    const wl_price = 270
    const hypa_price = 250
    const public_price = 300

    // MANUALLY FETCH FUNC

    const fetchTokenCards = async () => {
        const newTokenCards: JSX.Element[] = [];
        const hyenaTokenIds = context.hustlers_tokenId ?? []

        for (const nft of hyenaTokenIds) {
            try {
                const tokenImage = await EtherHelper.getHustlerTokenURI(context, nft);
                console.log(tokenImage)
                const tokenData = tokenImage;
                const handleDownloadClick = () => {
                    // Assuming 'image' is the URL of the image you want to download
                    const imageDownloadLink = document.createElement('a');
                    imageDownloadLink.href = "h1.png";
                    imageDownloadLink.setAttribute('download', 'nft_image.png'); // Set the desired file name
                    document.body.appendChild(imageDownloadLink);
                    imageDownloadLink.click();
                    document.body.removeChild(imageDownloadLink);
                };
                newTokenCards.push(
                    <Card className={classes.themeDark} key={nft} style={{ marginBottom: 30 }}>
                        <CustomizedHeader
                            className={classes.themeDark}
                            title={
                                <Typography variant="h4" className={classes.catchyTextSupplyCardNFT} color="textSecondary" component="p">
                                    {tokenData.name}
                                </Typography>
                            }
                        />
                        <CardMedia
                            className={classes.media}
                            image={'https://ipfs.io/ipfs/' + tokenData.image.slice(7)}
                            title="NFT Image"
                        >
                            {showAttributes && (
                                <div className={classes.overlay}>
                                    <Grid style={{ marginTop: 3 }} container spacing={1} direction="row">
                                        {tokenData.attributes.map((attribute: any, index: number) => (
                                            <Grid item xs={6} key={index}>
                                                <Tooltip title={`${index}`} arrow className={classes.tooltip}>
                                                    <Typography variant="body1">
                                                        {attribute.trait_type}
                                                        <div>{attribute.value}</div>
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            )}
                        </CardMedia>
                        <CardContent>
                            {/* Card content */}
                        </CardContent>
                        <CardActions disableSpacing
                            className={classes.themeDark}
                        >
                            <IconButton aria-label="download" onClick={handleDownloadClick}>
                                <GetAppIcon color="secondary" />
                            </IconButton>
                            <Switch
                                checked={showAttributes}
                                onChange={() => setShowAttributes(!showAttributes)}
                                color="secondary"
                                inputProps={{ 'aria-label': 'toggle attributes' }}
                            />
                        </CardActions>
                    </Card >
                );
            } catch (error) {
                console.error('Error fetching token data:', error);
            }
        }

        setTokenCards(newTokenCards);
    };

    /* —————————————————————— */

    useEffect(() => {
        if (!context.hyenaTokenIds) return;

        const fetchTokenCards = async () => {
            const newTokenCards: JSX.Element[] = [];
            const hyenaTokenIds = context.hustlers_tokenId ?? []

            for (const nft of hyenaTokenIds) {
                try {
                    const tokenImage = await EtherHelper.getHustlerTokenURI(context, nft);
                    console.log(tokenImage)
                    const tokenData = tokenImage;
                    const handleDownloadClick = () => {
                        // Assuming 'image' is the URL of the image you want to download
                        const imageDownloadLink = document.createElement('a');
                        imageDownloadLink.href = "h1.png";
                        imageDownloadLink.setAttribute('download', 'nft_image.png'); // Set the desired file name
                        document.body.appendChild(imageDownloadLink);
                        imageDownloadLink.click();
                        document.body.removeChild(imageDownloadLink);
                    };
                    newTokenCards.push(
                        <Card className={classes.themeDark} key={nft} style={{ marginBottom: 30 }}>
                            <CustomizedHeader
                                className={classes.themeDark}
                                title={
                                    <Typography variant="h4" className={classes.catchyTextSupplyCardNFT} color="textSecondary" component="p">
                                        {tokenData.name}
                                    </Typography>
                                }
                            />
                            <CardMedia
                                className={classes.media}
                                image={'https://ipfs.io/ipfs/' + tokenData.image.slice(7)}
                                title="NFT Image"
                            >
                                {showAttributes && (
                                    <div className={classes.overlay}>
                                        <Grid style={{ marginTop: 3 }} container spacing={1} direction="row">
                                            {tokenData.attributes.map((attribute: any, index: number) => (
                                                <Grid item xs={6} key={index}>
                                                    <Tooltip title={`${index}`} arrow className={classes.tooltip}>
                                                        <Typography variant="body1">
                                                            {attribute.trait_type}
                                                            <div>{attribute.value}</div>
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                )}
                            </CardMedia>
                            <CardContent>
                                {/* Card content */}
                            </CardContent>
                            <CardActions disableSpacing
                                className={classes.themeDark}
                            >
                                <IconButton aria-label="download" onClick={handleDownloadClick}>
                                    <GetAppIcon color="secondary" />
                                </IconButton>
                                <Switch
                                    checked={showAttributes}
                                    onChange={() => setShowAttributes(!showAttributes)}
                                    color="secondary"
                                    inputProps={{ 'aria-label': 'toggle attributes' }}
                                />
                            </CardActions>
                        </Card >
                    );
                } catch (error) {
                    console.error('Error fetching token data:', error);
                }
            }

            setTokenCards(newTokenCards);
        };

        fetchTokenCards();
    }, [context.hyenaTokenIds, classes.themeDark, context, classes.catchyTextSupplyCard, classes.media, classes.overlay, classes.tooltip, showAttributes]);

    return (
        <div className={classes.rootLoader}>
            <div className={classes.overlay}></div>
            {isMobile ? (
                <Grid spacing={2}>
                    <Grid item xs={12} md={12}>
                        {alertInfo !== null && (
                            <Paper elevation={3} className={classes.paperAlert}>
                                <Collapse in={alertInfo !== null}>
                                    <Alert
                                        variant="outlined"
                                        severity={alertInfo?.severity || "info"}
                                        onClose={handleCloseAlert}
                                    >
                                        <AlertTitle>{alertInfo?.severity === "error" ? "Error" : "Success"}</AlertTitle>
                                        {alertInfo?.message}
                                    </Alert>
                                </Collapse>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography variant="body1" className={classes.description}>
                                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                                    <Chip
                                        icon={<BadgeIcon />}
                                        label={context.connected ? 'Connected' : 'Not Connected'}
                                        color={context.connected ? 'success' : 'warning'}
                                        variant="outlined"
                                    />
                                </div>
                            </Typography>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <CountdownTimer targetDate={hyenaMintStartAt} />
                            </div>
                            <img src="h.gif" alt="GIF" className={classes.gifd} style={{ width: 300 }} />
                            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>

                                <Chip
                                    style={{ marginTop: 10 }}
                                    icon={<PaidIcon />}
                                    className={classes.success}
                                    label={'Public: 300 CRO'}
                                    color={'success'}
                                />
                                <Chip
                                    style={{ marginTop: 10 }}
                                    icon={<PaidIcon />}
                                    className={classes.success}
                                    label={'WL: 270 CRO'}
                                    color={'warning'}
                                />
                                <Chip
                                    style={{ marginTop: 10 }}
                                    icon={<PaidIcon />}
                                    className={classes.success}
                                    label={'Alpha: 250 CRO'}
                                    color={'warning'}
                                />
                            </div>
                            <Box style={{ marginTop: 40 }} className={classes.boxSupply}>
                                <Typography variant="h3" className={classes.catchyTextSupply} style={{ marginRight: 20 }}> {totalSupplyMinted}</Typography> <Typography variant="h3" className={classes.catchyTextSupply} style={{ marginRight: 10 }}>/</Typography><Typography variant="h3" className={classes.catchyTextSupply} style={{ marginLeft: 10 }}> 3200</Typography>
                            </Box>
                            <Grid item xs={12} md={12} style={{ marginBottom: 20, marginTop: 20 }}>
                                <div style={{ display: "flex", flexDirection: 'row' }}>
                                    <LinearProgress style={{ marginTop: 10, display: "flex", width: '100%', flexDirection: 'row' }} color="secondary" variant="determinate" value={(totalSupplyMinted / 3200) * 100} /> <Typography style={{ marginLeft: 10 }} className={classes.descriptionValue}>{((totalSupplyMinted / 3200) * 100).toFixed(2)}%</Typography>
                                </div>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} justifyContent="center" style={{ alignItems: 'center' }}>
                        <Paper elevation={3} className={classes.myPassSectionMobile}>

                            <Grid container spacing={2} justifyContent="center" style={{ alignItems: 'center', marginBottom: 40 }}>
                                <Typography style={{ marginTop: 20 }} className={classes.descriptionValue}>Select how many hustlers you want to mint:</Typography>
                                <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                                    <PrettoSlider
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={1}
                                        value={num_tickets}
                                        min={1}
                                        step={1}
                                        max={50}
                                        onChange={(_, value) => setNumTickets(value as number)}
                                    />
                                </Grid>
                                <Grid item md={12} className={classes.buttonText} style={{ marginTop: 10 }}>
                                </Grid>
                                <Grid item xs={12} justifyContent="center" style={{ alignItems: 'center' }}>
                                    <Button variant="contained" className={classes.button} onClick={mintHandlerPublic}>
                                        <IconButton style={{ marginRight: 10 }} aria-label="cart">
                                            <StyledBadge badgeContent={num_tickets} color="secondary">
                                                <ShoppingCartIcon />
                                            </StyledBadge>
                                        </IconButton>
                                        MINT
                                    </Button>
                                    <div>
                                        <Chip
                                            style={{ marginTop: 10 }}
                                            icon={<PaidIcon />}
                                            className={classes.success}
                                            label={public_price * num_tickets + 'CRO'}
                                            color={'success'}

                                        />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" disabled={context.hustlers_isWL_mint ? false : true} onClick={mintHandlerWL} className={classes.button} color="secondary" >
                                        <IconButton style={{ marginRight: 10 }} aria-label="cart">
                                            {context.hustlers_isWL_mint && (
                                                <StyledBadge badgeContent={num_tickets} color="secondary">
                                                    <ShoppingCartIcon />
                                                </StyledBadge>
                                            )}

                                            {!context.hustlers_isWL_mint && (
                                                <StyledBadge color="secondary">
                                                    <ShoppingCartIcon />
                                                </StyledBadge>
                                            )}
                                        </IconButton>
                                        WL MINT
                                    </Button>
                                    <div>
                                        <Chip
                                            style={{ marginTop: 10 }}
                                            icon={<PaidIcon />}
                                            className={classes.success}
                                            label={wl_price * num_tickets + 'CRO'}
                                            color={'success'}

                                        />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Button style={{ minHeight: 61 }} variant="outlined" disabled={context.hustlers_isHYPA_mint ? false : true} onClick={mintHandlerHYPA} className={classes.button} color="secondary" >
                                        {context.hustlers_isHYPA_mint && (
                                            <StyledBadge badgeContent={num_tickets} color="secondary">
                                                <ShoppingCartIcon />
                                            </StyledBadge>
                                        )}

                                        {!context.hustlers_isHYPA_mint && (
                                            <StyledBadge color="secondary" >
                                                <ShoppingCartIcon />
                                            </StyledBadge>
                                        )}
                                        <a style={{ marginLeft: 30 }}>ALPHA</a>
                                    </Button>
                                    <div>
                                        <Chip
                                            style={{ marginTop: 10 }}
                                            icon={<PaidIcon />}
                                            className={classes.success}
                                            label={hypa_price * num_tickets + 'CRO'}
                                            color={'success'}

                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Divider style={{ border: '1px solid #f57c00', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }} light={true}>
                                <Chip style={{ marginTop: -25 }} color={'warning'} label="IN MY WALLET" />
                            </Divider>
                            <Button style={{ color: '#f57c00', border: '1px solid #f57c00', marginTop: 15, marginBottom: 15 }} variant="outlined" onClick={fetchTokenCards}>REFRESH</Button>
                            <Divider style={{ border: '1px solid #f57c00', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }} light={true}>
                            </Divider>
                            <div style={{ borderRadius: '10%', marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                {tokenCards}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid />
                </Grid>
            ) : (
                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            {alertInfo !== null && (
                                <Paper elevation={3} className={classes.paperAlert}>
                                    <Collapse in={alertInfo !== null}>
                                        <Alert
                                            variant="outlined"
                                            severity={alertInfo?.severity || "info"}
                                            onClose={handleCloseAlert}
                                        >
                                            <AlertTitle>{alertInfo?.severity === "error" ? "Error" : "Success"}</AlertTitle>
                                            {alertInfo?.message}
                                        </Alert>
                                    </Collapse>
                                </Paper>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} className={classes.paper}>
                                <Typography variant="body1" className={classes.description}>
                                    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                                        <Chip
                                            icon={<BadgeIcon />}
                                            label={context.connected ? 'Connected' : 'Not Connected'}
                                            color={context.connected ? 'success' : 'warning'}
                                            variant="outlined"
                                        />
                                    </div>
                                </Typography>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <CountdownTimer targetDate={hyenaMintStartAt} />
                                </div>
                                <img src="h.gif" alt="GIF" className={classes.gifd} />
                                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>

                                    <Chip
                                        style={{ marginTop: 10 }}
                                        icon={<PaidIcon />}
                                        className={classes.success}
                                        label={'Public: 300 CRO'}
                                        color={'success'}
                                    />
                                    <Chip
                                        style={{ marginTop: 10 }}
                                        icon={<PaidIcon />}
                                        className={classes.success}
                                        label={'WL: 270 CRO'}
                                        color={'warning'}
                                    />
                                    <Chip
                                        style={{ marginTop: 10 }}
                                        icon={<PaidIcon />}
                                        className={classes.success}
                                        label={'Alpha: 250 CRO'}
                                        color={'warning'}
                                    />
                                </div>
                                <Box style={{ marginTop: 40 }} className={classes.boxSupply}>
                                    <Typography variant="h3" className={classes.catchyTextSupply} style={{ marginRight: 20 }}> {totalSupplyMinted}</Typography> <Typography variant="h3" className={classes.catchyTextSupply} style={{ marginRight: 10 }}>/</Typography><Typography variant="h3" className={classes.catchyTextSupply} style={{ marginLeft: 10 }}> 3200</Typography>
                                </Box>
                                <Grid item xs={12} md={12} style={{ marginBottom: 20, marginTop: 20 }}>
                                    <div style={{ display: "flex", flexDirection: 'row' }}>
                                        <LinearProgress style={{ marginTop: 10, display: "flex", width: '100%', flexDirection: 'row' }} color="secondary" variant="determinate" value={(totalSupplyMinted / 3200) * 100} /> <Typography style={{ marginLeft: 10 }} className={classes.descriptionValue}>{((totalSupplyMinted / 3200) * 100).toFixed(2)}%</Typography>
                                    </div>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} justifyContent="center" style={{ alignItems: 'center' }}>
                            <Paper elevation={3} className={classes.myPassSection}>

                                <Grid container spacing={2} justifyContent="center" style={{ alignItems: 'center', marginBottom: 40 }}>
                                    <Typography style={{ marginTop: 20 }} className={classes.descriptionValue}>Select how many hustlers you want to mint:</Typography>
                                    <Grid item md={12} style={{ marginTop: 0 }}>
                                        <PrettoSlider
                                            valueLabelDisplay="auto"
                                            aria-label="pretto slider"
                                            defaultValue={1}
                                            value={num_tickets}
                                            min={1}
                                            step={1}
                                            max={50}
                                            onChange={(_, value) => setNumTickets(value as number)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} className={classes.buttonText} style={{ marginTop: 10 }}>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" className={classes.button} onClick={mintHandlerPublic}>
                                            <IconButton style={{ marginRight: 10 }} aria-label="cart">
                                                <StyledBadge badgeContent={num_tickets} color="secondary">
                                                    <ShoppingCartIcon />
                                                </StyledBadge>
                                            </IconButton>
                                            MINT
                                        </Button>
                                        <div>
                                            <Chip
                                                style={{ marginTop: 10 }}
                                                icon={<PaidIcon />}
                                                className={classes.success}
                                                label={public_price * num_tickets + 'CRO'}
                                                color={'success'}

                                            />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" disabled={context.hustlers_isWL_mint ? false : true} onClick={mintHandlerWL} className={classes.button} color="secondary" >
                                            <IconButton style={{ marginRight: 10 }} aria-label="cart">
                                                {context.hustlers_isWL_mint && (
                                                    <StyledBadge badgeContent={num_tickets} color="secondary">
                                                        <ShoppingCartIcon />
                                                    </StyledBadge>
                                                )}

                                                {!context.hustlers_isWL_mint && (
                                                    <StyledBadge color="secondary">
                                                        <ShoppingCartIcon />
                                                    </StyledBadge>
                                                )}
                                            </IconButton>
                                            WL MINT
                                        </Button>
                                        <div>
                                            <Chip
                                                style={{ marginTop: 10 }}
                                                icon={<PaidIcon />}
                                                className={classes.success}
                                                label={wl_price * num_tickets + 'CRO'}
                                                color={'success'}

                                            />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Button style={{ minHeight: 61 }} variant="outlined" disabled={context.hustlers_isHYPA_mint ? false : true} onClick={mintHandlerHYPA} className={classes.button} color="secondary" >
                                            {context.hustlers_isHYPA_mint && (
                                                <StyledBadge badgeContent={num_tickets} color="secondary">
                                                    <ShoppingCartIcon />
                                                </StyledBadge>
                                            )}

                                            {!context.hustlers_isHYPA_mint && (
                                                <StyledBadge color="secondary" >
                                                    <ShoppingCartIcon />
                                                </StyledBadge>
                                            )}
                                            <a style={{ marginLeft: 30 }}>ALPHA</a>
                                        </Button>
                                        <div>
                                            <Chip
                                                style={{ marginTop: 10 }}
                                                icon={<PaidIcon />}
                                                className={classes.success}
                                                label={hypa_price * num_tickets + 'CRO'}
                                                color={'success'}

                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider style={{ border: '1px solid #f57c00', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }} light={true}>
                                    <Chip style={{ marginTop: -25 }} color={'warning'} label="IN MY WALLET" />
                                </Divider>
                                <Button style={{ color: '#f57c00', border: '1px solid #f57c00', marginTop: 15, marginBottom: 15 }} variant="outlined" onClick={fetchTokenCards}>REFRESH</Button>
                                <Divider style={{ border: '1px solid #f57c00', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }} light={true}>
                                </Divider>
                                <div style={{ borderRadius: '10%', marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                    {tokenCards}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid />
                    </Grid>
                </div >
            )
            }
            <div style={{ height: 50 }}></div>
        </div >
    );
};

export default Hyenas
