import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, AppBar, Toolbar, Button, FormControl, InputLabel, OutlinedInput, withStyles, createTheme } from '@material-ui/core';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import { EtherContextRepository } from '../../ethers/EtherContextRepository';
import { EtherContext } from '../../ethers/EtherContext';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        backgroundImage: 'url("casino.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        position: 'relative',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minWidth: '100%',
        height: '100%',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: -1,
        },
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
        zIndex: 0,
    },
    section: {
        padding: theme.spacing(3),
        borderRadius: theme.spacing(2),
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        marginBottom: theme.spacing(4),
        color: 'white'
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
    },
    appBar: {
        marginBottom: theme.spacing(3),
        width: '100%',
        top: '7.5%',
        color: 'white', zIndex: theme.zIndex.drawer + 1,
        background: 'rgba(17, 17, 17, 0.1)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        height: 56,
        alignItems: 'center'
    },
    navButton: {
        marginRight: theme.spacing(2),
        color: 'white',
        background: 'rgba(17, 17, 17, 0.2)',
        border: '2px solid transparent',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        marginLeft: theme.spacing(0),
        borderRadius: theme.spacing(2),
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
            background: '#FF8053',
            color: 'white',
        },
    },
    paper: {
        display: 'flex',
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: 'rgba(17, 17, 17, 0.0)',
        marginTop: 80,
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
}));

const theme = createTheme({
    palette: {
        primary: green,
    },
});

const Casino: React.FC = () => {
    const classes = useStyles();
    const [amount, setAmount] = useState("");
    const { context, saveContext } = React.useContext(EtherContext) as EtherContextRepository;

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, "");
        setAmount(inputValue);
    };

    return (
        <div className={classes.root}>
            <div className={classes.overlay}></div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Button component={Link} to="/casino/raffle" className={classes.navButton}>
                        Raffle
                    </Button>
                    <Button component={Link} to="/casino/lottery" className={classes.navButton}>
                        Lottery
                    </Button>
                    <Button component={Link} to="/casino/coinflip" className={classes.navButton}>
                        Coinflip
                    </Button>
                    <Button  component={Link} to="/casino/hilow" className={classes.navButton}>
                        HiLo
                    </Button>
                </Toolbar>
            </AppBar>
            <Paper elevation={3} className={classes.paper}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Paper className={classes.section}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Welcome to Our Casino Hustler!
                            </Typography>
                            <Typography variant="body1">
                                Explore the exciting world of gambling and entertainment at our luxurious casino. Test your luck and enjoy a wide range of games and experiences.
                            </Typography>
                        </Paper>
                    </Grid>
                    {/*
                    <Grid item xs={12}>
                        <Paper className={classes.section}>
                            <Typography variant="h4" className={classes.sectionTitle}>
                                Buy $XXX to play!
                            </Typography>
                            <Typography variant="body1">
                                Step into a world of thrilling entertainment at our luxurious casino. Whether you're a seasoned gambler or new to the scene, our diverse selection of games offers something for everyone. Test your luck on our state-of-the-art slot machines, try your skills at classic table games, or immerse yourself in the excitement of live casino experiences. To join in on the action, simply purchase tokens and dive into a realm of endless possibilities. The more you play, the higher your chances of winning big prizes and exclusive rewards. Come and explore the world of casino entertainment today!
                            </Typography>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, marginBottom: 10, alignItems: 'center', justifyContent: 'center' }}>
                                <form noValidate autoComplete="off" style={{ width: '200px', background: 'rgba(255, 255, 255, 0.2)' }}>
                                    <TextField
                                        label="Enter Amount"
                                        variant="filled"
                                        color="secondary"
                                        value={amount}
                                        onChange={handleAmountChange}
                                    />
                                </form>
                                <Typography variant="body1" style={{ marginTop: 20, marginBottom: 20 }}>
                                    $XXX Balance:  860
                                </Typography>
                                <Typography variant="body2" style={{ marginBottom: 20 }}>
                                    $CRO Balance:  {context.balance?.toFixed(2) ?? ''}
                                </Typography>

                                <Button
                                    variant="contained"
                                    color="secondary"
                                >
                                    Buy Tokens
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                    */}
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.section}>
                            <Typography variant="h5" className={classes.sectionTitle}>
                                Raffle
                            </Typography>
                            <Typography variant="body1">
                                Try your luck on our raffle lottery. Get your ticket and win big prizes in a variety of huge items. Join now 
                                <div style={{marginTop: 5}}>
                                <Link to="/casino/raffle"><Button variant="contained" color="secondary">Join</Button></Link>
                                </div>
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper className={classes.section}>
                            <Typography variant="h5" className={classes.sectionTitle}>
                                Lottery
                            </Typography>
                            <Typography variant="body1">
                            Try your luck on our lottery. Get your ticket and win big prizes in a variety of huge items. Join now 
                                <div style={{marginTop: 5}}>
                                <Link to="/casino/lottery"><Button variant="contained" color="secondary">Join</Button></Link>
                                </div>
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper className={classes.section}>
                            <Typography variant="h5" className={classes.sectionTitle}>
                                Coinflip
                            </Typography>
                            <Typography variant="body1">
                                Step into the realm of chance with Coinflip, an engaging on-chain game that's as simple as it is exhilarating! In Coinflip, you'll choose heads or tails and place your bet. Once the bets are set, a virtual coin will be flipped in real-time on the blockchain. Will your prediction be correct? It's a showdown of fate, and every flip holds the potential for victory.
                                <div style={{marginTop: 5}}>
                                <Link to="/casino/coinflip"> <Button  variant="contained" color="secondary">Join</Button></Link>
                                </div>
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper className={classes.section}>
                            <Typography variant="h5" className={classes.sectionTitle}>
                                HiLo
                            </Typography>
                            <Typography variant="body1">
                                Dive into the exciting world of HiLo, a thrilling on-chain game that tests your prediction skills and luck! Place your bets and choose high or low.
                                <div style={{marginTop: 5}}>
                                <Link to="/casino/hilow"> <Button  variant="contained" color="secondary">Join</Button></Link>
                                </div>
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.section}>
                            <Typography variant="h5" className={classes.sectionTitle}>
                                Live History
                            </Typography>
                            <Typography variant="body1">
                                Explore the fascinating history of on-chain game transactions, a dynamic record of your thrilling casino experiences.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.section}>
                            <Typography variant="h5" className={classes.sectionTitle}>
                                Rewards and Promotions — (PHASE 2)
                            </Typography>
                            <Typography variant="body1">
                                Take advantage of our exclusive rewards and promotions. From bonuses to loyalty programs, we'll offer various ways to maximize your winnings.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </div >
    );
};

export default Casino;
