/* eslint-disable no-lone-blocks */
import axios from "axios";

export const IPFSolver = async (ipfsURL: string): Promise<string> => {


    /*  function extractCID(ipfsURL: string) {
    const cidPattern = /Qm[0-9a-zA-Z]+/;
    const match = ipfsURL.match(cidPattern);
    return match ? match[0] : '';
    */

    const extractedCID = ipfsURL.slice(7)
    console.log('CID estratto:', extractedCID);

    const tryFetchImage = async (): Promise<string> => {
        try {
            const add_gateway = `https://ipfs.filebase.io/ipfs/${extractedCID}`
            return add_gateway;
        } catch (error) {
            console.error(`Error fetching image from IPFS: ${error}`);

            const localImageURL = 'Hye.jpg';
            return localImageURL;
        }
    };
    //let's try an 1m interval
    const intervalId = setInterval(async () => {
        const imageURL = await tryFetchImage();
        console.log('URL dell\'immagine:', imageURL);
        //resolution
        if (imageURL === ipfsURL) {
            clearInterval(intervalId);
        }
    }, 420 * 1000);

    let image_url: string = await tryFetchImage()

    return image_url; // Modifica con il valore appropriato

};
