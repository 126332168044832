export async function getMessages() {
    try {
        const response = await fetch("https://hyena-api.onrender.com/api/messages");

        if (!response.ok) {
            return ''
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function PushNames(server: string) {
    try {
        const apiUrl = `https://hyena-api.onrender.com/api/messages?serverName=${server}`;

        const response = await fetch(apiUrl);
        console.log(response)

        if (!response.ok) {
            console.log(response.status);
            return ''
        }

        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error(error);
    }
}


export async function getServerInfo() {
    try {
        const response = await fetch("https://hyena-api.onrender.com/api/server-id");

        console.log(response)

        if (!response.ok) {
           console.log(`Errore durante la richiesta delle informazioni del server: ${response.statusText}`);
           return ''
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}