import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button, Grid, Paper, Box, Collapse, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@material-ui/core';
import { EtherContext } from '../../ethers/EtherContext';
import { EtherContextRepository } from '../../ethers/EtherContextRepository';
import EtherHelper from '../../ethers/EtherHelper';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Pagination, PaginationItem } from '@mui/material';
import { Alert, AlertTitle } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CountdownTimer from '../Countdown';
import GetAppIcon from '@material-ui/icons/GetApp'; // Import the GetAppIcon

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minWidth: '100%',
    height: '100%',
    backgroundImage: 'url("bghome.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginLeft: 0,
    marginRight: 0,
    alignItems: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
      backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
      zIndex: -1,
    },
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
    backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
    zIndex: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: 'rgba(17, 17, 17, 0.7)',
    backdropFilter: 'blur(10px)',
    border: '2px solid transparent',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
    borderRadiusTopRight: 100,
    borderRadiusTopLeft: 100,
    height: '790px',
  },
  paperMobile: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: 'rgba(17, 17, 17, 0.7)',
    backdropFilter: 'blur(10px)',
    border: '2px solid transparent',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
    borderRadiusTopRight: 100,
    borderRadiusTopLeft: 100,
  },
  myPassSection: {
    padding: theme.spacing(2),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    background: 'rgba(17, 17, 17, 0.7)',
    backdropFilter: 'blur(10px)',
    border: '2px solid transparent',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
    borderRadiusTopRight: 100,
    borderRadiusTopLeft: 100,
    height: '790px',
    overflowY: 'auto',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 24,
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontWeight: 'bold',
    fontFamily: 'Rubik Wet Paint',
  },
  titleCount: {
    marginBottom: theme.spacing(2),
    fontSize: 24,
    color: 'white',
    fontFamily: 'Rubik Wet Paint',
    marginTop: 50
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
  },
  button: {
    marginTop: theme.spacing(2),
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#FF8E53',
    },
  },
  gif: {
    marginTop: 20,
    width: '30%',
    height: '30%',
    border: '2px solid transparent',
    borderRadius: '30%',
    marginBottom: 20,
  },
  boxSupply: {
    display: 'flex',
    justifyContent: 'center'
  },
  catchyTextSupply: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontFamily: 'Rubik Wet Paint',
    fontSize: 32,
    letterSpacing: 0.2,
    lineHeight: 1.2,
  },
  catchyTextSupplyCard: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontFamily: 'Rubik Wet Paint',
    fontSize: 28,
    letterSpacing: 0.2,
    lineHeight: 1.2,
  },
  card: {
    maxWidth: 345,
    backgroundColor: "black",
    border: "2px solit white",
    borderRadius: 50
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  pagination: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titlePagination: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  soldOutText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Rubik Wet Paint',
    fontSize: 42,
    fontWeight: 'bold',
    color: 'white',
    zIndex: 1,
  },
  soldOutTextDesktop: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Rubik Wet Paint',
    fontSize: 82,
    color: 'white',
    zIndex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Launchpad: React.FC = () => {
  const classes = useStyles();
  const { context, saveContext } = React.useContext(EtherContext) as EtherContextRepository;
  const isMobile = useMediaQuery('(max-width:960px)');
  const [open, setOpen] = React.useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertInfo, setAlertInfo] = useState<{ severity: "success" | "error", message: string } | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const hyenaMintStartAt = new Date(context.hyenaMintStartAt ?? "2023-08-20T21:00:00");
  const [expanded, setExpanded] = useState(false); // Define the 'expanded' state

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseAlert = () => {
    setAlertInfo(null);
  };

  useEffect(() => {
    console.log("isDisabled()", isDisabled());
    setDisabled(isDisabled());
    saveContext(context);
  }, [context.connected]);

  const handleClose = () => {
    setOpen(false);
  };


  function isDisabled(): boolean {
    if (!context?.connected ?? true) return true;
    return false;
  }

  const mintHandler = async () => {
    setDisabled(true);
    setMintLoading(true);

    try {
      const ctx = await EtherHelper.mint(context, 1);
      console.log("NFT.mint(%s): ", JSON.stringify(ctx));
      saveContext(ctx);
      if (ctx.toastTitle && ctx.toastStatus === 'error') {
        setAlertInfo({ severity: "error", message: ctx.toastDescription + '' });
      } else {
        setAlertInfo({ severity: "success", message: "Minting was successful!" });
      }
      setTimeout(() => {
        saveContext(context);
      }, 5000);
      console.log(context)
    } catch (error: any) {
      console.error("Error on mint: ", JSON.stringify(error));
      if (error === "execution reverted: limit is one mint per address") {
        setAlertInfo({ severity: "error", message: "Sorry, you have already minted one NFT." });
      } else {
        setAlertInfo({ severity: "error", message: error.reason });
      }
    } finally {
      setDisabled(false);
      setMintLoading(false);
      saveContext(context);

      if (alertInfo?.severity === "error") {
        setTimeout(() => {
          handleCloseAlert();
        }, 3000);
      }
    }
  };

  const [tokenCards, setTokenCards] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (!context.hyenaTokenIds) return;

    const fetchTokenCards = async () => {
      const newTokenCards: JSX.Element[] = [];
      const hyenaTokenIds = context.hyenaTokenIds ?? []

      for (const nft of hyenaTokenIds) {
        try {
          const tokenImage = await EtherHelper.getTokenURI(context, nft);
          console.log(tokenImage)
          const tokenData = tokenImage;
          const image = 'https://ipfs.io/ipfs/' + tokenData.image.slice(7)
          const handleDownloadClick = () => {
            // Assuming 'image' is the URL of the image you want to download
            const imageDownloadLink = document.createElement('a');
            imageDownloadLink.href = image;
            imageDownloadLink.setAttribute('download', 'nft_image.png'); // Set the desired file name
            document.body.appendChild(imageDownloadLink);
            imageDownloadLink.click();
            document.body.removeChild(imageDownloadLink);
          };
          newTokenCards.push(
            <Card className={classes.card} key={nft} style={{ marginBottom: 30 }}>
              <CardHeader
                title={
                  <Typography variant="h4" className={classes.catchyTextSupplyCard} color="textSecondary" component="p">
                    {tokenData.name}
                  </Typography>
                }
              />
              <CardMedia
                className={classes.media}
                image={image}
                title="NFT Image"
              />
              <CardContent>
                {/* Card content */}
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="download" onClick={handleDownloadClick}>
                  <GetAppIcon color="secondary" />
                </IconButton>
              </CardActions>
            </Card>
          );
        } catch (error) {
          console.error('Error fetching token data:', error);
        }
      }

      setTokenCards(newTokenCards);
    };

    fetchTokenCards();
  }, [context.hyenaTokenIds, classes.card, context]);

  return (
    <div className={classes.root}>
      <div className={classes.overlay}></div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Unlock Exclusive Benefits: Your Alpha Invitation Awaits!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Welcome to our platform! Discover the power of an Alpha Invitation – your gateway to exclusive project perks.
            <br /><br />
            One Alpha Invitation per user, ensuring a committed community of like-minded individuals.
            <br /><br />
            Your Alpha Advantages:
            <ol>
              <li>Early Access: Be the first to experience updates and share feedback.</li>
              <li>Direct Influence: Shape the project's direction with your insights.</li>
              <li>Exclusive Content: Access special behind-the-scenes content.</li>
              <li>Networking: Connect with fellow Alpha Invitation holders.</li>
              <li>Recognition: Gain prominence as a key contributor.</li>
            </ol>
            <br /><br />
            Seize this unique opportunity for unmatched benefits – grab your Alpha Invitation now!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Let's go
          </Button>
        </DialogActions>
      </Dialog>
      {isMobile ? (
        <><Paper elevation={3} className={classes.paperMobile}>
          <div className={classes.overlay}></div>
          <Collapse in={alertInfo !== null}>
            <Alert
              variant="outlined"
              severity={alertInfo?.severity || "info"}
              onClose={handleCloseAlert}
            >
              <AlertTitle>{alertInfo?.severity === "error" ? "Error" : "Success"}</AlertTitle>
              {alertInfo?.message}
            </Alert>
          </Collapse>
          <Typography variant="h5" className={classes.title}>
            Welcome to the Hyena Invitation Launchpad!
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Get ready to secure your spot in the exclusive Hyena Invitation NFT collection. Only 350 unique Pass available!
          </Typography>
          <img src="preview.gif" alt="GIF" className={classes.gif} />
          <Grid container spacing={2} justifyContent="center">
            {/*
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" color="textSecondary" className={classes.titleCount}>Minting starts in:</Typography>
      <CountdownTimer targetDate={hyenaMintStartAt} />
     </div>
     */}
            <Typography variant="h4" className={classes.soldOutText}>
              Sold Out
            </Typography>
          </Grid>
        </Paper>
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Box className={classes.boxSupply}>
                  <Typography variant="h3" className={classes.catchyTextSupply} style={{ marginRight: 20 }}>350</Typography> <Typography variant="h3" className={classes.catchyTextSupply} style={{ marginRight: 10 }}>/</Typography><Typography variant="h3" className={classes.catchyTextSupply} style={{ marginLeft: 10 }}> 350</Typography>
                </Box>
                <Typography variant="h5" className={classes.title} style={{ marginTop: 50 }}>
                  My Pass:
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                {tokenCards}
              </Grid>
            </Grid>
          </Paper></>
      ) : (
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper elevation={3} className={classes.paper}>
                <div className={classes.overlay}></div>
                <Collapse in={alertInfo !== null}>
                  <Alert
                    variant="outlined"
                    severity={alertInfo?.severity || "info"}
                    onClose={handleCloseAlert}
                  >
                    <AlertTitle>{alertInfo?.severity === "error" ? "Error" : "Success"}</AlertTitle>
                    {alertInfo?.message}
                  </Alert>
                </Collapse>
                <Typography variant="h5" className={classes.title}>
                  Welcome to the Hyena Invitation Launchpad!
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  Get ready to secure your spot in the exclusive Hyena Invitation NFT collection. Only 350 unique Pass available!
                </Typography>
                {/*
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" color="textSecondary" className={classes.titleCount}>Minting starts in:</Typography>
                  <CountdownTimer targetDate={hyenaMintStartAt} />
                </div>
                */}
                <Typography variant="h4" className={classes.soldOutTextDesktop} style={{ width: '100%' }}>
                  Sold Out
                </Typography>
                <img src="preview.gif" alt="GIF" className={classes.gif} />
                <Box className={classes.boxSupply}>
                  <Typography variant="h3" className={classes.catchyTextSupply} style={{ marginRight: 20 }}> {context.hyenaMinted}</Typography> <Typography variant="h3" className={classes.catchyTextSupply} style={{ marginRight: 10 }}>/</Typography><Typography variant="h3" className={classes.catchyTextSupply} style={{ marginLeft: 10 }}> 350</Typography>
                </Box>
                <Grid container spacing={2} justifyContent="center" style={{ alignItems: 'center', marginBottom: 100 }}>
                  <Grid item>
                    <Button variant="contained" disabled className={classes.button} onClick={handleClickOpenDialog}>
                      Details
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" disabled onClick={mintHandler} className={classes.button} >
                      Mint
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6} justifyContent="center" style={{ alignItems: 'center' }}>
              <Paper elevation={3} className={classes.myPassSection}>
                <Typography variant="h5" className={classes.title} style={{ marginTop: 50 }}>
                  My Pass:
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                  {tokenCards}
                </div>
              </Paper>
            </Grid>
            <Grid />
          </Grid> {/* Questo è il tag di chiusura della colonna */}
        </div>
      )
      }
    </div >
  );
}

export default Launchpad;
