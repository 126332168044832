/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup, List, ListItem, ListItemText, Grid, Stack, IconButton, createTheme, styled, Badge } from '@mui/material';
import { BadgeProps, Card, CardContent, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Paper, Slide, Typography, useMediaQuery } from '@material-ui/core';
import { TextField, makeStyles } from '@material-ui/core';
import EtherHelper from '../../ethers/EtherHelper';
import { EtherContext } from '../../ethers/EtherContext';
import { EtherContextRepository } from '../../ethers/EtherContextRepository';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { TransitionProps } from '@material-ui/core/transitions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import lottery from '../../common/lottery.jpg'
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PetsIcon from '@mui/icons-material/Pets';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const deployer = '0xC1ec8665C40B8cAB988C3E126d96d28Bbcdd550a'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const CountdownTimer: React.FC<{ lastOpenTimestamp: Date; maxTime: number }> = ({ lastOpenTimestamp, maxTime }) => {
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  useEffect(() => {
    // Calcola la data di scadenza aggiungendo maxTime in secondi a lastOpenTimestamp
    const expirationTime = new Date(lastOpenTimestamp.getTime() + maxTime * 1000);
    setExpirationDate(expirationTime);

    const interval = setInterval(() => {
      // Calcola il tempo rimanente in secondi rispetto all'ora di scadenza
      const currentTime = new Date().getTime();
      const timeDifference = Math.max(expirationTime.getTime() - currentTime, 0);

      // Se il tempo rimanente è zero, cancella l'intervallo
      if (timeDifference === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [lastOpenTimestamp, maxTime]);

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    return date.toLocaleString(undefined, options);
  };

  return (
    <div>
      {expirationDate
        ?
        <Button
          style={{ color: '#1976d2', border: '2px solid #1976d2' }}
          variant='outlined'
          color="secondary"
        >
          {formatDate(expirationDate)}
        </Button>
        : null
      }
    </div>
  );
};



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    backgroundImage: "url(https://img.freepik.com/free-vector/summer-landscape-with-moon-sky-night_107791-9394.jpg?w=1060&t=st=1693411349~exp=1693411949~hmac=834c857dbac3df4343386dd21b23794cc3749eaff3944f91b605da2388b7e938)",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minWidth: '100%',
    height: '100%',
    marginLeft: 0,
    marginRight: 0,
    alignItems: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
      backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
      zIndex: -1,
    },
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
    backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
    zIndex: 0,
  },
  button: {
    marginRight: theme.spacing(2),
    color: 'white',
    background: 'rgba(17, 17, 17, 0.2)',
    border: '2px solid transparent',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(0),
    borderRadius: theme.spacing(2),
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      background: '#FF8053',
      color: 'white',
    },
  },
  gradient: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    margin: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    marginBottom: theme.spacing(4),
    color: 'white'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(17, 17, 17, 0.1)',
    marginTop: 10,
    backdropFilter: 'blur(10px)',
    border: '2px solid transparent',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
    borderRadiusTopRight: 100,
    borderRadiusTopLeft: 100,
  },
  card: {
    width: '100%',
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0)', // Sfondo semi-trasparente
    marginBottom: theme.spacing(2),
    backdropFilter: 'blur(10px)', // Effetto di sfocatura
    border: '2px solid transparent',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
    borderRadiusTopRight: 100,
    borderRadiusTopLeft: 100,
  },
  chip: {
    margin: 7,
    gap: 10,
    backgroundColor: '#9c27b0',
    color: 'white',
    fontWeight: 'bold', // Aggiunto il grassetto
    fontFamily: 'Roboto',
    fontSize: 14
  },
  paper2: {
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    marginTop: 10,
    backdropFilter: 'blur(10px)',
    border: '2px solid transparent',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
    borderRadiusTopRight: 100,
    borderRadiusTopLeft: 100,
  },
  paperItem: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(10px)',
    border: '2px solid transparent',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontFamily: 'Rubik Wet Paint',
    fontSize: 20,
  },
  sectionTitle2: {
    marginBottom: theme.spacing(2),
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontFamily: 'Rubik Wet Paint',
    fontSize: 32,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const theme = createTheme({
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  flexGrow: 1,
}));

interface NumberInputProps {
  value: number;
  onChange: (newValue: number) => void;
}

const NumberInputWithButtons: React.FC<NumberInputProps> = ({ value, onChange }) => {
  const increment = () => {
    onChange(value + 1);
  };

  const decrement = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  return (
    <ButtonGroup style={{ marginBottom: 20 }}>
      <Button color="secondary" onClick={decrement}>—</Button>
      <TextField
        label='Buy Tickets'
        value={value}
        variant="filled"
        color="secondary"
        style={{
          width: '100%',
          backgroundColor: 'rgba(255,255,255, 0.3)',       // White background
          border: '2px solid #7b1fa2',
          borderLeft: 'none',
          borderRight: 'none',
          borderRadius: 10,
          paddingLeft: 0,                 // Add some padding for better appearance
          paddingRight: 0,
        }}
        type="number"
        inputProps={{ min: 0 }}
        onChange={(e) => onChange(parseFloat(e.target.value))}
      />
      <Button color="secondary" onClick={increment}>+</Button>
    </ButtonGroup>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Lottery: React.FC = () => {
  const [winner, setWinner] = useState<string | null>(null);
  const [poolHistory, setPoolHistory] = useState<any[]>([]);
  const { context, saveContext } = React.useContext(EtherContext) as EtherContextRepository;
  const classes = useStyles();
  const [poolId, setPoolId] = useState(0);
  const [poolData, setPoolData] = useState({
    onlyHolders: true,
    maxTickets: 0,
    ticketPrice: 0,
    winnerPerc: 0,
    maxTime: 0,
  });
  const [poolDataInput, setPoolDataInp] = useState({
    onlyHolders: true,
    maxTickets: 0,
    ticketPrice: 0,
    winnerPerc: 0,
    maxTime: 0,
  });
  const [onlyHolders, setOnlyHolders] = useState<boolean>(true);
  const [maxTickets, setMaxTickets] = useState<number>(0);
  const [ticketPrice, setTicketPrice] = useState<number>(0);
  const [winnerPerc, setWinnerPerc] = useState<number>(0);
  const [maxTime, setMaxTime] = useState<number>(0);

  const [ticket, setTicketBuy] = useState<number>(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [enabledPools, setEnabledPools] = React.useState<number[]>([])
  const [openAlert, setOpenAlert] = React.useState(false);
  const [countdownTime, setCountdownTime] = useState<number>(0);
  const [inputValue, setInputValue] = useState('');
  const [inputValueId, setInputValueId] = useState('');
  const [poolIds, setPoolIds] = useState<number[]>([]);
  const isMobile = useMediaQuery('(max-width:960px)');
  const [open, setOpen] = React.useState(false);
  const [alertType, setAlertType] = useState('success'); // 'success' o 'warning'

  const handleAlertClose = () => {
    setOpen(false);
  };

  const handleAddPoolIds = () => {
    if (inputValueId !== '') {
      const newPoolIds = inputValueId
        .split(',')
        .map(id => Number(id.trim()))
        .filter(id => !isNaN(id));
      setPoolIds(newPoolIds);
      setActivePools(newPoolIds)
      setInputValueId('');
    }
  };

  console.log(poolIds)

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getActivePools()
    getLottery()
    getPool1();
    getPool2();
    //getPool3();
    getWinner();
    getPoolHistory();
    if (context.connected === true) {
      getActivePools()
      getLottery()
      getPool1();
      getPool2();
      //getPool3();
      getWinner();
      getPoolHistory();
    }
    if (context.maxTime && context.lastOpenTimestamp) {
      const currentTime = Math.floor(Date.now() / 1000);
      const endTime = (context.lastOpenTimestamp.getTime() / 1000) + context.maxTime;
      setCountdownTime(Math.max(endTime - currentTime, 0));
    }
  }, []);

  const buyTicket = () => {
    EtherHelper.buyTickets(poolId, ticket, context.ticketPrice ?? 0, context)
      .then((tx: any) => {
        if (tx) {
          setOpen(true);
          setAlertType('success');
        }
      })
      .catch((error) => {
        console.log("Error during buy ticket: ", error);
        if (error.message === "MetaMask - RPC Error: Internal JSON-RPC error.") {
          setOpen(true);
          setAlertType('warning');
        } else {
          setOpen(true);
          setAlertType('error'); // Mostra un alert di errore generico
        }
      });
  };

  const setActivePools = async (poolIds: number[]) => {
    try {
      const set_active_pools = await EtherHelper.setEnabledPools(poolIds, context)
    } catch (e) {
      console.error('Error getting active pools:', e);
    }
  }

  const getActivePools = async () => {
    try {
      const active_pools = await EtherHelper.getEnabledPools(context).then((pools) => {
        setEnabledPools(pools.map(bn => bn.toNumber()))
      })
    } catch (e) {
      console.error('Error getting active pools:', e);
    }
  }

  const resetAndCloseLottery = async () => {
    try {
      const resetted = await EtherHelper.resetAndCloseLottery(poolId, context).then(
        () => {
          getPool1();
          getPool2();
        }
      )
      console.log(resetted)
    } catch (e) {
      console.error('Error resetting pool:', e);
    }
  }

  const getWinner = async () => {
    try {
      const lottery = await EtherHelper.getActualLottery(poolId, context);
      setWinner(lottery.recentWinner ?? 'No Winner Yet');
      console.log(lottery)
    } catch (error) {
      console.error('Error getting winner:', error);
    }
  };

  const getLottery = async () => {
    try {
      const pool = await EtherHelper.Lottery(poolId ?? 0, context);
      console.log("Lottery", pool);
    } catch (error) {
      console.error('Error getting lottery:', error);
    }
  };

  const getPool1 = async () => {
    try {
      setPoolId(0)
      const pool = await EtherHelper.getPool(0, context);
      console.log(pool);
      if (pool) {
        setPoolData({
          onlyHolders: pool.onlyHolders ?? true,
          maxTickets: pool.maxTickets ?? 0,
          ticketPrice: pool.ticketPrice ?? 0,
          winnerPerc: pool.winnerPerc ?? 0,
          maxTime: pool.maxTime ?? 0,
        });
      }
      const selectedPool = await EtherHelper.Lottery(0, context).then(
        (ctx) => {
          saveContext(ctx)
        }
      )
    } catch (error) {
      console.error('Error getting pool:', error);
    }
    try {
      const pool = await EtherHelper.getPool(0, context);
      console.log(pool);
      if (pool) {
        setPoolData({
          onlyHolders: pool.onlyHolders ?? true,
          maxTickets: pool.maxTickets ?? 0,
          ticketPrice: pool.ticketPrice ?? 0,
          winnerPerc: pool.winnerPerc ?? 0,
          maxTime: pool.maxTime ?? 0,
        });
      }
      const selectedPool = await EtherHelper.Lottery(0, context).then(
        (ctx) => {
          saveContext(ctx)
        }
      )
    } catch (error) {
      console.error('Error getting pool:', error);
    }
  };
  const getPool2 = async () => {
    try {
      setPoolId(1)
      const pool = await EtherHelper.getPool(1, context);
      console.log(pool);
      if (pool) {
        setPoolData({
          onlyHolders: pool.onlyHolders ?? true,
          maxTickets: pool.maxTickets ?? 0,
          ticketPrice: pool.ticketPrice ?? 0,
          winnerPerc: pool.winnerPerc ?? 0,
          maxTime: pool.maxTime ?? 0,
        });
      }
      const selectedPool = await EtherHelper.Lottery(1, context).then(
        (ctx) => {
          saveContext(ctx)
        }
      )
    } catch (error) {
      console.error('Error getting pool:', error);
    }
    try {
      const pool = await EtherHelper.getPool(1, context);
      console.log(pool);
      if (pool) {
        setPoolData({
          onlyHolders: pool.onlyHolders ?? true,
          maxTickets: pool.maxTickets ?? 0,
          ticketPrice: pool.ticketPrice ?? 0,
          winnerPerc: pool.winnerPerc ?? 0,
          maxTime: pool.maxTime ?? 0,
        });
      }
      const selectedPool = await EtherHelper.Lottery(1, context).then(
        (ctx) => {
          saveContext(ctx)
        }
      )
    } catch (error) {
      console.error('Error getting pool:', error);
    }
  };
  const getPool3 = async () => {
    setPoolId(2)
    try {
      const pool = await EtherHelper.getPool(2, context);
      console.log(pool);
      if (pool) {
        setPoolData({
          onlyHolders: pool.onlyHolders ?? true,
          maxTickets: pool.maxTickets ?? 0,
          ticketPrice: pool.ticketPrice ?? 0,
          winnerPerc: pool.winnerPerc ?? 0,
          maxTime: pool.maxTime ?? 0,
        });
      }
      const selectedPool = await EtherHelper.Lottery(2, context).then(
        (ctx) => {
          saveContext(ctx)
        }
      )
    } catch (error) {
      console.error('Error getting pool:', error);
    }
    try {
      const pool = await EtherHelper.getPool(2, context);
      console.log(pool);
      if (pool) {
        setPoolData({
          onlyHolders: pool.onlyHolders ?? true,
          maxTickets: pool.maxTickets ?? 0,
          ticketPrice: pool.ticketPrice ?? 0,
          winnerPerc: pool.winnerPerc ?? 0,
          maxTime: pool.maxTime ?? 0,
        });
      }
      const selectedPool = await EtherHelper.Lottery(2, context).then(
        (ctx) => {
          saveContext(ctx)
        }
      )
    } catch (error) {
      console.error('Error getting pool:', error);
    }
  };

  const getPoolHistory = async () => {
    try {
      const history = await EtherHelper.getPoolHistory(context.poolId ?? '', context);
      setPoolHistory(history);
      console.log(history)
    } catch (error) {
      console.error('Error getting pool history:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPoolData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChangeInp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPoolDataInp({
      onlyHolders: onlyHolders,
      maxTickets: maxTickets,
      ticketPrice: ticketPrice,
      winnerPerc: winnerPerc,
      maxTime: maxTime,
    });
  };

  const handleSetPool = async () => {
    try {
      await EtherHelper.setPool(poolId, { onlyHolders, maxTickets, ticketPrice, winnerPerc, maxTime }, context)
      setMaxTickets(0)
      setMaxTime(0)
      setTicketPrice(0)
      setWinnerPerc(0)
      setOnlyHolders(false)
      console.log('Pool set successfully!');
    } catch (error) {
      console.error('Error setting pool:', error);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.overlay} />
      <Snackbar open={open} autoHideDuration={6000} style={{ marginBottom: 50 }} onClose={handleAlertClose}>
        <MuiAlert
          onClose={handleAlertClose}
          severity={alertType === 'success' ? 'success' : 'warning'}
          elevation={6}
          variant="filled"
        >
          {alertType === 'success' ? 'Successfully!' : 'Error: check your balance or lottery status.'}
        </MuiAlert>
      </Snackbar>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Lottery Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="body1" paragraph>
              Only Holders: {poolData.onlyHolders ? 'Yes' : 'No'}
            </Typography>
            <Typography variant="body1" paragraph>
              Max Tickets: {poolData.maxTickets}
            </Typography>
            <Typography variant="body1" paragraph>
              Ticket Price: {poolData.ticketPrice}
            </Typography>
            <Typography variant="body1" paragraph>
              Winner Percentage: {poolData.winnerPerc}%
            </Typography>
            <Typography variant="body1" paragraph>
              Lottery Active: {context.isLotteryActive ? 'Yes' : 'No'}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Let's go
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3} style={{ marginTop: 20 }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ minHeight: 650 }} className={classes.paper}>
            <Alert severity="warning">REMEMBER: Choose the lottery from the panel below </Alert>
            <div className={classes.inputContainer}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10 }}>
                <Button
                  style={{ color: '#1976d2', border: '2px solid #1976d2' }}
                  variant='text'
                >{context.isLotteryActive ? 'Active' : 'Not Active'}</Button>
                {context.maxTime && context.lastOpenTimestamp && (
                  <CountdownTimer lastOpenTimestamp={context.lastOpenTimestamp} maxTime={context.maxTime} />
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10, marginTop: 20, marginBottom: 20 }}>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={getPool1}
                >
                  Lottery 1
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={getPool2}
                >
                  Lottery 2
                </Button>
                <div></div>
                {!isMobile && (
                  <div >
                    <Button variant="outlined" color="secondary" onClick={handleClickOpenDialog}>
                      Details
                    </Button>
                  </div>
                )}
              </div>
              <Card className={classes.card}>
                <CardContent>
                </CardContent>
              </Card>
              <Paper className={classes.card} style={{ display: 'flex', flexDirection: 'column' }}>
                <Chip
                  label={`LOTTERY: ${poolId + 1}`}
                  className={classes.chip}
                />
                <Chip
                  label={`PLAYERS: ${context.players?.length ?? ''}`}
                  color="secondary"
                  className={classes.chip}
                />
                <Chip
                  label={`PRICE: ${context.ticketPrice ?? '' + ' CRO'}`}
                  color="secondary"
                  className={classes.chip}
                />
                <Chip
                  label={`TICKETS: ${context.players?.length + '/' + context.maxTickets}`}
                  color="secondary"
                  className={classes.chip}
                />
                <div style={{ height: 300, overflowY: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow >
                        <TableCell style={{ color: 'white' }}>Paused</TableCell>
                        <TableCell style={{ color: 'white' }} >Lottery</TableCell>
                        <TableCell style={{ color: 'white' }} >Players</TableCell>
                        <TableCell style={{ color: 'white' }} >Opened</TableCell>
                        <TableCell style={{ color: 'white' }} >Last Win</TableCell>
                        <TableCell style={{ color: 'white' }} >Winner</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {context.connected === true && context.history ? (
                        context.history
                          .sort((a, b) => (b.lastOpenTimestamp?.getTime() || 0) - (a.lastOpenTimestamp?.getTime() || 0))
                          .map((lottery, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ color: 'white' }}>{lottery.paused ? 'Yes' : 'No'}</TableCell>
                              <TableCell style={{ color: 'white' }}>{poolId + 1}</TableCell>
                              <TableCell style={{ color: 'white' }}>
                                {lottery.players?.length}
                              </TableCell>
                              <TableCell style={{ color: 'white' }}>
                                {lottery.lastOpenTimestamp ? lottery.lastOpenTimestamp.toLocaleString() : ''}
                              </TableCell>
                              <TableCell style={{ color: 'white' }}>
                                {lottery.lastWinTimestamp && lottery.lastWinTimestamp.getTime() === 0
                                  ? 'No winner yet'
                                  : lottery.lastWinTimestamp
                                    ? lottery.lastWinTimestamp.toLocaleString()
                                    : ''}
                              </TableCell>
                              <TableCell style={{ color: 'white' }}>{lottery.recentWinner?.slice(0, 5) + '...' + lottery.recentWinner?.slice(-4)}</TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell style={{ color: 'white' }} colSpan={7}>No history available</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper} >
            <Typography variant="h6" gutterBottom className={classes.sectionTitle2}>
              Lottery {poolId + 1}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, marginTop: 20, marginBottom: 20, justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={getPool1}
              >
                Lottery 1
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={getPool2}
              >
                Lottery 2
              </Button>
            </div>
            <Stack style={{ marginTop: 20 }} spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
              <NumberInputWithButtons
                value={ticket}
                onChange={(newValue) => {
                  if (newValue >= 0) {
                    setTicketBuy(newValue);
                  }
                }}
              />
            </Stack>
            <Button variant='outlined' color='primary' onClick={buyTicket} style={{ marginBottom: 20, fontWeight: 'bold', color: 'white', border: '2px solid #1976d2' }}>
              <IconButton aria-label="cart" >
                <StyledBadge badgeContent={ticket} color="primary">
                  <ShoppingCartIcon color="primary" />
                </StyledBadge>
              </IconButton>
              <div style={{ marginLeft: 10 }}>Buy Ticket {ticket > 0 ? ` || ${ticket * (context.ticketPrice ?? 0)} CRO` : ""}</div>
            </Button>
            <Stack style={{ marginTop: 50, alignItems: 'center' }} spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
              <Grid container style={{maxWidth: '360px'}} spacing={{ xs: 1, sm: 2 }}>
                <Grid item xs={6} md={6}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <AccountCircleIcon fontSize='large' color='secondary' />
                    <div style={{ color: 'white', marginTop: 7, marginLeft: 5 }}>{context.addressSigner ? context.addressSigner?.slice(0, 5) ?? '' + '...' + context.addressSigner?.slice(-4) ?? '' : ''}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <ConfirmationNumberIcon fontSize='large' color='secondary' />
                    <div style={{ color: 'white', marginTop: 7, marginLeft: 5 }}>
                    {
                  context && (
                    <div>
                      {
                        context.players?.reduce((acc, player) => {
                          if (player.toLowerCase() === context.addressSigner?.toLowerCase()) {
                            return acc + 1;
                          } else {
                            return acc;
                          }
                        }, 0)
                      }
                      <div>
                      </div>
                    </div>
                  )
                }
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <AttachMoneyIcon fontSize='large' color='secondary' />
                    <div style={{ color: 'white', marginTop: 7, marginLeft: 5 }}>
                      {context.balance ? context.balance?.toFixed(2) : ''} 
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <PetsIcon fontSize='large' color='secondary' />
                    <div style={{ color: 'white', marginTop: 7, marginLeft: 5 }}>
                    {context.hyenaTokenIds ? context.hyenaTokenIds.length : ''}
                    </div>
                  </div>
                </Grid>

              </Grid>
             
              <Button
                variant="outlined"
                color="secondary"
                onClick={resetAndCloseLottery}
                style={{ marginTop: 50 }}
              ><LockResetRoundedIcon style={{ marginRight: 5 }} />  Reset & Close Lottery Pool</Button>
              <Button style={{ marginBottom: 10 }} variant="outlined" onClick={getPoolHistory} className={classes.button}>
                <ManageSearchRoundedIcon style={{ marginRight: 5 }} />Get Pool History
              </Button>
            </Stack>
          </Paper>
        </Grid>
        <div style={{ marginBottom: 100, height: 100 }}></div>
        {context.addressSigner?.toLowerCase() && context.addressSigner?.toLowerCase() === deployer.toLowerCase() && (
          <Grid item xs={12} md={12}>
            <Grid container spacing={3} style={{ marginTop: 20, marginBottom: 80 }}>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper2} style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    className={classes.textField}
                    label="Pool ID"
                    type="number"
                    value={poolId}
                    onChange={(e) => setPoolId(parseFloat(e.target.value))}
                  />
                  <FormControlLabel control={
                    <Checkbox
                      checked={onlyHolders}
                      onChange={(e) => setOnlyHolders((e.target as HTMLInputElement).checked)}
                    />}
                    label="Only Holders" />
                  <TextField
                    className={classes.textField}
                    label="Max Tickets"
                    type="number"
                    name="maxTickets"
                    value={maxTickets}
                    onChange={(e) => setMaxTickets(parseFloat(e.target.value))}
                  />
                  <TextField
                    className={classes.textField}
                    label="Ticket Price"
                    type="number"
                    name="ticketPrice"
                    value={ticketPrice}
                    onChange={(e) => setTicketPrice(parseFloat(e.target.value))}
                  />
                  <TextField
                    className={classes.textField}
                    label="Winner Percentage"
                    type="number"
                    name="winnerPerc"
                    inputProps={{ min: 0 }}
                    value={winnerPerc}
                    onChange={(e) => setWinnerPerc(Number(e.target.value))}
                  />
                  <TextField
                    className={classes.textField}
                    label="Max Time"
                    type="number"
                    name="maxTime"
                    value={maxTime}
                    onChange={(e) => setMaxTime(parseFloat(e.target.value))}
                  />
                  <Button variant="contained" color="primary" onClick={handleSetPool}>
                    Set Pool
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <div>
                    <TextField
                      label="Pool IDs (use comma)"
                      value={inputValueId}
                      onChange={(e) => setInputValueId(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddPoolIds}>
                      Add Pool IDs
                    </Button>
                  </div>
                  <Divider />
                  <Button style={{ marginTop: 10 }} variant="outlined" onClick={getActivePools}>Get Enabled Pools</Button>
                  <Typography style={{ marginTop: 10, marginBottom: 10 }}>{enabledPools.join('—') ?? 0}</Typography>
                  <Divider />
                  <Button variant="outlined" onClick={getWinner} style={{ marginTop: 30 }} className={classes.button}>
                    Get Winner
                  </Button>
                  <Typography style={{ marginBottom: 10, marginTop: 10 }}>{winner ?? 'No winner yet'} — Pool Id: {poolId}</Typography>
                  <Divider />
                  <Button variant="outlined" onClick={resetAndCloseLottery} style={{ marginTop: 30 }} className={classes.button}>
                    Reset & Close Lottery
                  </Button>
                  <TextField
                    className={classes.textField}
                    style={{ marginTop: 10 }}
                    label="Pool ID"
                    type="number"
                    value={poolId}
                    onChange={(e) => setPoolId(parseFloat(e.target.value))}
                  />
                  <Divider variant="middle" />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
        <div style={{ height: 100 }}></div>
      </Grid>
    </div>
  );
};

export default Lottery;
