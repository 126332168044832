import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Card, CardHeader, CardContent, Avatar, IconButton, Grid, CardActions, Collapse } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TwitterIcon from '@material-ui/icons/Twitter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        flexGrow: 1,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minWidth: '100%',
        height: '100%',
        backgroundImage: 'url("bghome.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: -1,
        },
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: 'white'
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 'auto',
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
        zIndex: 0,
    },
    paper: {
        display: 'flex',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: 'rgba(17, 17, 17, 0.7)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    title: {
        marginBottom: theme.spacing(2),
        fontSize: 24,
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontWeight: 'bold',
        fontFamily: 'Rubik Wet Paint',
    },
    description: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
    },
    descriptionTitle: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        fontSize: 24,
    },
    descriptionTitleSub: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        color: 'white',
        fontSize: 14,
    },
    card: {
        maxWidth: 345,
        backgroundColor: 'black',
        border: '2px solid white',
        borderRadius: 50,
        marginBottom: 30,
    },
    cardContent: {
        color: 'white',
    },
    avatar: {
        backgroundColor: 'transparent',
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    gridItem: {
        padding: theme.spacing(2),
    },
}));

const Team: React.FC = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<number | false>(false);

    const handleExpandClick = (index: number) => {
        setExpanded(expanded === index ? false : index);
    };

    const teamMembers = [
        {
            name: 'KVRC',
            role: 'Founder & CEO',
            image: 'Hye.jpg',
            description: "Cronos DeFi luminary, blending community and project management skills, elevating fellow Crofam endeavors. A dedicated 25-year-old, here daily to radiate support. Passion ignited post-high school, steering me towards lasting pursuits in management and animal care. Pioneered as the youngest Serbian team leader in a Slovakian factory, enriched by IT Management Academy. Crypto and Cronos seized my focus. Committed to crafting a haven for learning, earning, and delight within HH."
        },
        {
            name: 'Steve Seaclod',
            role: 'CLO',
            image: 'pass1.png',
            description: "Introducing Steve, a vibrant 40-year-old fueled by an unquenchable tech curiosity. With a year in the crypto trenches, he's no stranger to digital currencies. What makes him shine? Merging tech obsession with social flair. Steve's not just about codes; he's a social butterfly. From crypto meetups to tech-fueled mingling, he's a maestro of positive vibes and unforgettable connections."
        },
        {
            name: 'Wolf',
            role: 'CIO',
            image: 'pass3.png',
            description: "Wolf, a seasoned DeFi explorer and investor, boasts a formidable reputation across the community. Guided by an unwavering commitment to robust community dynamics and crystalline project trajectories. Scammers? They're on his blacklist. And the timeless query, ''Wen?'' Ever on his lips, even when the answer is his to grasp."
        },
        {
            name: 'Keepeeto',
            role: 'CTO & DEV',
            image: 'pass2.png',
            description: "I am Keepeeto, your go-to maestro of web3 React development, flaunting an impressive 2-year saga of crafting and shaping decentralized marvels. In the grand tapestry of blockchain, I, alongside my visionary partner Exit, orchestrated the inception of Ethercode, a testament to our unbridled ardor for this revolutionary technology. Together, we're not just pushing boundaries; we're the ones drawing the new maps."
        },
        {
            name: 'Exit',
            role: 'CTO & DEV',
            image: 'pass3.png',
            description: 'An unparalleled web3 Solidity virtuoso, tirelessly delving into the enigmatic realms of blockchain and decentralization. I proudly stand as a co-founder of Ethercode alongside my ingenious partner, Keepeet, where we sculpt avant-garde decentralized solutions that redefine industry standards.'
        },
        {
            name: 'Jovan Slijepcevic',
            role: 'Artist',
            image: 'pass1.png',
            description: "My name is Jovan Slijepcevic, and I am a passionate urban graphic designer with over three years of experience in the field. My journey in design began with personal projects and collaborations, where I honed my skills and developed a unique creative style. Over the years, I’ve had the privilege of working on a diverse range of projects, each one offering a new opportunity to push my creative boundaries. I believe that every design tells a story, and I strive to make that story compelling and engaging through my work."
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.overlay}></div>
            <Paper elevation={3} className={classes.paper}>
                <Grid container className={classes.gridContainer} spacing={2}>
                        <Typography variant="h5" className={classes.title}>
                            Meet the Team
                        </Typography>
                        {teamMembers.map((member, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index} className={classes.gridItem}>
                                <Card className={classes.card}>
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="team-member" className={classes.avatar}>
                                                <img src="preview.gif" style={{ width: '100%' }} />
                                            </Avatar>
                                        }
                                        action={
                                            <IconButton aria-label="settings">
                                                <MoreVertIcon color="secondary" />
                                            </IconButton>
                                        }
                                        title={<div className={classes.descriptionTitle}>{member.name}</div>}
                                        subheader={<div className={classes.descriptionTitleSub}>{member.role}</div>}
                                    />
                                    <img src={member.image} style={{ width: '50%', borderRadius: '50%' }} />
                                    <CardContent className={classes.cardContent}>
                                        <div className={classes.description}>
                                            {member.description.length > 150 ? (
                                                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                                    {member.description}
                                                </Collapse>
                                            ) : (
                                                member.description
                                            )}
                                        </div>
                                        {member.description.length > 150 && (
                                            <IconButton
                                                color="primary"
                                                className={classes.cardActions}
                                                onClick={() => handleExpandClick(index)}
                                                aria-expanded={expanded === index}
                                                aria-label={"Show More"}
                                            >
                                                <ExpandMoreIcon color="primary" />
                                            </IconButton>
                                        )}
                                    </CardContent>
                                    <CardActions className={`${classes.cardActions} ${classes.footer}`}>
                                        <TwitterIcon color="primary" />
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            </Paper>
        </div>
    );
}

export default Team;
