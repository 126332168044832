import React, { useEffect, useState } from 'react';
import { TextField, Button, CardContent, CardActions, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardHeader, Card, createStyles, makeStyles, Divider, ListItemAvatar, Avatar, CircularProgress } from '@material-ui/core';
import { FiTwitter } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import DiscordIcon from '@mui/icons-material/Forum';
import { PushNames, getMessages, getServerInfo } from '../bot/GET';
import { List, ListItem, Paper, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface CardData {
    title: string;
    description: string;
    socialLinks: string[];
    image: string;
    icon: React.ReactNode[];
    link: string[];
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        flexGrow: 1,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minWidth: '100%',
        height: '100%',
        backgroundImage: 'url("bghome.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: -1,
        },
    },
    divider: {
        backgroundColor: 'white', // Imposta il colore del Divider a bianco
        marginTop: 20,
        marginBottom: 20,
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
        zIndex: 0,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    chatBox: {
        overflowY: 'scroll',
        minHeight: 200,
        maxHeight: '400px', // Imposta l'altezza massima per il div scrollabile
        padding: '10px', // Spazio interno al div
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: '5px',
        color: 'white',
    },
    card: {
        position: 'relative',
        maxWidth: '100%',
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: "rgba(0,0,0, 0.8)",
        borderRadius: 50
    },

    banner: {
        height: 250, // Altezza del banner
        position: 'relative',
    },
    title: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%', // Posiziona il titolo al centro del banner
        left: '50%', // Posiziona il titolo al centro del banner
        transform: 'translate(-50%, -50%)', // Allinea il titolo al centro verticalmente e orizzontalmente
        marginBottom: theme.spacing(2),
        color: 'white',
        background: 'linear-gradient(45deg, rgba(254, 107, 139, 0.9) 50%, rgba(255, 142, 83, 0.8) 90%)',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 38,
        padding: '8px 16px',
        boxShadow: '0 0 10px black',
        borderRadius: 50 // Padding del titolo
    },
    title2: {
        textAlign: 'center',
        position: 'relative',
        top: '50%', // Posiziona il titolo al centro del banner
        left: '50%', // Posiziona il titolo al centro del banner
        transform: 'translate(-50%, -50%)', // Allinea il titolo al centro verticalmente e orizzontalmente
        marginBottom: theme.spacing(2),
        color: 'white',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 28,
        padding: '8px 16px',
        boxShadow: '0 0 10px black',
        borderRadius: 50, // Padding del titolo
    },
    description: {
        color: 'white',
        marginTop: 16,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 16
    },
    message: {
        marginBottom: theme.spacing(1),
    },
    timestamp: {
        fontSize: '1rem', // Dimensione del timestamp
        color: 'lightgray', // Colore del timestamp
    },
    socialLinks: {
        display: 'flex',
        justifyContent: 'center', // Allinea le icone al centro orizzontalmente
        marginTop: 16, // Spazio tra la descrizione e le icone social
    },
    socialIcon: {
        marginRight: 16, // Spazio tra le icone social
    },
}));

const AdminCardManager: React.FC = () => {
    const [cards, setCards] = useState<CardData[]>([]);
    const [serverIdToData, setServerIdToData] = useState<ServerData>({});
    const [res, setRes] = useState();

    const classes = useStyles()
    const initialCards: CardData[] = [
        {
            title: 'Crooks Finance',
            description: 'Crooks Finance is a DeFi project launched on the CRONOS Chain which provides its users with an automated high-yield cronos reward protocol which makes passive income easy, secure, and sustainable.',
            socialLinks: ['https://example.com/card1'],
            image: 'crooks.webp',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/crooksfinance', 'https://crooks.finance/', 'https://discord.gg/crooksfinance'],

        },
        {
            title: 'BOB Adventures',
            description: "BOB Adventures is a strong NFT community on #CronosChain, with multiple collections and utilities for BOB NFT holders. Partnered with well-known projects. Don't miss out and join us!",
            socialLinks: ['https://example.com/card2'],
            image: 'Bobz.png',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/CroBobAdventure', 'https://bob-adventures.netlify.app/', 'https://discord.gg/Zzt89SfY'],
        },
        {
            title: 'Steakhouse',
            description: "Are you looking for a juicy experience? Well look no further than The Cronos Steakhouse, Since 2022 they have been serving up the finest food Cronos has to offer, from Chefz to Staking they have it all!",
            socialLinks: ['https://example.com/card2'],
            image: 'Steakhouse.png',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/CronoSteakHouse', 'https://cronossteakhouse.com/', 'https://discord.gg/VDbdTpC7'],
        },
        {
            title: 'Crazzzy Monsters',
            description: "Introducing Crazzzy Monsters NFT on Cronos! Calling all crypto enthusiasts and monster lovers! The minting phase of the Crazzzy Monsters NFT collection on the Cronos network has concluded. Crazzzy Monsters are 10,000 hand-drawn NFTs, each unique in detail, with varying characteristics, colors, accessories, and backgrounds. From cute to bizarre, these monsters will captivate your imagination. Features: Exclusive Ownership, Vibrant Community, Limited Edition Drops, Royalties, Investment, Innovative Utilities.",
            socialLinks: ['https://example.com/card2'],
            image: 'crazym.png',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/CrazzzyMonsters', 'http://crazzzymonsters.com', 'https://discord.gg/MmBrcna4UM'],
        },
        {
            title: 'STONELAND',
            description: "Stoneland offers several projects which aim to give sustained, exponential gains through decentralized finance. CroCaveman: OG collection of 531 unique, randomly generated NFTs on the Cronos Network. Stoneland Survivors: A compilation of 10,000 distinctive NFTs spread across 5 blockchains, providing substantial passive income opportunities to holders.Stoneland Treasures: Featuring a biweekly lottery with substantial cash prizes.",
            socialLinks: ['https://example.com/card2'],
            image: 'stoneland.png',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/Stoneland___?t=gDAa_IHFVNPzorE30GJ1DQ&s=09', 'https://twitter.com/Stoneland___?t=gDAa_IHFVNPzorE30GJ1DQ&s=09', 'https://discord.gg/AnEAUp2yKX'],
        },
        {
            title: 'LIQUOR TOKEN',
            description: "More than a memecoin, more than NFTs, The finest blockchain alcohol is served with a 7% tax on every tipple | 3% - Marketing - 2% | $LIQUOR reflections for holders | 2% - Liquidity. The Liquor community can participate in several staking events : NFT and single token staking on cronos and polygon",
            socialLinks: ['https://example.com/card2'],
            image: 'liquor.png',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/LiquorTokenCro', 'https://liquor-token-cronos.com/', 'https://discord.gg/NqqFZsvMJE'],
        },
        {
            title: 'Nuclear Ape Squad',
            description: "Nuclear Ape Squad (NAS) immerses you in Arakun's world, where apes battle mutant bananas. As an NAS member, you shape the conflict's outcome and save the planet. Cutting-edge tech and web3 integration offer stunning art, a vibrant community, and engaging gameplay. Our avant-garde avatars have a unique forward-facing design, meticulously crafted with over 300 layers and rare gold items for exclusivity. Each of the 1000 pieces is one-of-a-kind, with intricate shadows and diverse elements.",
            socialLinks: ['https://example.com/card2'],
            image: 'NAS.png',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/nuclearapesquad', 'https://nuclearapesquad.com/', 'https://discord.gg/EWjDt94MTH'],
        },
        {
            title: 'Marble Verse',
            description: "The MarbleVerse champions embody creativity, design, and imagination. These Marbles are sleek, minimalist, and robust, built to race gracefully with unwavering determination. As their numbers grow, they symbolize purity, open-mindedness, creativity, untapped potential, and an enduring love for our childhoods in a contemporary aesthetic. Developed by Aden Games, known for mega-hit games like Gold Rush 3D! and Weapon Craft Run, MarbleVerse offers a nostalgic play-to-earn racing experience. Initially established in web2 games, the team expanded into the blockchain world, minting 20,000 NFTs on Cronos. MarbleVerse is now community-driven, evolving on Cronos and launching its second-generation mint on the Polygon Blockchain.",
            socialLinks: ['https://example.com/card2'],
            image: 'marble.png',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/marbleverse_io', 'https://www.marbleverse.io/', 'https://discord.com/invite/pfbMdQqYCX'],
        },
        {
            title: 'Candy Finance',
            description: "First community owned DEX in Defi. Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. 100% of fees collected distributed back to liquidity providers, holders and community members. Trade anything. No registration, no hassle.",
            socialLinks: ['https://example.com/card2'],
            image: 'candy.webp',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/BoredCandyCity', 'https://candycity.finance/?chain=cronos', 'https://discord.gg/boredcandycity'],
        },
        {
            title: 'AngelusBoBsaiNFTs',
            description: " AngelousBobsAiNFTs is an exciting project centered around the creation and release of unique digital artworks in the form of NFTs (Non-Fungible Tokens) on the blockchain. The project features captivating NFT series, including Fantasy Rainbow Creatures NFTs (March - April 2023), Alien Creatures NFTs, Demons N Gremlins NFTs, and Soul Fighters Phase 1 NFTs, offering a diverse and immersive experience for collectors and enthusiasts.",
            socialLinks: ['https://example.com/card2'],
            image: 'ANg.jpg',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/AngelusbobRh/status/1704207347919249782', 'https://corgistudio.io/collection/65116bd4070843251d9cdb65?tab=ONSALE', 'https://discord.gg/tM3gKXu5HA'],
        },
        {
            title: 'DooNFT',
            description: " The DooNFT team, led by ArtDoo, has conquered the world of NFTs with their CroDoo collection, which sold out in just 6 hours. As of September 16, 2023, they have distributed 746,000 Cro, achieved a floor price of over 300 Cro, and formed strategic partnerships, including GameFi Access. Anyone holding a CroDooNFT has seen an impressive ROI of 328% through royalties, with NFT valuations soaring by as much as 750%. Various income streams, such as launchpads, royalties, services, and partnerships, contribute to this remarkable success. The Doo team tirelessly works to expand the ecosystem.",
            socialLinks: ['https://example.com/card2'],
            image: 'doo.png',
            icon: [<TwitterIcon sx={{ color: 'white' }} />, <LanguageIcon sx={{ color: 'white' }} />, <DiscordIcon sx={{ color: 'white' }} />],
            link: ['https://twitter.com/TheDooNFT', 'https://doonft.com/', 'https://discord.gg/jWtZQmZGK5'],
        }
    ];

    const removeCard = (index: number) => {
        const updatedCards = [...cards];
        updatedCards.splice(index, 1);
        setCards(updatedCards);
    };

    const [resInfo, setResInfo] = useState<any>();
    const [messages, setMessages] = useState<any>('')

    interface ServerData {
        [key: string]: {
            messagesArray: any[]; // Puoi sostituire 'any' con un tipo specifico se lo conosci
            serverName: string;
        };
    }


    useEffect(() => {
        getServerInfo().then((res) => {
            setResInfo(res?.serverId);

            if (res?.serverId) {
                Promise.all(
                    res.serverId.map((serverName: any) =>
                        PushNames(serverName).then((res: any) => {
                            setServerIdToData((prevServerData) => ({
                                ...prevServerData,
                                [serverName]: res,
                            }));
                            console.log(`Messaggi da ${serverName}:`, res);
                            setRes(res)
                        })
                    )
                ).then(() => {
                    // Ora l'oggetto serverData è stato popolato con i dati dei server
                    console.log(serverIdToData);
                });
            }
        });
    }, []);



    // Dichiara una variabile di stato con il tipo definito

    if (serverIdToData) {
        console.log("data", serverIdToData["HyenaHustlers"], serverIdToData["HyenaHustlers"]?.serverName)
    }

    if (!serverIdToData) {
        getServerInfo().then((res) => {
            setResInfo(res?.serverId);

            if (res?.serverId) {
                Promise.all(
                    res.serverId.map((serverName: any) =>
                        PushNames(serverName).then((res: any) => {
                            setServerIdToData((prevServerData) => ({
                                ...prevServerData,
                                [serverName]: res,
                            }));
                            console.log(`Messaggi da ${serverName}:`, res);
                            setRes(res)
                        })
                    )
                ).then(() => {
                    // Ora l'oggetto serverData è stato popolato con i dati dei server
                    console.log(serverIdToData);
                });
            }
        });
    }


    return (
        <div className={classes.root}>
            <div className={classes.overlay}></div>
            <Card className={classes.card} style={{ margin: 20 }}>
                <CardContent className={classes.description}>
                    <div className={classes.title2}> DISCORD ANNOUNCEMENTS</div>
                    <div className={classes.chatBox}>
                        {serverIdToData["HyenaHustlers"]?.messagesArray ? (
                            <List>
                                {serverIdToData["HyenaHustlers"].messagesArray.map((message: any, index: any) => (
                                    <div key={index} className={classes.message}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                                            <ListItemAvatar >
                                                <Avatar style={{ marginTop: -10 }} alt="Avatar" src={"logo.png"} />
                                            </ListItemAvatar> HYENA HUSTLERS
                                        </div>
                                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                                            {message.content.split('\n').map((line: string, lineIndex: number) => (
                                                <React.Fragment key={lineIndex}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </Typography>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20, justifyContent: 'right', marginRight: 20 }}>
                                            <AccessTimeIcon style={{ marginTop: -3 }} />
                                            <Typography variant="caption" className={classes.timestamp}>
                                                {new Date(message.createdTimestamp).toLocaleString()}
                                            </Typography>
                                        </div>

                                        <Divider classes={{ root: classes.divider }} />
                                    </div>
                                ))}
                            </List>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                                <CircularProgress color="secondary" />
                            </div>)}
                    </div>
                </CardContent>
            </Card>
            <div style={{ marginTop: 50 }}>
                {initialCards.map((card, index) => {
                    // 1. Crea una variabile per il nome del partner corrente
                    const partnerName = card.title;

                    // 2. Verifica se esiste un oggetto corrispondente nel serverIdToData
                    const partnerData = serverIdToData[partnerName];

                    return (
                        <Card className={classes.card} style={{ margin: 20 }}>
                            <div className={classes.banner}>
                                <img src={card.image} alt={card.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                <div className={classes.title}>{card.title}</div>
                            </div>
                            <CardContent className={classes.description}>
                                <p>{card.description}</p>
                                {card.link.map((link, linkIndex) => (
                                    <a href={link} target="_blank" rel="noopener noreferrer" key={linkIndex}>
                                        <IconButton color='secondary'>
                                            {card.icon[linkIndex]} {/* Usa un'icona diversa per ciascun link */}
                                        </IconButton>
                                    </a>
                                ))}

                                {/* 3. Aggiungi il chatBox se esiste l'oggetto partnerData */}
                                {partnerData && (
                                    <div className={classes.chatBox}>
                                        {partnerData.messagesArray ? (
                                            <List>
                                                {partnerData.messagesArray.map((message, index) => (
                                                    <div key={index} className={classes.message}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                                                            <ListItemAvatar >
                                                                <Avatar style={{ marginTop: -10 }} alt="Avatar" src={card.image} />
                                                            </ListItemAvatar> {card.title}
                                                        </div>
                                                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                                                            {message && message.content.split('\n').map((line: string, lineIndex: number) => (
                                                                <React.Fragment key={lineIndex}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </Typography>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20, justifyContent: 'right', marginRight: 20 }}>
                                                            <AccessTimeIcon style={{ marginTop: -3 }} />
                                                            {message && (
                                                                <Typography variant="caption" className={classes.timestamp}>
                                                                    {new Date(message.createdTimestamp).toLocaleString()}
                                                                </Typography>
                                                            )}
                                                        </div>

                                                        <Divider classes={{ root: classes.divider }} />                                                    </div>
                                                ))}
                                            </List>
                                        ) : (
                                            <Typography variant="body1">{res ?? ''}</Typography>
                                        )}
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default AdminCardManager;
