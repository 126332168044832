import { EtherContext } from "../../ethers/EtherContext";
import EtherHelper from "../../ethers/EtherHelper";
import { EtherContextRepository } from "../../ethers/EtherContextRepository";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { AppBar, CircularProgress, Divider, Grid, Paper, Snackbar, TextField, Toolbar, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import { green, purple } from "@mui/material/colors";
import { BigNumber, ethers } from "ethers";
import { makeStyles } from "@material-ui/core";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { createFalse } from "typescript";
import { GridItem } from "@chakra-ui/react";
import { Stack } from "@mui/material";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        backgroundImage: "url(https://img.freepik.com/free-vector/summer-landscape-with-moon-sky-night_107791-9394.jpg?w=1060&t=st=1693411349~exp=1693411949~hmac=834c857dbac3df4343386dd21b23794cc3749eaff3944f91b605da2388b7e938)",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        position: 'relative',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minWidth: '100%',
        height: '100%',
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'center',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
            zIndex: -1,
        },
    },
    appBar: {
        marginBottom: theme.spacing(3),
        width: '100%',
        top: '7.5%',
        color: 'white', zIndex: theme.zIndex.drawer + 1,
        background: 'rgba(17, 17, 17, 0.1)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 1',
        height: 56,
        alignItems: 'center'
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        backdropFilter: 'blur(10px)', // Backdrop filter for blurring the background
        zIndex: 0,
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'rgba(17, 17, 17, 0.2)',
        marginTop: 10,
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    card: {
        width: '100%',
        color: 'white',
        backgroundColor: 'rgba(255, 255, 255, 0)', // Sfondo semi-trasparente
        marginBottom: theme.spacing(2),
        backdropFilter: 'blur(10px)', // Effetto di sfocatura
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    chip: {
        margin: 7,
        gap: 10,
        backgroundColor: '#9c27b0',
        color: 'white',
        fontWeight: 'bold', // Aggiunto il grassetto
        fontFamily: 'Roboto',
        fontSize: 14
    },
    paper2: {
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        marginTop: 10,
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
        borderRadiusTopRight: 100,
        borderRadiusTopLeft: 100,
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(10px)',
        border: '2px solid transparent',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) 0',
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 20,
    },
    sectionTitleRF: {
        marginBottom: theme.spacing(10),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 30,
    },
    sectionTitleRF1: {
        marginBottom: theme.spacing(2),
        fontFamily: 'Rubik Wet Paint',
        fontSize: 29,
        color: 'white'
    },
    sectionTitle2: {
        marginBottom: theme.spacing(2),
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontFamily: 'Rubik Wet Paint',
        fontSize: 32,
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(3),
    },
    navButton: {
        marginRight: theme.spacing(2),
        color: 'white',
        background: 'rgba(17, 17, 17, 0.2)',
        border: '2px solid transparent',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        marginLeft: theme.spacing(0),
        borderRadius: theme.spacing(2),
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
            background: '#FF8053',
            color: 'white',
        },
    },
}));

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Coinflip: React.FC = () => {
    const { context, saveContext } = React.useContext(
        EtherContext
    ) as EtherContextRepository;
    const [enabledBets, setEnabledBets] = useState<any>([]);
    const [userResult, setUserResult] = useState<any>(null);
    const classes = useStyles();
    const [loading, setLoading] = useState(true); // Stato per gestire il caricamento dei dati
    const [selectedSide, setSelectedSide] = useState<boolean | null>(null);
    const [betAmount, setBetAmount] = useState<number | null>(null);
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('success'); // 'success' o 'warning'
    const [open, setOpen] = React.useState(false);
    const [winner, setRecentWinner] = useState('')
    const [amount, setAmount] = useState<number>(0);
    const [card, setCard] = useState()
    const [loadingRes, setLoadingRes] = useState(true); // Stato per gestire il caricamento dei dati
    const [balance, setBalance] = useState();

    useEffect(() => {
        getEnabledBets();
        VaultBalance();
    }, [context]);

    const handleAlertClose = () => {
        setOpen(false);
    };

    const handleSelectSide = (side: boolean) => {
        setSelectedSide(side);
    };

    const handleBetAmount = (amount: number) => {
        setBetAmount(amount);
    };


    const getUserResult = async () => {
        try {
            setLoadingRes(true)
            const result = await EtherHelper.eventOn(context);
            console.log(result.player, result);
            setUserResult(result);
            setRecentWinner(result.player?.toLowerCase())
            setCard(result.card)
        } catch (e) {
            console.log("Event CoinFlip Error: ", JSON.stringify(e));
            setLoadingRes(false)
        } finally {
            setLoadingRes(false)
        }
    };

    const getEnabledBets = async () => {
        try {
            setLoading(true)
            const result = await EtherHelper.getEnabledBets(context);
            console.log(result);
            const resToNum = result.map((bigNumber: BigNumber) => ethers.utils.formatEther(bigNumber))
            setEnabledBets(resToNum);
            return result;
        } catch (e) {
            console.log("Event CoinFlip Error: ", JSON.stringify(e));
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    const RefillContract = async () => {
        try {
            const refill = await EtherHelper.RefillContract(context, amount)
            setOpen(true)
            setAlertType('success')
        } catch (e: any) {
            setOpen(true);
            setAlertType('warning');
            console.log("Error on CoinFlip Refill:", JSON.stringify(e));
            setAlertMessage(`Error during TX on Coinflip: ${e?.code}`);
        }
    }

    const Withdraw = async () => {
        try {
            const withdraw = await EtherHelper.withdrawCoinHi(context)
            setOpen(true)
            setAlertType('success')
        } catch (e: any) {
            setOpen(true);
            setAlertType('warning');
            console.log("Error on CoinFlip Refill:", JSON.stringify(e));
            setAlertMessage(`Error during TX on Coinflip: ${e?.code}`);
        }
    }

    const CoinFlip = async () => {
        if (selectedSide === null || betAmount === null) {
            setOpen(true)
            setAlertType('warning')
            setAlertMessage('Please, place your bet and chose the side.')
            return;
        }

        try {

            const tx = await EtherHelper.CoinFlip(context, selectedSide, betAmount);

            getUserResult()
            setOpen(true)
            setAlertType('success')
            console.log("TX: ", tx);
        } catch (e: any) {
            setOpen(true);
            setAlertType('warning');
            console.log("Error on CoinFlip start:", JSON.stringify(e));
            setAlertMessage(`Error during TX on Coinflip: ${e?.code}`);
        } finally {
        }
    };

    const VaultBalance = async () => {
        try {
            EtherHelper.VaultBalance(context).then((tx) => {
                setBalance(tx)
            })
        } catch (e) {
            console.log(e)
        } finally {

        }
    }

    console.log(balance ? balance : '0')
    const deployer = '0xC1ec8665C40B8cAB988C3E126d96d28Bbcdd550a'

    return (
        <div className={classes.root}>
            <div className={classes.overlay} />
            <Snackbar open={open}
                autoHideDuration={6000} onClose={handleAlertClose}>
                <MuiAlert
                    onClose={handleAlertClose}
                    severity={alertType === 'success' ? 'success' : 'warning'}
                    elevation={24}
                    style={{ marginBottom: 50 }}
                >
                    {alertType === 'success' ? 'Successfully!' : ''}
                    {alertType !== 'success' ? alertMessage : ''}
                </MuiAlert>
            </Snackbar>
            <Paper
                elevation={3}
                className={classes.paper}
            >
                <Typography style={{ margin: 20 }} className={classes.sectionTitle2} variant="h4">Coinflip Game</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.sectionTitle} variant="h4">Select Bet</Typography>
                        {loading ?
                            (
                                // Mostra uno spinner durante il caricamento
                                <CircularProgress />
                            ) : (

                                <Box mt={2} style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row', gap: 10 }}>
                                    {enabledBets.map((bet: string, index: number) => (
                                        <Box mt={2} key={index} style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row', gap: 10 }}>
                                            <Button
                                                variant={betAmount === parseFloat(bet) ? "contained" : "contained"}
                                                color={betAmount === parseFloat(bet) ? "success" : "secondary"}
                                                onClick={(e) => handleBetAmount(parseFloat(bet))}
                                            >
                                                {parseInt(bet.toString())} CRO
                                            </Button>
                                        </Box>
                                    ))}
                                </Box>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.sectionTitle} variant="h4">Select Side</Typography>
                        <Box mt={2} style={{ marginTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10, marginBottom: 20 }}>
                            <Button
                                variant={selectedSide === true ? "contained" : "contained"}
                                color={selectedSide === true ? "success" : "secondary"} // Cambia il colore del pulsante a secondary
                                onClick={() => handleSelectSide(true)} // Imposta il valore di scommessa desiderato
                            >
                                Heads
                            </Button>
                            <Button
                                variant={selectedSide === false ? "contained" : "contained"}
                                color={selectedSide === false ? "success" : "secondary"} // Cambia il colore del pulsante a secondary
                                onClick={() => handleSelectSide(false)} // Imposta il valore di scommessa desiderato
                            >
                                Tails
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box style={{ width: '100%', maxHeight: '150px', height: 'auto', backgroundColor: 'rgba(0,0,0, 0.3)', alignItems: 'center' }}>
                            {loadingRes ?
                                (
                                    <>
                                    {parseFloat(balance ?? '0') <= 10 && (
                                        <Button
                                            style={{ marginTop: 20, marginBottom: 20, width: '100%' }}
                                            variant="outlined"
                                            color="primary" // Cambia il colore del pulsante a secondary
                                        >
                                            NOT ENOUGH BALANCE ON CASINO'
                                        </Button>
                                    )}
                                    <CircularProgress />
                                    </>
                                ) : (
                                    <div>
                                        {userResult && (
                                            <Box mt={2} style={{ marginTop: 20 }}>
                                                <Typography className={classes.sectionTitleRF1} variant="body1">
                                                    {userResult.player.slice(0, 5) + '...' + userResult.player.slice(-5)}
                                                </Typography>
                                                <Typography className={classes.sectionTitleRF1} variant="body1">{userResult.won === true ? "You Win!" : ""}</Typography>
                                                <Typography className={classes.sectionTitleRF1} variant="body1">{userResult.won === false ? "You Lose" : ""}</Typography>
                                            </Box>
                                        )}
                                    </div>
                                )}
                        </Box>
                        {parseFloat(balance ?? '0') <= 10 && (
                            <Button
                                style={{ marginTop: 20, width: '100%' }}
                                variant="contained"
                                color="primary" // Cambia il colore del pulsante a secondary
                                onClick={() => CoinFlip()} // Imposta il valore di scommessa desiderato
                                disabled
                            >
                                Flip
                            </Button>
                        )}
                        {parseFloat(balance ?? '0') > 10 && (
                            <Button
                                style={{ marginTop: 20, width: '100%' }}
                                variant="contained"
                                color="primary" // Cambia il colore del pulsante a secondary
                                onClick={() => CoinFlip()} // Imposta il valore di scommessa desiderato
                            >
                                Flip
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {context.addressSigner?.toLowerCase() && context.addressSigner?.toLowerCase() === deployer.toLowerCase() && (
                            <div>
                                <Typography className={classes.sectionTitleRF1}> Admin Panel </Typography>
                                <Stack>
                                    <Divider />
                                    <TextField
                                        label='Refill Contract'
                                        value={amount.toString()} // Converti amount in una stringa
                                        variant="filled"
                                        color="secondary"
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgba(255,255,255, 0.2)',
                                            border: '2px solid #7b1fa2',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                            borderRadius: 10,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        onChange={(e) => setAmount(parseFloat(e.target.value))}
                                    >
                                    </TextField>
                                    <Button onClick={RefillContract} variant="contained" color="secondary">Refill</Button>
                                    <Divider />
                                    <Button style={{ marginTop: 20 }} onClick={Withdraw} variant="contained" color="secondary">Withdraw</Button>

                                </Stack>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};
